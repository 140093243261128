import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import * as employmentData from "../../assets/static/employment_count.json";
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { Partner, partner } from "../../models";
import { HeaderComponent } from '../header/header.component';
import { PartnerinformationComponent } from '../partnerinformation/partnerinformation.component';
import { environment } from "../../environments/environment";
import { stepOneCMSModel, stepOneEntryIdObj } from "../../models";
import * as _ from "lodash";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormsModule,
  NgForm
} from "@angular/forms";
import * as cloneDeep from 'lodash/cloneDeep';
import HelloSign from 'hellosign-embedded';

const client = new HelloSign();

@Component({
  selector: 'app-becomeapartner',
  templateUrl: './becomeapartner.component.html',
  styleUrls: ['./becomeapartner.component.less']
})

export class BecomeapartnerComponent implements OnInit {
  @ViewChild(HeaderComponent) header: HeaderComponent;
  @Input() name: string;
  locale: string = 'en-US';
  step: number = 1;
  lookups: any = {};
  submitted; mailcompare; passwordcompare; error; showcountymenu; showlandlinemenu; showNDA; showLoader; partnerInformation: boolean = false;
  errorMsg: string;
  diableRigsterButton = true;
  signature_request_id: string = '';
  public partnerobj: Partner = partner[0];
  phonefields = {
    'landline': null,
    'phone_number': null
  };
  stepOneCMSData = new stepOneCMSModel();
  stepOneIdObj = new stepOneEntryIdObj();
  currentuser: any;
  tooltipotions: any = {
    'placement': 'right',
    'show-delay': 500
  };
  fromGlobal: boolean = false;
  locationserror: boolean = false;
  httpprefix: string = 'https://';
  weburl: string = '';
  showDocument: boolean = true;
  showcounter: boolean = false;
  count: number = 7;
  diabledownloadButton: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private contentful: ContentfulService,
    private partnercreationService: PartnercreationService,
    private _eref: ElementRef,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  employmentData = employmentData.default;

  ngOnInit() {
    this.getData();
    this.commonService.scrollTop();
    this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.partnerobj.extra_data.contract_signed && this.currentuser) {
      this.showNDA = true;
      this.commonService.getPartnerData(this.currentuser.sponsors[0].id).subscribe(result => {
        this.partnerobj = result;
        this.getEmbedSinedUrl();
      });
    } else {
      if (this.partnerobj.email) {
        window.location.reload();
      }
      localStorage.clear();
    }
    this.commonService.getData("country", "").subscribe(result => {
      this.lookups["COUNTRY_CODES"] = [];
      for (let k in result) {
          // if (result[k].id == 2) {
            this.lookups["COUNTRY_CODES"].push(result[k]);
          // }
      }
  });

  }

  compareEmails(email, confirmemail) {
    if (email.toLowerCase() != confirmemail.toLowerCase()) {
      this.mailcompare = true;
    } else {
      this.mailcompare = false;
    }
  }
  comparePassword(password, confirmpassword) {
    if (password !== confirmpassword) {
      this.passwordcompare = true;
    } else {
      this.passwordcompare = false;
    }
  }

  checkPartner() {
    if (this.partnerobj.id) {
      this.updatePartner(false);
    } else {
      this.createPartner();
    }
  }

  createPartner() {
    this.showLoader = true;
    this.error = false;
    this.errorMsg = "";
    this.setValues();
    let request = cloneDeep(this.partnerobj);
    request.address = this.getAddressFromPartnerData();
    this.partnercreationService
      .createPartner(request)
      .then((data: any) => {
        this.partnerLogin();
        data.body['landlinecode'] = this.partnerobj.landlinecode;
        data.body['contactcode'] = this.partnerobj.contactcode;
        this.partnerobj = data.body;
      })
      .catch(error => {
        this.showErrorMsg(error.error);
        this.showLoader = false;
        this.showNDA = false;
      });
  }

  getAddressFromPartnerData(){
    let address = "";
    if (this.partnerobj.extra_data.office_street_line_1) {
      address += this.partnerobj.extra_data.office_street_line_1 + ', ';
    }
    if (this.partnerobj.extra_data.office_street_line_2) {
      address += this.partnerobj.extra_data.office_street_line_2+ ', ';
    }
    if (this.lookups['REGION_DATA'] && (this.lookups['REGION_DATA']).length > 0) {
      (this.lookups['REGION_DATA']).forEach(element => {
        if (element.id == this.partnerobj.region) {
          address += element.name + ", ";
        }
      });
    }
    if (this.lookups['CITY_DATA'] && (this.lookups['CITY_DATA']).length > 0) {
      (this.lookups['CITY_DATA']).forEach(element => {
        if (element.id == this.partnerobj.city) {
          address += element.name + ", ";
        }
      });
    }
    if (this.lookups['COUNTRY_CODES'] && (this.lookups['COUNTRY_CODES']).length > 0) {
      (this.lookups['COUNTRY_CODES']).forEach(element => {
        if (element.id == this.partnerobj.country) {
          address += element.name ;
        }
      });
    }
    return address;
  }


  updatePartner(fromNDA) {
    this.showLoader = true;
    this.error = false;
    this.errorMsg = "";
    if (!fromNDA) {
      this.setValues();
    } else {
      this.partnerobj.extra_data.onboarding_step_completed = 1;
    }
    delete this.partnerobj.logo;
    delete this.partnerobj.header;
    delete this.partnerobj.description;
    this.partnercreationService
      .updatePartnerData(this.partnerobj.id, this.partnerobj)
      .then((data: any) => {
        if (fromNDA) {
          this.router.navigate(['/partnerinformation']);
          this.commonService.scrollTop();
        } else {
          this.partnerLogin();
        }
      })
      .catch(error => {
        this.showLoader = false;
        this.showNDA = false;
        this.showErrorMsg(error.error);
      });
  }

  getLookUpValues(val, type, lookuptype) {
    this.commonService.getData(type, val).subscribe(result => {
      this.lookups[lookuptype] = result;
    })
  }

  checkLookUpValues(val, type, lookuptype) {
    switch (lookuptype) {
      case "REGION_DATA": {
        this.partnerobj.region = '';
        this.partnerobj.city = '';
        this.lookups['REGION_DATA'] = [];
        this.lookups['CITY_DATA'] = [];
        break;
      }
      case "CITY_DATA": {
        this.partnerobj.city = '';
        this.lookups['CITY_DATA'] = [];
        break;
      }
      case "STATE_DATA": {
        this.partnerobj.extra_data.state1 = '';
        this.partnerobj.extra_data.city1 = '';
        this.lookups['STATE_DATA'] = [];
        this.lookups['CITY1_DATA'] = [];
        break;
      }
      case "CITY1_DATA": {
        this.partnerobj.extra_data.city1 = '';
        this.lookups['CITY1_DATA'] = [];
        break;
      }

      default:
        break;
    }
    if (val) {
      this.getLookUpValues(val, type, lookuptype);
    }
  }
  setValues() {
    this.partnerobj.contact_person_name = this.partnerobj.contact_person_first_name + " " + this.partnerobj.contact_person_last_name;
    this.partnerobj.extra_data.landline = this.partnerobj.landlinecode + this.phonefields.landline;
    this.partnerobj.phone_number = this.partnerobj.contactcode + this.phonefields.phone_number;
    this.partnerobj.brand_name = this.partnerobj.name;
    this.partnerobj.industry = null;
    this.partnerobj.username = this.partnerobj.user_name;
    if (this.weburl) {
      this.partnerobj.url = this.httpprefix + this.weburl.toLowerCase();
    }
  }

  partnerLogin() {
    var request: any = {
      "username": this.partnerobj.username,
      "password": this.partnerobj.password
    };
    this.error = false;
    this.errorMsg = "";
    this.authenticationService.login(request)
      .subscribe(
        data => {
          this.getEmbedSinedUrl();
        },
        error => {
          this.error = true;
          this.errorMsg = error.error.error.message;
          this.showLoader = false;
        });

  }

  showNDADocuments() {
    this.commonService.scrollTop();
    this.showNDA = true;
  }
  hideNDADocuments() {
    this.error = false;
    this.showNDA = false;
  }
  showPartnerInformation() {
    let request = {
      "status": "complete",
      "document_template": 1
    }
    this.partnercreationService
      .statusChange(request, this.partnerobj.id)
      .then((data: any) => {
        this.updatePartner(true);
      })
      .catch(error => {
        this.showErrorMsg(error.error);
      });
  }
  hidePartnerInformation() {
    this.partnerInformation = false;
  }
  showErrorMsg(error) {
    this.error = true;
    if (Object.keys.length > 0) {
      this.commonService.scrollTop();
      this.showLoader = false;
      if (error.email !== undefined) {
        this.errorMsg = this.translate.instant(error.email.code);
      } else if (error.name !== undefined) {
        this.errorMsg = error.name[0].message;
      } else if (error.trade_license_number !== undefined) {
        this.errorMsg = "Ensure Trade License Number field has no more than 50 characters."
      } else if (error.message) {
        this.errorMsg = error.message;
      } else if (error.non_field_errors) {
        this.errorMsg = error.non_field_errors[0].message;
      } else if (error.error && error.error.error && error.error.error[0].message) {
        this.errorMsg = error.error.error[0].message;
      } else if (error && error.error) {
        this.errorMsg = error.error;
      } else if (error && error.error && error.error.message) {
        this.errorMsg = error.error.message;
      } else if (error && error[0] && error[0].message) {
        this.errorMsg = error[0].message;
      }
      else {
        let Messages = Object.keys(error).map((key) => (error[key][0].message));
        this.errorMsg = Messages.toString();
      }

    }
  }
  getEmbedSinedUrl() {
    this.checkTime();
    client.close();
    this.partnercreationService
      .signedgetAPI(1, this.partnerobj.id)
      .then((data: any) => {
        let res;
        if (data.length > 0) {
          res = data[0];
        } else {
          res = data;
        }
        this.showLoader = false;
        if (res.download_url) {
          this.showDocument = false;
          this.signature_request_id = res.download_url;
          this.diableRigsterButton = false;
          this.diabledownloadButton = false;
          this.router.navigate(['/partnerinformation']);
          this.commonService.scrollTop();
        } else {
          this.signature_request_id = res.signature_request_id;
          this.showDocument = true;
          this.showNDADocuments();
          this.postEmbedSinedUrl();
        }
      })
      .catch(error => {
        this.showLoader = false;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  postEmbedSinedUrl() {
    this.checkTime();
    client.close();
    var request = {
      "document_template": 1
    };
    this.partnercreationService
      .signedpostAPI(request, this.partnerobj.id)
      .then((data: any) => {
        let res = data;
        this.loadHelloSignEmberUrl(res);
        this.signature_request_id = res.signature_request_id;
      })
      .catch(error => {
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }


  loadHelloSignEmberUrl(data) {
    let ref = this;
    //HelloSign.init(environment.helloSignClientId);
    if(document.getElementsByClassName('x-hellosign-embedded').length === 0) {
      const client = new HelloSign({
        clientId: environment.helloSignClientId
      });
      
      setTimeout(function() {
        client.open(data.sign_url,{
          allowCancel: true,
          skipDomainVerification: true,
          // skipDomainVerification: false,   //prod eui11
          container: document.getElementById('embed-document')
        });
        client.on(HelloSign.events.SIGN, () => {
            ref.diableRigsterButton = false;
            ref.diabledownloadButton = true;
            ref.startCount();
            ref.showcounter = true;
        });
        ref.diabledownloadButton = false;
      }, 1000);
   }

  }

  downloadNDADocument() {
    let request = {
      "document_template": 1,
      "signature_request_id": this.signature_request_id
    };
    this.partnercreationService
      .downloadNDADocument(request, this.partnerobj.id)
      .then((data: any) => {
        // create link element
        let a = document.createElement('a'),
          url = data.body.url;
        // initialize 
        a.href = url;
        a.download = 'ndadocument.pdf';
        // append element to the body, 
        // a must, due to Firefox
        document.body.appendChild(a);
        // trigger download
        a.click();
        // delay a bit deletion of the element
        setTimeout(function() {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 100);
      })
      .catch(error => {
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }
  getData() {
    let contentFulIds = this.stepOneIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      this.header.getData(dataobj);
      HeaderComponent
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys": {
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
          this.stepOneCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }

      }
    });
  }

  /*checkTotalLocations(fromglobal) {
    if (fromglobal) {
      this.fromGlobal = true;
    }
    if (this.fromGlobal && this.partnerobj.extra_data.no_of_locations_globally < this.partnerobj.extra_data.no_of_locations_uae) {
      this.locationserror = true;
    } else {
      this.locationserror = false;
    }
  } */

  startCount() {
    let scope = this;
    const inter = setInterval(function() {
        scope.count = scope.count - 1;
        if (scope.count === 0) {
          clearInterval(inter);
           scope.diabledownloadButton = false;
        }
    }, 1000);
  }

  checkNumber(event) {
    return this.commonService.checkNumber(event);
  }

  checkTime() {
    let scope = this;
    setTimeout(function() {
      scope.getEmbedSinedUrl();
    }, 2640000);
  }

}
