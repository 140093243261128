import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/services';
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';

@Component({
	selector: 'app-leftpanel',
	templateUrl: './leftpanel.component.html',
	styleUrls: ['./leftpanel.component.less']
})
export class LeftpanelComponent implements OnInit {
	@Input() step: any;
	@Input() partnerobj: any;
	@Input() billingConfig : any;
	ChildPartner= false;
	hiddenPathsArray =[];
	sponsorlogo = (localStorage.getItem('partnerimg') !== null && localStorage.getItem('partnerimg') !== undefined) ? localStorage.getItem('partnerimg') : '';
	sponsorname = (localStorage.getItem('partnername') !== null && localStorage.getItem('partnername') !== undefined) ? localStorage.getItem('partnername') : '';
	constructor(
		private router: Router,
    	private route: ActivatedRoute,
		private rolebasedrulesService: RolebasedrulesService,
		private authenticationService: AuthenticationService
	) { }

	ngOnInit() {
		this.ChildPartner = (localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined) ? true : false;
		this.setHiddenPaths();
	}

	gotoPage(page) {
		if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
			this.router.navigate([page], { queryParams: { partner: localStorage.getItem('partnerid') }});
		} else {
    		this.router.navigate([page]);
		}
  	}

	setHiddenPaths(){
		let currentMemberRoleCode = this.authenticationService?.currentUserValue?.role?.code;
		this.hiddenPathsArray =  this.rolebasedrulesService.getHiddenRoutesBasedOnRoleCode(currentMemberRoleCode);
	}

}
