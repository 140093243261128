export class InvitedUser {
    sponsorName: string;
    locationName: string;
    user:any;
    mobile: string;
    role_name: string;
    role: string;
    access_level: any;
    access_revoked_datetime: null;
    sponsor_sponsorlocations_data:any;
    access_revoked_type: string;
    access_revoked_value: string;
    sponsors: any;
    locations: any;
    active: boolean;
    profile_image: string;
}

export const invitedUser: InvitedUser[] = [{
    sponsorName: "",
    locationName: "",
    role:"",
    mobile: "",
    role_name: "",
    active:true,
    profile_image:"",
    user: {
        first_name:"",
        last_name:"",
        email:"",
        is_active:true,
    },
    access_level: {},
    access_revoked_datetime: null,
    sponsor_sponsorlocations_data:[],
    access_revoked_type: "UNLIMITED",
    access_revoked_value: "",
    sponsors:[],
    locations: []
}];


export class UserInviteErrorsObj {
    first_name: {
      present: boolean,
      messege: string
    };
    last_name: {
      present: boolean,
      messege: string
    };
    email: {
      present: boolean,
      messege: string
    };
    mobile: {
      present: boolean,
      messege: string
    };
    role: {
      present: boolean,
      messege: string
    };
    location: {
      present: boolean,
      messege: string
    };
    apiResponseError:{
      present: boolean,
      messege: string
    };
};

export const userInviteErrorsObj: UserInviteErrorsObj[] = [{
    first_name: {
        present: false,
        messege: 'First Name cannot be empty'
    },
    last_name: {
      present: false,
      messege: 'Last Name cannot be empty'
    },
    email: {
      present: false,
      messege: 'Email is incorrect or empty'
    },
    mobile: {
      present: false,
      messege: 'Minimum 8 and maximum 12 digits.'
    },
    role: {
      present: false,
      messege: 'Role is Required'
    },
    location: {
      present: false,
      messege: 'Location is Required'
    },
    apiResponseError:{
      present: false,
      messege: 'Some error Occurred'
    },
}];
