import { Injectable } from '@angular/core';
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
  	private webService: WebService
  ) { }
  private host = environment.host();
  private startDate = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
  private endDate = moment(new Date()).format('YYYY-MM-DD');
  getDashboardData(partnerId,interval,url) {
  	let requestUrl = "";
  	if(interval==='month'){
  		requestUrl = this.host + "sponsors/"+partnerId+"/dashboards/"+url+"/?grouping="+interval;
  	}else{
  		
  		requestUrl = this.host + "sponsors/"+partnerId+"/dashboards/"+url+"/?grouping="+interval+"&start_date="+this.startDate+"&end_date="+this.endDate;
  	}
    return this.webService.getAPI(requestUrl, false);
  }

  getDashboardDataForAnalyse(partnerId,grouping,url,filter,start_date,end_date,mainfilter,offerid,locationid){
    let requestUrl =  this.host + "sponsors/"+partnerId+"/dashboards/"+url;
    if(filter==='time'){
      if(mainfilter ==='all'){
        requestUrl = requestUrl+"/?grouping="+grouping+"&start_date="+start_date+"&end_date="+end_date;
      }
      if(mainfilter ==='offers'){
        requestUrl = requestUrl+"/?grouping="+grouping+"&start_date="+start_date+"&end_date="+end_date+"&offer_id="+offerid;
      }
      if(mainfilter ==='locations'){
        requestUrl = requestUrl+"/?grouping="+grouping+"&start_date="+start_date+"&end_date="+end_date+"&location_code="+locationid;
      }
    }else{
      if(mainfilter ==='all'){
        requestUrl = requestUrl+"/?grouping="+filter;
      }
      if(mainfilter ==='offers'){
        requestUrl = requestUrl+"/?grouping="+filter+"&offer_id="+offerid;
      }
      if(mainfilter ==='locations'){
        requestUrl = requestUrl+"/?grouping="+filter+"&location_code="+locationid;
      }
      
    }
    return this.webService.getAPI(requestUrl, false);
  }
}


