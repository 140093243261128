import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, ContentfulService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { headerCMSModel, headerEntryIdObj } from "../../models";
import * as _ from "lodash";
import HelloSign from 'hellosign-embedded';
const client = new HelloSign();
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {

  @Input() partnerobj: any;
  @Input() step: any;
  headerCMSModelData = new headerCMSModel();
  headerIdObj = new headerEntryIdObj();
  locale: string = 'en-US';
  headerImage: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private contentful: ContentfulService
  ) { }

  ngOnInit() {
     this.contentful.getContent('5jFmb6z55PWvrRUnAwRzRx', this.locale).subscribe(dataobj => {
      this.headerImage = dataobj['imageContent'].fields.file.url;
    });
  }

  partnerLogout() {
    client.close();
    this.authenticationService.logout();
  }

  gotoMargetingPage() {
    client.close();
    this.authenticationService.logout();
  }
  getData(obj) {
    let contentFulIds = this.headerIdObj.entryIds;
    for (let entry of contentFulIds) {
      let Index = _.findIndex(obj.items, {
        "sys":{
          "id": entry.id
        }
      });
      if (Index !== -1 && obj.items[Index]['fields']['stringContent']!==undefined) {
          this.headerCMSModelData[entry.text] = obj.items[Index]['fields']['stringContent'];
      }
    }
  }
}
