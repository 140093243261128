import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services';

@Component({
  selector: 'app-termsheader',
  templateUrl: './termsheader.component.html',
  styleUrls: ['./termsheader.component.less']
})
export class TermsheaderComponent implements OnInit {
    @Input() headerImg: any;
	@Input() pageData: any;
	showsignin: boolean = false;
	currentUser: any;
	partnerobj: any;
	isstrategicpartner = false;
	strategicdata = [];
  constructor(private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService) { }

  ngOnInit() {
  	this.strategicdata = JSON.parse(localStorage.getItem('parentSponsor'));
		if(this.strategicdata){
			this.isstrategicpartner = true;
		}
  	this.commonService.scrollTop();
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (this.currentUser) {
			this.showsignin = false;
			this.commonService.getPartnerData(this.currentUser.sponsors[0].id).subscribe(data => {
				this.partnerobj = data;
			});
		} else {
			this.showsignin = true;
		}

  }

  gotoPage(url) {
		this.router.navigate([url]);
	}

	gotoBackPage() {
		if (this.currentUser && (this.partnerobj.status === 'S' || this.partnerobj.status === 'T' || this.partnerobj.status === 'A') && this.isstrategicpartner === false) {
			this.router.navigate(['/profile']);
		} else if(this.isstrategicpartner){
			this.router.navigate(['/strategicpartners']);
		} else {
			this.router.navigate(['/skywardseveryday']);
		}
	}

}
