import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { SkywardseverydayComponent } from './skywardseveryday/skywardseveryday.component';
import { BecomeapartnerComponent } from './becomeapartner/becomeapartner.component';
import { CustomizeyourpackageComponent } from './customizeyourpackage/customizeyourpackage.component';
import { PartnerinformationComponent } from './partnerinformation/partnerinformation.component';
import { SigninComponent } from './signin/signin.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SigntheagreementComponent } from './signtheagreement/signtheagreement.component';
import { LetsgoComponent } from './letsgo/letsgo.component';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { VerifyyourbusinessComponent } from './verifyyourbusiness/verifyyourbusiness.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PaymentsComponent } from './payments/payments.component';
import { TransactiondetailsComponent } from './transactiondetails/transactiondetails.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactusComponent } from './contactus/contactus.component';
import { OffersComponent } from './offers/offers.component';
import { OffercreationComponent } from './offercreation/offercreation.component';
import { LinkexpiredComponent } from './linkexpired/linkexpired.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard, NavigationGuard } from '../guards';
import { InvoicedetailsComponent } from './invoicedetails/invoicedetails.component';
import { OfferdetailsComponent } from './offerdetails/offerdetails.component';
import { TermsnconditionsComponent } from './termsnconditions/termsnconditions.component';
import { FaqComponent } from './faq/faq.component';
import { PaymenttermsComponent } from './paymentterms/paymentterms.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { StrategicpartneviewComponent } from './strategicpartneview/strategicpartneview.component';
import { LocationpaymentschemesreviewComponent } from './locationpaymentschemesreview/locationpaymentschemesreview.component';
import { VersionComponent } from './version/version.component';
import { UsersComponent } from './users/users.component';
import { RolebasedaccessGuard } from 'src/guards/rolebasedaccess.guard';

const routes: Routes = [{ path: '', component: SkywardseverydayComponent, canDeactivate: [NavigationGuard] },
{ path: 'landingpage', component: LandingpageComponent, canDeactivate: [NavigationGuard] },
{ path: 'version', component: VersionComponent, canDeactivate: [NavigationGuard] },
{ path: 'skywardseveryday', component: SkywardseverydayComponent, canDeactivate: [NavigationGuard] },
{ path: 'becomeapartner', component: BecomeapartnerComponent, canDeactivate: [NavigationGuard]},
{ path: 'customizeyourpackage', component: CustomizeyourpackageComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'partnerinformation', component: PartnerinformationComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'signtheagreement', component: SigntheagreementComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'welcomepage', component: LocationpaymentschemesreviewComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'letsgo', component: LetsgoComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'signin', component: SigninComponent, canDeactivate: [NavigationGuard] },
{ path: 'login', component: ResetpasswordComponent, canDeactivate: [NavigationGuard] },
{ path: 'verifyyourbusiness', component: VerifyyourbusinessComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'transactiondetails', component: TransactiondetailsComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'users', component:UsersComponent, canActivate: [AuthGuard,RolebasedaccessGuard], canDeactivate: [NavigationGuard] },
{ path: 'contactus', component: ContactusComponent, canDeactivate: [NavigationGuard] },
{ path: 'offers', component: OffersComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'offercreation', component: OffercreationComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'linkexpired', component: LinkexpiredComponent, canDeactivate: [NavigationGuard] },
{ path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'invoicedetails', component: InvoicedetailsComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'offerdetails', component: OfferdetailsComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard] },
{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard]},
{ path: 'termsnconditions', component: TermsnconditionsComponent, canDeactivate: [NavigationGuard] },
// { path: 'faq', component: FaqComponent, canDeactivate: [NavigationGuard]},
{ path: 'paymentterms', component: PaymenttermsComponent, canDeactivate: [NavigationGuard] },
{ path: 'privacypolicy', component: PrivacypolicyComponent, canDeactivate: [NavigationGuard] },
//{ path: 'locationschemesreview', component: LocationpaymentschemesreviewComponent},
{ path: 'strategicpartners', component: StrategicpartneviewComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuard]},
{path:'!/login',redirectTo:'login'},
// otherwise redirect to home
{ path: '**', redirectTo: '' }];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
