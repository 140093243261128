import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.less']
})
export class VersionComponent implements OnInit {

  currentVersion:string="";
  buildDate:any={};
  constructor() { }

  ngOnInit(): void {

    // this.currentVersion = "Merchant-Portal-1.1.0.8-PROD;
    // this.currentVersion = "Merchant-Portal-1.1.0.42-UAT";
    this.currentVersion = "Merchant-Portal-1.1.0.25-QA";
    
    this.buildDate = new Date('2024-05-14');

  }


}
