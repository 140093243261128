import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { HeaderComponent } from '../header/header.component';
import { environment } from "../../environments/environment";
import { stepFourCMSModel, stepFourEntryIdObj } from "../../models";
import * as _ from "lodash";
import HelloSign from 'hellosign-embedded';

const client = new HelloSign();

@Component({
  selector: 'app-signtheagreement',
  templateUrl: './signtheagreement.component.html',
  styleUrls: ['./signtheagreement.component.less']
})
export class SigntheagreementComponent implements OnInit {
  @ViewChild(HeaderComponent) header: HeaderComponent;
  partnerobj: any;
  step: number = 4;
  error: boolean = false;
  errorMsg: string;
  diableRigsterButton: boolean = true;
  //HelloSign: any;
  signature_request_id: string = '';
  stepFourCMSData = new stepFourCMSModel();
  stepFourIdObj = new stepFourEntryIdObj();
  locale: string = 'en-US';
  showContract: boolean = true;
  showDocument: boolean = true;
  contractdataobj: any = {
    'signed_name': '',
    'signed_email': '',
    'designation': '',
    'signed_title': ''
  };
  showcounter: boolean = false;
  count: number = 7;
  diabledownloadButton: boolean = true;
  constructor(
    private partnercreationService: PartnercreationService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private contentful: ContentfulService,
  ) { }

  ngOnInit() {
    this.commonService.scrollTop();
    this.getData();
    this.partnerobj = this.authenticationService.currentUserValue;
    this.getPartnerData(this.partnerobj.sponsors[0].id);
  }

  gotoNextStep() {
    this.router.navigate(['/letsgo']);
  }
  getPartnerData(partnerId) {
    this.partnercreationService
      .getPartnerData(partnerId)
      .then((data: any) => {
        this.partnerobj = data;
        this.getEmbedSinedUrl();
      })
      .catch(error => {
        this.error = true;
        this.showErrorMsg(error);
      });


  }

  updateSignedDocument(saveexit) {
    let request = {
      "status": "complete",
      "document_template": 2
    }
    this.partnercreationService
      .statusChange(request, this.partnerobj.id)
      .then((data: any) => {
        this.updatePartner(saveexit);
      })
      .catch(error => {
        this.showErrorMsg(error.error);
      });
  }

  updatePartner(saveexit) {
    this.partnerobj.extra_data.contract_signed = true;
    this.partnerobj.extra_data.onboarding_step_completed = 4;
    delete this.partnerobj.logo;
    delete this.partnerobj.header;
    delete this.partnerobj.description;
    this.partnercreationService
      .updatePartnerData(this.partnerobj.id, this.partnerobj)
      .then((data: any) => {
        if (saveexit) {
          this.authenticationService.logout();
        } else {
          this.router.navigate(['/letsgo']);
        }
      })
      .catch(error => {
        this.partnerobj.extra_data.contract_signed = false;
        this.error = true;
        this.showErrorMsg(error);
      });
  }

  getEmbedSinedUrl() {
    this.checkTime();
    client.close();
    this.partnercreationService
      .signedgetAPI(2, this.partnerobj.id)
      .then((data: any) => {
        let res;
        if (data.length > 0) {
          res = data[0];
        } else {
          res = data;
        }
        this.showDocument = false;
        if (res.download_url) {
          this.showContract = false;
          this.signature_request_id = res.download_url;
          this.diableRigsterButton = false;
          this.diabledownloadButton = false;
        } else {
          this.showContract = true;
        }
        this.authenticationService.currentUserValue.header = true;
      })
      .catch(error => {
        this.showErrorMsg(error);
      });
  }

  postEmbedSinedUrl() {
    this.checkTime();
    client.close();
    var request = {
      "document_template": 2,
      'contract_obj': this.contractdataobj
    };
    this.partnercreationService
      .signedpostAPI(request, this.partnerobj.id)
      .then((data: any) => {
        let res = data;
        this.loadHelloSignEmberUrl(res);
        this.signature_request_id = res.signature_request_id;
      })
      .catch(error => {
        this.showErrorMsg(error);
      });
  }


  loadHelloSignEmberUrl(data) {
    this.showDocument = true;
    let ref = this;
    let logedindata = JSON.parse(localStorage.getItem('currentUser'));
    if (logedindata) {
      if(document.getElementsByClassName('x-hellosign-embedded').length === 0) {
        const client = new HelloSign({
          clientId: environment.helloSignClientId
        });
        client.open(data.sign_url,{
          allowCancel: true,
          skipDomainVerification: true,
          // skipDomainVerification: false,   //prod eui11
          container: document.getElementById('embed-document')
        });
        this.diabledownloadButton = false;
        client.on(HelloSign.events.SIGN, () => {
          ref.diableRigsterButton = false;
          ref.diabledownloadButton = true;
          ref.startCount();
          ref.showcounter = true;
        });
        ref.diabledownloadButton = false;
      }
    }
  }

  downloadNDADocument() {
    let request = {
      "document_template": 2,
      "signature_request_id": this.signature_request_id
    }
    this.partnercreationService
      .downloadNDADocument(request, this.partnerobj.id)
      .then((data: any) => {
        // create link element
        let a = document.createElement('a'),
          url = data.body.url;
        // initialize 
        a.href = url;
        a.download = 'AgreementDocument.pdf';
        // append element to the body, 
        // a must, due to Firefox
        document.body.appendChild(a);
        // trigger download
        a.click();
        // delay a bit deletion of the element
        setTimeout(function() {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 100);
      })
      .catch(error => {
        this.showErrorMsg(error);
      });
  }

  showErrorMsg(error) {
    this.error = true;
    this.errorMsg = this.commonService.showCommonErrorMsg(error);
  }

  getData() {
    let contentFulIds = this.stepFourIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      this.header.getData(dataobj);
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys": {
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
          this.stepFourCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }

      }
    });
  }

  startCount() {
    let scope = this;
    const inter = setInterval(function() {
        scope.count = scope.count - 1;
        if (scope.count === 0) {
          clearInterval(inter);
          scope.diabledownloadButton = false;
        }
    }, 1000);
  }

  checkTime() {
    let scope = this;
    setTimeout(function() {
      scope.getEmbedSinedUrl();
    }, 2640000);
  }
}
