import { Component, OnInit } from '@angular/core';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-locationerrorsmodal',
  templateUrl: './locationerrorsmodal.component.html',
  styleUrls: ['./locationerrorsmodal.component.less']
})
export class LocationerrorsmodalComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  close() {
    this.modalService.dismissAll();
  }
}
