import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, ContentfulService, PartnercreationService } from '../../services';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit {
	paymentDue: boolean = false;
	accountSuspended: boolean = false;
	notifications: any = [];
	customerType: string = '';
	@Input() step: any;
	@Input() partnerobj: any;
	partnerDetails :any;
	partnerStatus:any;
	billingConfig: any;
	error: boolean = false;
	errorMsg: string = '';
	paymentsbutton: any;
	locale: string = 'en-US';
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private contentful: ContentfulService,
		private modalService: NgbModal,
		private partnercreationService: PartnercreationService
	) { }

	ngOnInit() {
		let partnerId = '';
		if(this.route.snapshot.queryParamMap.get('partner')){
			partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
		} else {
			partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
		}
		let self = this;
		this.getBillingConfig(partnerId);
		this.commonService.getNotifications(partnerId).subscribe(data => {
			self.notifications = data.messages;
		});
		this.getData();
		this.commonService.getPartnerData(partnerId).subscribe(data => {
			this.partnerDetails = data;
			this.partnerStatus = data.status;
			// this.partnerStatus = 'ON-HOLD';
		});
	}

	open(content, modalclass) {
		this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalService.dismissAll();
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}
	getData() {
		this.contentful.getContent('1Mpplqk4dTi2lIIASwPEcw', this.locale).subscribe(dataobj => {
			this.paymentsbutton = dataobj['stringContent'];
		});
	}
}
