import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-logoutmodal',
  templateUrl: './logoutmodal.component.html',
  styleUrls: ['./logoutmodal.component.less']
})
export class LogoutmodalComponent implements OnInit {

  constructor(
  	private authenticationService: AuthenticationService,
  	private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  partnerLogout() {
  	this.close();
    this.authenticationService.logout();
  }

  close() {
    this.modalService.dismissAll();
  }

}
