export class stepOneCMSModel {
  	aboutYourBusinessTitle: string;
    aboutYourBusinessDescription: string;
    accountDetailsTitle: string;
    accountDetailsDescription: string;
    businessDetailsTitle: string;
    businessDetailsDescription: string;
    brandDetailsTitle: string;
    brandDetailsDescription: string;
    nextCTA: string;
    nonDisclosureAgreementTitle: string;
    nonDisclosureAgreementDescription1: string;
    nonDisclosureAgreementDescription2: string;
    nonDisclosureAgreementDescription3: string;
    backCTA: string;
    registerCTA: string;
    entitynameinfo: string;
    tradingnameinfo: string;
    NDASignedHeader: string;
    NDASignedDescription: string;
    registerOfficeTitle:string;
    registerOfficeDescription:string;
}


export class stepOneEntryIdObj {
  	entryIds = [
  		{ id:'2kC78vWycEJ10KaxVpVAZk', text : 'aboutYourBusinessTitle'},
      { id:'11Vl5DHkj7JFGyTECegx3g', text : 'aboutYourBusinessDescription'},
      { id:'1IvB9U9FipZ97HZFncm93x', text : 'accountDetailsTitle'},
      { id:'5YSCIZSjMtx8XwR94DeGAJ', text : 'accountDetailsDescription'},
      { id:'dBpFRvNfv2RvOfxmtmKKX', text : 'contactDetailsTitle'},
      { id:'1AN6FE6wvFgA0StQ6J7KMj', text : 'contactDetailsDescription'},
      { id:'HKYVKONkaCDZzohFNmyaq', text : 'businessDetailsTitle'},
      { id:'2X113MixkXCXm7W1siZ3c9', text : 'businessDetailsDescription'},
      { id:'5aoLLF8JgMtvAV8URzeMyb', text : 'brandDetailsTitle'},
      { id:'1BgI9nU9GqdRPLTSiGpT2w', text : 'brandDetailsDescription'},
      { id:'5BWefivYM1EOa7CVxNR3DX', text : 'nextCTA'},
      { id:'5thpIRYV0aB0xHoQnOYy4r', text : 'nonDisclosureAgreementTitle'},
      { id:'AkD648y74SWKSjp4eroEv', text : 'nonDisclosureAgreementDescription1'},
      { id:'5qlBvLUFehrs8fA51nJnPj', text : 'nonDisclosureAgreementDescription2'},
      { id:'6eoxcq0RPBX369Qz3ZqEcK', text : 'nonDisclosureAgreementDescription3'},
      { id:'2jJI6nX12CNqgJWBhz7wsc', text : 'backCTA'},
      { id:'MhfNZkJU2yw7qiVYXAoyZ', text : 'registerCTA'},
      { id:'oRv3eIMZAJONNEWC0vT6S', text : 'entitynameinfo'},
      { id:'4DKO6z02tGZoXeg0LVDWNO', text : 'tradingnameinfo'},
      { id:'39x2e3H61rPk4OyRtUAAXD', text : 'NDASignedHeader'},
      { id:'1htjrMk2HSLoK5RpXH0C0s', text : 'NDASignedDescription'},
      { id:'2eMXy6xUuCJHbZhfYgX2JZ', text : 'registerOfficeTitle'},
      { id:'4dMKurMl2Tw9vBsenK7wPs', text : 'registerOfficeDescription'}

  	];
}