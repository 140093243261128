import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-sessionexpirationmodal',
  templateUrl: './sessionexpirationmodal.component.html',
  styleUrls: ['./sessionexpirationmodal.component.less']
})
export class SessionexpirationmodalComponent implements OnInit {

  constructor(private appComponent: AppComponent) { }


  ngOnInit() {
  }

  close(reauth){
  	this.appComponent.close(reauth);
  }
  refreshToken(){
  	this.appComponent.refreshToken();
  }
}
