import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

	constructor(
		private router: Router,
    	private route: ActivatedRoute
	) { }

	ngOnInit() {
	}

	gotoPage(url) {
    	this.router.navigate([url]);
  	}

}
