import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
// import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { SkywardseverydayComponent } from './skywardseveryday/skywardseveryday.component';
import { BecomeapartnerComponent } from './becomeapartner/becomeapartner.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PartnerinformationComponent } from './partnerinformation/partnerinformation.component';
import { JwtInterceptor, ErrorInterceptor } from '../helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SigninComponent } from './signin/signin.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { CustomizeyourpackageComponent } from './customizeyourpackage/customizeyourpackage.component';
import { SigntheagreementComponent } from './signtheagreement/signtheagreement.component';
import { LetsgoComponent } from './letsgo/letsgo.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxFilesizeModule } from 'ngx-filesize';
import { VerifyyourbusinessComponent } from './verifyyourbusiness/verifyyourbusiness.component';
//import { TooltipModule } from 'ng2-tooltip-directive';
import { TransactionsComponent } from './transactions/transactions.component';
import { InnerheaderComponent } from './innerheader/innerheader.component';
import { LeftpanelComponent } from './leftpanel/leftpanel.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentsmodalComponent } from './paymentsmodal/paymentsmodal.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TransactiondetailsComponent } from './transactiondetails/transactiondetails.component';
import { ProfileComponent } from './profile/profile.component';
import { ContentsuccessmodalComponent } from './contentsuccessmodal/contentsuccessmodal.component';
import { PinconfirmationmodalComponent } from './pinconfirmationmodal/pinconfirmationmodal.component';
import { LocationsmodalComponent } from './locationsmodal/locationsmodal.component';
import { LocationdeletemodalComponent } from './locationdeletemodal/locationdeletemodal.component';
import { LogoutmodalComponent } from './logoutmodal/logoutmodal.component';
import { ContactusComponent } from './contactus/contactus.component';
import { LocationsresetmodalComponent } from './locationsresetmodal/locationsresetmodal.component';
import { OffersComponent } from './offers/offers.component';
import { LinkexpiredComponent } from './linkexpired/linkexpired.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { OffercreationComponent } from './offercreation/offercreation.component';
import { BlockcopypasteDirective } from './blockcopypaste.directive';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OfferoverlappComponent } from './offeroverlapp/offeroverlapp.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CurrencyFormatDirective } from './currency-format.directive';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OutputGraphComponent } from './output-graph/output-graph.component';
import { InvoicedetailsComponent } from './invoicedetails/invoicedetails.component';
import { OrderModule } from 'ngx-order-pipe';
import { OffercreationmodalComponent } from './offercreationmodal/offercreationmodal.component';
import { OfferdetailsComponent } from './offerdetails/offerdetails.component';
import { RefundmodalComponent } from './refundmodal/refundmodal.component';
import { SessionexpirationmodalComponent } from './sessionexpirationmodal/sessionexpirationmodal.component';
import { SessionmodalComponent } from './sessionmodal/sessionmodal.component';
import { TwodecimalnumberDirective } from './twodecimalnumber.directive';
import { TooltipDirective } from './tooltip.directive';
import { TermsnconditionsComponent } from './termsnconditions/termsnconditions.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { FaqComponent } from './faq/faq.component';
import { PaymenttermsComponent } from './paymentterms/paymentterms.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ResetemailsComponent } from './resetemails/resetemails.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { TermsheaderComponent } from './termsheader/termsheader.component';
import { ConvertFrom24To12FormatPipe } from './convert-from24-to12-format.pipe';
import { StrategicpartneviewComponent } from './strategicpartneview/strategicpartneview.component';
import { LocationerrorsmodalComponent } from './locationerrorsmodal/locationerrorsmodal.component';
import { LocationpaymentschemesreviewComponent } from './locationpaymentschemesreview/locationpaymentschemesreview.component';
import { PaymentsdownloadmodalComponent } from './paymentsdownloadmodal/paymentsdownloadmodal.component';
import { VersionComponent } from './version/version.component';
import { OffercommdatepickmodalComponent } from './offercommdatepickmodal/offercommdatepickmodal.component';
import { UsersComponent } from './users/users.component';
import { EditUserModalComponent } from './users/edit-user-modal/edit-user-modal.component';
import { ActiveInactiveModalComponent } from './users/active-inactive-modal/active-inactive-modal.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    SkywardseverydayComponent,
    BecomeapartnerComponent,
    PartnerinformationComponent,
    HeaderComponent,
    FooterComponent,
    SigninComponent,
    ResetpasswordComponent,
    CustomizeyourpackageComponent,
    SigntheagreementComponent,
    LetsgoComponent,
    ToggleButtonComponent,
    WelcomepageComponent,
    NumbersOnlyDirective,
    VerifyyourbusinessComponent,
    TransactionsComponent,
    InnerheaderComponent,
    LeftpanelComponent,
    PaymentsComponent,
    PaymentsmodalComponent,
    TransactiondetailsComponent,
    ProfileComponent,
    ContentsuccessmodalComponent,
    PinconfirmationmodalComponent,
    LocationsmodalComponent,
    LocationdeletemodalComponent,
    LogoutmodalComponent,
    ContactusComponent,
    LocationsresetmodalComponent,
    OffersComponent,
    LinkexpiredComponent,
    ChangepasswordComponent,
    OffercreationComponent,
    BlockcopypasteDirective,
    OfferoverlappComponent,
    NotificationsComponent,
    CurrencyFormatDirective,
    DashboardComponent,
    OutputGraphComponent,
    InvoicedetailsComponent,
    OffercreationmodalComponent,
    OfferdetailsComponent,
    RefundmodalComponent,
    SessionexpirationmodalComponent,
    SessionmodalComponent,
    TwodecimalnumberDirective,
    TooltipDirective,
    TermsnconditionsComponent,
    FaqComponent,
    PaymenttermsComponent,
    PrivacypolicyComponent,
    ResetemailsComponent,
    EditprofileComponent,
    TermsheaderComponent,
    ConvertFrom24To12FormatPipe,
    StrategicpartneviewComponent,
    LocationerrorsmodalComponent,
    LocationpaymentschemesreviewComponent,
    PaymentsdownloadmodalComponent,
    VersionComponent,
    OffercommdatepickmodalComponent,
    UsersComponent,
    EditUserModalComponent,
    ActiveInactiveModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SlickCarouselModule,
    //TooltipModule,
    NgbModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCQB3MKiSqyL5rKWu-qATcSH-2kqPcZ3dk',
      libraries: ["places"]
    }),
    // AgmJsMarkerClustererModule,
    AgmMarkerClustererModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxDaterangepickerMd.forRoot(),
    UiSwitchModule,
    NgxFilesizeModule,
    OrderModule,
    MarkdownModule.forRoot({
     loader: HttpClient, // optional, only if you use [src] attribute
     markedOptions: {
       provide: MarkedOptions,
       useValue: {
         gfm: true,
         tables: true,
         breaks: false,
         pedantic: false,
         sanitize: false,
         smartLists: true,
         smartypants: false,
       },
     },
   })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
