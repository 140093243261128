import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.less']
})
export class PaymentsComponent implements OnInit {
	noInovoices: boolean = false;
	step: string = 'payments';
	tab: string = 'invoices';
	payments: boolean = false;
	noPayments: boolean = false;
	partnerobj: any = {};
	billingConfig: any;
	error: boolean = false;
	errorMsg: string = "";
	inVoices = [];
	payMents = [];
	next: string = "None";
	prev: string = "None";
	paymentsnext: string = "None";
	paymentsprev: string = "None";
	locale: string = 'en-US';
	invoiceHeaderImg: any;
	paymentsbutton: any;
	loader: boolean = true;
	payment_plan:any;
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private partnercreationService: PartnercreationService,
		private modalService: NgbModal,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		if (this.route.snapshot.queryParamMap.get('partner')) {
			this.getPartnerData(parseInt(this.route.snapshot.queryParamMap.get('partner')));
			this.getBillingConfig(parseInt(this.route.snapshot.queryParamMap.get('partner')));
		}
		else {
			let partnerData = this.authenticationService.currentUserValue;
			this.getPartnerData(partnerData.sponsors[0].id);
			this.getBillingConfig(partnerData.sponsors[0].id);
		}
		this.getData();

	}
	partnerLogout() {
		this.authenticationService.logout();
	}

	tabChange(val) {
		this.tab = val;
	}

	open(content, modalclass) {
		this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalService.dismissAll();
	}

	getPartnerData(partnerId) {
		this.partnercreationService
			.getPartnerData(partnerId)
			.then((data: any) => {
				this.partnerobj = data;
				this.getInvoices('None');
				this.getPayments('None');
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getInvoices(cursor) {
		this.loader = true;
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		this.partnercreationService
			.getInvoices(cursor, this.partnerobj.id)
			.then((data: any) => {
				// for (var i = 0; i < data.body.length; i++) {
				// 	data.body[i].issue_date = moment(data.body[i].issue_date).tz('Asia/Dubai').format('DD MMM YYYY');
				// 	data.body[i].start_date = moment(data.body[i].start_date).tz('Asia/Dubai').format('DD MMM YYYY');
				// 	data.body[i].end_date = moment(data.body[i].end_date).tz('Asia/Dubai').format('DD MMM YYYY');
				// }
				this.inVoices = data.body;
				this.inVoices.forEach((invoice:any)=>{
					let miles= 0;
					invoice.points.forEach((inv:any)=>{
						// console.log(inv);
						if(inv && inv.cost >0){
							miles+=inv.points;
						}
					});
					invoice.totalMiles = miles;
				})
				this.next = data.headers.get('next');
				this.prev = data.headers.get('prev');
				if (this.inVoices.length > 0) {
					this.noInovoices = false;
				} else {
					this.noInovoices = true;
				}
				this.loader = false;
			})
			.catch(error => {
				this.loader = false;
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getPayments(cursor) {
		this.loader = true;
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		this.partnercreationService
			.getPayments(cursor, this.partnerobj.id)
			.then((data: any) => {
				this.payMents = data.body;
				this.paymentsnext = data.headers.get('next');
				this.paymentsprev = data.headers.get('prev');
				if (this.payMents.length > 0) {
					this.noPayments = false;
				} else {
					this.noPayments = true;
				}
				this.loader = false;
			})
			.catch(error => {
				this.error = true;
				this.loader = false;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	gotoTransactionDetains(invoiceid) {
		this.router.navigate(['/transactiondetails'], { queryParams: { invoice: invoiceid } });
	}

	gotoInvoiceDetails(invoiceid) {
		this.router.navigate(['/invoicedetails'], { queryParams: { invoice: invoiceid } });
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
				this.payment_plan = this.billingConfig.payment_plan;
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}


	getData() {
		this.contentful.getContent('1aDAR4V2UCb54TC0AVligE', this.locale).subscribe(dataobj => {
			this.invoiceHeaderImg = dataobj['imageContent'].fields.file.url;
		});
		this.contentful.getContent('1Mpplqk4dTi2lIIASwPEcw', this.locale).subscribe(dataobj => {
			this.paymentsbutton = dataobj['stringContent'];
		});
	}

}
