import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Partner, partner, profileEntryIdObj } from 'src/models';
import { AuthenticationService, CommonService, ContentfulService, PartnercreationService } from 'src/services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {

	partnerobj: Partner = partner[0];
	step: string = 'users';
	userRoleDetailsData:any;
	allUsersArray:any;
	userModalRole :string = 'add'; // this can be add or edit .
	lookups: any = {};
	editUserDataId:any;
	partnerlocations: any = [];
	nextcursor: string = 'None';
	prevcursor: string = 'None';
	currentcursor:string = 'None'; //using it to call again while making user active or inactive
	// industryval: string = '';
	showOnlyActiveUsers: boolean = true;
	systemUserDataId:any;
	editDescription: boolean = false;
	partnerData: Partner = partner[0];
	locale: string = 'en-US';
	profileIdObj = new profileEntryIdObj();
	showsave: boolean = false;
	UsersHeaderImg: any;
	userdata: any;
	partnerId: any;
	billingConfig:any;
	disablePrevNext:boolean = false;
	constructor(private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private router: Router,
		private contentful: ContentfulService,
		private partnerCreationService: PartnercreationService,
		private route: ActivatedRoute,
		private modalService: NgbModal
	) { }

	ngOnInit() {
		this.partnerobj["industry"] = "";
		this.getCMSImage();
		this.getUserRoles();
		this.getUsersOfSystem("None");
		if (this.route.snapshot.queryParamMap.get('partner')) {
			this.partnerId = parseInt(this.route.snapshot.queryParamMap.get('partner'));
		} else {
			this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
		}

		this.userdata = this.authenticationService.currentUserValue;
		this.getBillingConfig(this.partnerId);
		this.commonService.scrollTop();
		this.commonService.getPartnerData(this.partnerId).subscribe(data => {
			this.partnerobj = JSON.parse(JSON.stringify(data));
			// if (this.partnerobj.extra_data.draft_description) {
			// 	this.description = this.partnerobj.extra_data.draft_description;
			// } else {
			// 	this.description = this.partnerobj.description;
			// }
			// this.industryval = this.partnerobj['industry'];

		});
	}

	getCMSImage() {
		this.contentful.getContent('2pd24cGaASqd53o4Mhr9iK', this.locale).subscribe(dataobj => {
			this.UsersHeaderImg = dataobj['imageContent'].fields.file.url;
		});
	}

	openAddUserPopUp() {
		// open the Add/edit user Popup
	}


	getUserRoles() {
		this.partnerCreationService.getAllUserRoles().then((data: any) => {
			this.userRoleDetailsData = data;
		});
	}

	getUsersOfSystem(cursor){
		this.disablePrevNext=  true;
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		else{
			cursor = '';
		}

		this.partnerCreationService.getActiveOrInactiveSystemUsers(this.showOnlyActiveUsers,cursor).then((data:any)=>{
			this.allUsersArray = data.body;
			this.nextcursor = data.headers.get('next');
			this.prevcursor = data.headers.get('prev');
			this.disablePrevNext = false;
		}).catch(error=>{
			console.log(error);
		});
	}

	getNavigatedUsers(direction:string){
		if(direction == 'prev'){
			this.currentcursor = this.prevcursor;
			this.getUsersOfSystem(this.prevcursor);
		}
		else{
			this.currentcursor = this.nextcursor;
			this.getUsersOfSystem(this.nextcursor);
		}
	}

	open(content, modalclass, usecase, user?) {
		this.userModalRole = usecase;
		if(user && user.id){
			this.editUserDataId = user?.id;
		}
		// console.log(user);
		// user.user.first_name = "Dhoni"
		// console.log(this.editUserDataId);
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			windowClass: modalclass
		};
		let userEditOrAddModal = this.modalService.open(content, ngbModalOptions);
		userEditOrAddModal.result.then((data)=>{
			console.log(data);
			this.handleUpdatedDataFromEditModal(data);

		}).catch((data:any)=>{
			this.handleUpdatedDataFromEditModal(data);

			// user.user.first_name = "Dhoni";
		});
	}

	close() {
		this.modalService.dismissAll();
	}

	handleUpdatedDataFromEditModal(data: any) {
		if (data && data.id) {
			for (let i = 0; i < this.allUsersArray.length; i++) {
				if (this.allUsersArray[i].id == data.id) {
					this.allUsersArray[i] = data;
				}
			}
		}
	}

	toggleShowInactiveUsers() {
		this.allUsersArray = [];
		this.showOnlyActiveUsers = !this.showOnlyActiveUsers;
		this.clearNavigationCursors();
		this.getUsersOfSystem('None');
	}

	changeActivityOfUser(user:any,content:any,modalclass:any){
		let ngbModalOptions: NgbModalOptions = {
			backdrop: true,
			keyboard: false,
			windowClass: modalclass
		};

		this.systemUserDataId = user.id;
		let activeConfirmationModal = this.modalService.open(content,ngbModalOptions);
		activeConfirmationModal.result.then((data:any)=>{ // means success
			this.handleActiveModalClose(data.taskDone);
			// console.log("from data");
		},(error:any)=>{
			this.handleActiveModalClose(error.taskDone);
			console.log(error);// means no change in user so no change in list
			// console.log("from error");
		});
	}

	handleActiveModalClose(success:boolean){
		if(success){
			this.getUsersOfSystem(this.currentcursor);
		}
	}

	clearNavigationCursors(){
		this.nextcursor = 'None';
		this.prevcursor= 'None';
		this.currentcursor= 'None';
	}

	getBillingConfig(partnerId) {
		this.partnerCreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
			})
			.catch(error => {
				// this.showErrorMsg(error);
			});

	}

}
