import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, ContentfulService, StrategicparternsService } from '../../services';
import { debounceTime , distinctUntilChanged} from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from "lodash";

@Component({
  selector: 'app-strategicpartneview',
  templateUrl: './strategicpartneview.component.html',
  styleUrls: ['./strategicpartneview.component.less'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class StrategicpartneviewComponent implements OnInit {
  sponsorsstages: any=[];
  sponsorstagesstring= '';
  sponsorsData: any= [];
  step= 'strategicpage';
  private searchSub$ = new Subject<string>();
  error = false;
  errorMsg = '';
  mainsponsordata : any= [];
  mainsponsorname = '';
  childsponsors: any=[];
  childsponsorscopy: any=[];
  mainchildssponsorscopy : any= [];
  searchbrandname = '';
  filtersmenushow = false;
  statuschecked = false;
  industriesData: any = [];
  SponsorstagesList: any = [];
  gridmenu = true;
  listmenu= false;
  searchstages: string;
  searchindus: string;
  stageslist: any = [];
  induslist: any = [];
  prentspo: string;
  filterlistofindustries: any = [];
  constructor(private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private strategicpartnersService: StrategicparternsService) { }

  ngOnInit() {
    this.getIndustries();
    this.getSponsorStages();
    this.searchSub$.pipe(
     debounceTime(700)
   ).subscribe(() => {
    this.getfiltersdata();
   });

  }

  getSponsor(data){
    this.mainsponsordata = data;
  }

  getchilds(id){
    this.strategicpartnersService
      .StrategicPartners(id)
      .then((data: any ) => {
        if(data.length > 0){
          this.filterlistofindustries = [];
          for(var m=0; m<data.length;m++){
            data[m].industryname = '';
            for(var j=0; j<this.industriesData.length; j++){
              if(data[m].industry === this.industriesData[j].code){
                data[m].industryname = this.industriesData[j].name;
                 var Index = _.findIndex(this.filterlistofindustries, {
                  "code": this.industriesData[j].code
                });
                  if(Index === -1){
                    var oneIndustrydata = this.industriesData[j];
                    oneIndustrydata.active=false;
                     this.filterlistofindustries.push(oneIndustrydata);
                  }
              }
            }
          }
          for(var v=0; v<data.length; v++){
            for(var pd=0; pd<this.SponsorstagesList.length; pd++){
              if(data[v].status === this.SponsorstagesList[pd].code){
                data[v].get_status_display = this.SponsorstagesList[pd].visible_name;
              }
            }
          }
          for(var t=0; t<data.length; t++){
            this.childsponsors.push(data[t]);
          }
          // var inds2 = [];
          // for(var lstinds2=0; lstinds2<this.filterlistofindustries.length; lstinds2++){
          //   inds2.push(encodeURIComponent(this.filterlistofindustries[lstinds2].code));
          // }
          // this.searchindus = inds2.toString();
          this.strategicpartnersService.setChildSponsors(this.childsponsors);
        }
      });
  }

  getChildSponsors(){
     var childs = this.strategicpartnersService.getChildSponsors();
     this.filterlistofindustries = [];
     this.childsponsors = [];
     for(var q=0; q<childs.length;q++){
      childs[q].industryname = '';
      for(var j=0; j<this.industriesData.length; j++){
        if(childs[q].industry === this.industriesData[j].code){
          childs[q].industryname = this.industriesData[j].name;
          var Index = _.findIndex(this.filterlistofindustries, {
          "code": this.industriesData[j].code
        });
          if(Index === -1){
             this.filterlistofindustries.push(this.industriesData[j]);
          }
        }
      }
    }
    for(var u=0; u<childs.length; u++){
      for(var pf=0; pf<this.SponsorstagesList.length; pf++){
        if(childs[u].status === this.SponsorstagesList[pf].code){
          childs[u].get_status_display = this.SponsorstagesList[pf].visible_name;
        }
      }
    }
    for(var tc=0; tc<childs.length; tc++){
      this.childsponsors.push(childs[tc]);
    }
    var inds = [];
    for(var lstinds=0; lstinds<this.filterlistofindustries.length; lstinds++){
      inds.push(encodeURIComponent(this.filterlistofindustries[lstinds].code));
    }
    this.searchindus = inds.toString();
  }  

  getSponsorStages(){
    this.SponsorstagesList = [{code: "A", visible_name: "Active", active: true}, {code: "S", visible_name: "Suspended", active: true}, {code: "T", visible_name: "Terminated", active: true}]
  }

  getIndustries(){
    this.strategicpartnersService
    .getIndustriesLookupValues()
    .then((data: any) => {
      this.industriesData = data;
      if(this.strategicpartnersService.getParentSponsor().length > 0){
      var parentsponsor = this.strategicpartnersService.getParentSponsor()[0];
      this.mainsponsordata = parentsponsor;
      this.prentspo = parentsponsor['id'];
      this.getChildSponsors();
    } else {
      this.getSponsor(JSON.parse(localStorage.getItem('parentSponsor')));
      this.getchilds(JSON.parse(localStorage.getItem('parentSponsor'))['id']);
      this.prentspo = JSON.parse(localStorage.getItem('parentSponsor'))['id'];
    }
    }).catch(error => {
        this.showErrorMsg(error);
      });
  }

  filterstatussponsors(status){
    this.stageslist = [];
    if(status !== undefined && status !== null && status !== '') {
      for(var j=0;j<this.SponsorstagesList.length; j++){
        if(this.SponsorstagesList[j].active === true){
          this.stageslist.push(this.SponsorstagesList[j].code);
        }
        // } else {
        //   this.SponsorstagesList[j].active = false;
        // }
      }
    }
    this.searchstages = this.stageslist.toString();
    this.getfiltersdata();
  }

  filterindusponsor(indus){
   this.induslist = [];
    if(indus !== undefined) {
      for(var k=0;k<this.filterlistofindustries.length; k++){
        if(this.filterlistofindustries[k].active === true){
          this.induslist.push(encodeURIComponent(this.filterlistofindustries[k].code));          
        } else{
        }
      }
      this.searchindus = this.induslist.toString();
    }
    this.getfiltersdata();
  }

  getSearchBrands(searchval){
     this.searchbrandname = searchval;
    this.searchSub$.next();
  }

  getfiltersdata(){
    if(this.searchstages === '' || this.searchstages === undefined){
      this.searchstages = 'A,S,T';
    }
    this.strategicpartnersService
      .getSearchFilterSponsorsData(this.prentspo, this.searchstages, this.searchindus, this.searchbrandname)
      .then((data: any) => {
        this.childsponsors = [];
        //this.filtersmenushow = false;
        for(var k=0; k<data.length;k++){
          data[k].industryname = '';
          for(var l=0; l<this.industriesData.length; l++){
            if(data[k].industry === this.industriesData[l].code){
              data[k].industryname = this.industriesData[l].name;
            }
          }
        }
        for(var mn=0; mn<data.length; mn++){
          for(var p=0; p<this.SponsorstagesList.length; p++){
            if(data[mn].status === this.SponsorstagesList[p].code){
              data[mn].get_status_display = this.SponsorstagesList[p].visible_name;
            }
          }
        }
        for(var tn=0; tn<data.length; tn++){
          this.childsponsors.push(data[tn]);
        }
      }).catch(error => {
         this.showErrorMsg(error);
      });
  }
 

  showErrorMsg(error) {
    this.error = true;
    this.errorMsg = this.commonService.showCommonErrorMsg(error);
  }

  showfilteropts(){
    this.filtersmenushow = !this.filtersmenushow;
  }

  showgridmenu(){
    this.gridmenu = !this.gridmenu;
    this.listmenu = false;
  }

  showlistmenu(){
    this.listmenu = !this.listmenu;
    this.gridmenu = false;
  }

  gotoDashboard(brand){
    if(brand.status !== 'T'){
      this.router.navigate(['/dashboard'], { queryParams: { partner: brand.id }});
      localStorage.setItem('partnerimg', brand['logo']);
       localStorage.setItem('partnername', brand['name']);
    }
  }
  gotomaindashboard(id){
     localStorage.setItem('partnerimg', this.mainsponsordata['logo']);
     localStorage.setItem('partnername', this.mainsponsordata['name']);
     this.router.navigate(['/dashboard'], { queryParams : { partner: id }});
  }

  onClick(event){
    if(event.target.className !== 'filterbar' &&  event.target.className !== 'filterimg' && event.target.className !== 'custom-chec-box checked' &&  event.target.className !== 'checkmark' && event.target.className !== 'filteroptions' && event.target.className !== 'sponsormenuitems' && event.target.className !== 'containerfilter' && event.target.className !== 'statusd' && event.target.className !== 'divider' && event.target.className !== 'filterbar' && event.target.className !== 'stasimg') {
      this.filtersmenushow = false;
    }
  }
}
