import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "./../environments/environment";
import { User } from '../models';
import { Router, ActivatedRoute } from '@angular/router';
import { VersionCheckService } from '../services/version-check.service';
import {
    NgbModal, NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    //private versionCheckService: VersionCheckService;

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private versionCheckService: VersionCheckService, private modalService: NgbModal) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    private host = environment.host();

    public get currentUserValue(): User {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        return this.currentUserSubject.value;
    }

    login(request: any) {
        return this.http.post<any>(this.host+"login/", request)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    user.header = true;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('status','loggedin');
                    this.currentUserSubject.next(user);
                    localStorage.setItem('partnerimg', user['sponsor_logo']);
                    localStorage.setItem('partnername', user['sponsor_name']);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        //localStorage.removeItem('currentUser');
        this.modalService.dismissAll();
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(['/skywardseveryday']);
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);

    }

    // getPartnerData(parnerId: any){
    //     return this.http.get<any>(this.host+"sponsors/"+parnerId)
    //         .pipe(map(partnerObj => {
    //             // login successful if there's a jwt token in the response
    //             if (partnerObj) {
    //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
    //                 localStorage.setItem('partnerObj', JSON.stringify(partnerObj));
    //                 this.partnerObj.next(partnerObj);
    //             }
    //             return partnerObj;
    //         }));
    // }
}