import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, CommonService, PartnercreationService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-locationdeletemodal',
	templateUrl: './locationdeletemodal.component.html',
	styleUrls: ['./locationdeletemodal.component.less']
})
export class LocationdeletemodalComponent implements OnInit {
	@Input() locationObj: any;
	@Input() partnerobj: any;
	@Input() index: any;
	@Input() partnerlocations: any;
	constructor(
		private modalService: NgbModal,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private translate: TranslateService,
		private partnercreationService: PartnercreationService
	) { }

	ngOnInit() {
	}

	close() {
		this.modalService.dismissAll();
	}

	removeLocation(){
		this.locationObj.active = false;
		this.partnerlocations[this.index].active = false;
		this.partnercreationService
			.deactivateLocation(this.partnerobj,this.locationObj)
			.then((data: any) => {
				this.close();
			})
			.catch(error => {
				console.log(error.error);
			});
	}
}
