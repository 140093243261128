import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { stepFiveCMSModel, stepFiveEntryIdObj } from "../../models";
import * as _ from "lodash";

@Component({
	selector: 'app-locationsresetmodal',
	templateUrl: './locationsresetmodal.component.html',
	styleUrls: ['./locationsresetmodal.component.less']
})
export class LocationsresetmodalComponent implements OnInit {
	@Output('getMarkers') removeMarkers = new EventEmitter<any>();
	@Input() modalData: any;
	stepFiveIdObj = new stepFiveEntryIdObj();
	stepFiveCMSData = new stepFiveCMSModel();
	locale: string = 'en-US';
	constructor(
		private modalService: NgbModal,
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.getData(this.modalData);
	}

	close() {
		this.modalService.dismissAll();
	}

	removeLocations() {
		this.removeMarkers.emit();
		this.close();
	}
	getData(obj) {
    let contentFulIds = this.stepFiveIdObj.entryIds;
    for (let entry of contentFulIds) {
      let Index = _.findIndex(obj.items, {
        "sys":{
          "id": entry.id
        }
      });
      if (Index !== -1 && obj.items[Index]['fields']['stringContent']!==undefined) {
          this.stepFiveCMSData[entry.text] = obj.items[Index]['fields']['stringContent'];
      }
    }

  }

}
