import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-offercommdatepickmodal',
  templateUrl: './offercommdatepickmodal.component.html',
  styleUrls: ['./offercommdatepickmodal.component.less']
})
export class OffercommdatepickmodalComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }
  selectedAMPMFormat= 'AM';
  hour="12";
  minutes='00';
  selectedCommDate: any = moment(new Date()).format('YYYY-MM-DD');
  minDate = moment();
  @Output('totalDateTimeEmitter') totalDateTimeEmitter = new EventEmitter<any>();
  timeHourMintesValidatorRegex = new RegExp("^(0?[1-9]|1[0-2]):[0-5][0-9]$");
  isTimeValid:boolean=true;
  ngOnInit(): void {
  }

  choosedCommDate(event:any){
    this.selectedCommDate  = moment(event.chosenLabel.replace('/','-')).format('YYYY-MM-DD');
  }

  closeModal() {
		// this.activeModal.close({"hours":this.hour,"minutes":this.minutes});
    this.modalService.dismissAll();
	}
  sendSelectedDateTime(){
    this.totalDateTimeEmitter.emit({"time":this.hour+':'+this.minutes+' '+this.selectedAMPMFormat,'selectedDate':this.selectedCommDate});
    this.closeModal();
  }
  selectAMPM(type){
    this.selectedAMPMFormat= type;
  }

  checkForCorrectTime(){
    var timehm = this.hour + ":"+ this.minutes;
    this.isTimeValid = this.timeHourMintesValidatorRegex.test(timehm);
  }


}
