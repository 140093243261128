import { Component, OnInit, Input } from '@angular/core';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { ContentfulService } from '../../services';

@Component({
  selector: 'app-offercreationmodal',
  templateUrl: './offercreationmodal.component.html',
  styleUrls: ['./offercreationmodal.component.less']
})
export class OffercreationmodalComponent implements OnInit {
  @Input() fromcancel: boolean = false;
  @Input() offerdata: any;
  offerImage: any;
  locale: string = 'en-US';

  constructor(private modalService: NgbModal,
    private contentful: ContentfulService
    ) { }

  ngOnInit() {
    this.getData();
  }

  close() {
		this.modalService.dismissAll();
	}

  getData() {
    this.contentful.getContent('590GZWYMnV06Y8JVBVOcSx', this.locale).subscribe(dataobj => {
      this.offerImage = dataobj['imageContent'].fields.file.url;
    });
  }
}
