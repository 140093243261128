import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import { PartnercreationService, CommonService } from '../../services';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";


@Component({
	selector: 'app-editprofile',
	templateUrl: './editprofile.component.html',
	styleUrls: ['./editprofile.component.less']
})
export class EditprofileComponent implements OnInit {

	@Input() partnerobj: any;
	@Input() selectedDetails: any;
	@Input() userdata: any;
	@Output('getPartnerDetails') partnerdetails = new EventEmitter<any>();
	error: boolean = false;
	errorMsg: string = '';
	partnerData: any;
	countrycode: string = '57';
	phonenumber: any;
	landline_number: any;
	finanacenumber: any;
	constructor(
		private modalService: NgbModal,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService
	) { }

	ngOnInit() {
		this.partnerData = cloneDeep(this.partnerobj);
		this.phonenumber = this.partnerData.phone_number.split("27")[1];
		this.landline_number = this.partnerData.extra_data.landline.split("27")[1];
		if (this.partnerData.extra_data.finanace_number) {
			this.finanacenumber = this.partnerData.extra_data.finanace_number.split("27")[1];
		}
	}

	close() {
		this.modalService.dismissAll();
	}

	checkNumber(event) {
		return this.commonService.checkNumber(event);
	}
	save() {
		delete this.partnerData.logo;
		delete this.partnerData.header;
		delete this.partnerData.description;
		// delete this.partnerData.contact_person_name;
		delete this.partnerData.contact_person_first_name;
		delete this.partnerData.contact_person_last_name;
		delete this.partnerData.phone_number;
		this.partnerData.extra_data.landline = this.countrycode + this.landline_number;
		if (this.finanacenumber) {
			this.partnerData.extra_data.finanace_number = this.countrycode + this.finanacenumber;
		} else {
			this.partnerData.extra_data.finanace_number = null;
		}
		this.partnercreationService
			.updatePartnerData(this.partnerData.id, this.partnerData)
			.then((data: any) => {
				this.partnerdetails.emit(data.body);
				this.close();
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}
	updateUser() {
		var request = {
			'mobile': this.countrycode + this.phonenumber,
			'user': {
				'first_name': this.userdata.user.first_name,
				'last_name': this.userdata.user.last_name,
				'username': this.partnerData.user_name
			},
			'sso_login_only': this.userdata.sso_login_only,
			'role': this.userdata.role.id
		};
		this.partnercreationService
			.updateUserInfo(this.userdata.id, request)
			.then((data: any) => {
				this.save();
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

}
