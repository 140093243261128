import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.less']
})
export class ToggleButtonComponent implements OnInit {
	@Output() changed = new EventEmitter<boolean>();
	constructor() { }

	ngOnInit() {
	}

}
