export class stepFiveCMSModel {
    stepFiveHeader: string;
    stepFiveDescription: string;
    yourcompanylogoheader: string;
    yourcompanylogoDescription1 : string;
    yourcompanylogoDescription2 : string;
    yourcompanylogoDescription3 : string;
    aboutYourBrandHeader : string;
    aboutYourBrandDescription1 : string;
    aboutYourBrandDescription2 : string;
    aboutYourBrandDescription3 : string;
    boutYourBrandDescription3 : string;
    yourParticipatingOutletsHeader : string;
    yourParticipatingOutletsDescription1 : string;
    yourParticipatingOutletsDescription2 : string;
    addManuallyCTA : string;
    getItFromGoogleCTA : string;
    transactionSettingsHeader : string;
    transactionSettingsDescription1 : string;
    transactionSettingsDescription2 : string;
    transactionSettingsDescription3 : string;
    transactionSettingsDescription4 : string;
    transactionSettingsDescription5 : string;
    transactionSettingsDescription6 : string;
    transactionSettingsDescription7 : string;
    transactionSettingsDescription8 : string;
    transactionSettingsDescription9 : string;
    trainingMaterialsHeader : string;
    trainingMaterialsDescription : string;
    marketingAndBrandingGuidelinesHeader : string;
    marketingAndBrandingGuidelinesDescription : string;
    saveandsubmit: string;
    merchantPortalCopy:string;
    locationPaymentTextDesc:string;
}


export class stepFiveEntryIdObj {
  	entryIds = [
      { id:'1OoYR0NKYUPvkeuqWmerdS', text : 'stepFiveHeader'},
      { id:'3I4rvHRQuXs7QIt5egcmyp', text : 'stepFiveDescription'},
      { id:'2owgkdTePbKu00cRp8XlyT', text : 'yourcompanylogoheader'},
      { id:'76pptwJI7xKt7L719f6QSf', text : 'yourcompanylogoDescription1'},
      { id:'5CSmCYxPd9G7GLdWwL5zsc', text : 'yourcompanylogoDescription2'},
      { id:'ki74fGlscdOvrHv0Gz0xP',  text : 'yourcompanylogoDescription3'},
      { id:'60XtjEaJxV1v4OVG4yoXZA', text : 'aboutYourBrandHeader'},
      { id:'7w0du4N4YNOTi1gx912jZB', text : 'aboutYourBrandDescription1'},
      { id:'384QcQ4t95l3rS5GP4WbDk', text : 'aboutYourBrandDescription2'},
      { id:'384QcQ4t95l3rS5GP4WbDk', text : 'aboutYourBrandDescription3'},
      { id:'7phAg3tH3VsKUvsAlNStP2', text : 'yourParticipatingOutletsHeader'},
      { id:'2UYWDzm1QrT2nJCBaf8kgl', text : 'yourParticipatingOutletsDescription1'},
      { id:'506VCQAVCFQeAibdiKdtqX', text : 'yourParticipatingOutletsDescription2'},
      { id:'G3yP3zEhEfMKBRArgEhWA',  text : 'addManuallyCTA'},
      { id:'YyhwOXMuAjRQP7pmIXxCI',  text : 'getItFromGoogleCTA'},
      { id:'3YNNqd9XpuKJB5rkWw6btF',  text : 'transactionSettingsHeader'},
      { id:'4UMhyXqEeZNjTheu0rBRqc',  text : 'transactionSettingsDescription1'},
      { id:'2GlMA53OOJLSpoj4qBoxmr',  text : 'transactionSettingsDescription2'},
      { id:'7xtgIh9FvvL2fr9Rk5CxVy',  text : 'transactionSettingsDescription3'},
      { id:'5HeETRikl9CdvQBUZ9h4dS',  text : 'transactionSettingsDescription4'},
      { id:'3qZscpOKrE8vyRvWrG1PXf',  text : 'transactionSettingsDescription5'},
      { id:'2ov9Bo1NtXlaNqX8Gm54Ad',  text : 'transactionSettingsDescription6'},
      { id:'2Ex3gY8ZmldDnjSq9aBFqk',  text : 'transactionSettingsDescription7'},
      { id:'KY9Vl40E9TFzwdhneOlCC',  text : 'transactionSettingsDescription8'},
      { id:'33xvpwiqqPLgoElxaP2D4D',  text : 'transactionSettingsDescription9'},
      { id:'4DTwC8Ni348h18Bh3oU4Ku',  text : 'trainingMaterialsHeader'},
      { id:'792Ra0KFVqrYjouTEeCiIJ',  text : 'trainingMaterialsDescription'},
      { id:'5vh8yAYYYNVM0mNLkMOCO3',  text : 'marketingAndBrandingGuidelinesHeader'},
      { id:'3oYEAQQN69NbFewfsVSR5f',  text : 'marketingAndBrandingGuidelinesDescription'},
      { id:'5mK0mt3w6CD2e8DVpdfFiG',  text : 'saveandsubmit'},
      { id:'7L17PjMNCpt1EhQ7AIzhRk', text: 'letsGoVisaTooltip'},
      {id:'W3Uk2Hbjplvt6DO0kHE5W', text:'locationPaymentTextDesc'}
  	];
}