import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, ContentfulService, PartnercreationService } from 'src/services';
import { InvitedUser, UserInviteErrorsObj, invitedUser, userInviteErrorsObj } from 'src/models/invitedUser';
import { rolesEntryIdObj } from 'src/models/cms/roles';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.less']
})
export class EditUserModalComponent implements OnInit, OnDestroy{

  constructor(
    private modalService: NgbModal,
    private partnerCreationService: PartnercreationService, 
    private authenticationService :AuthenticationService,
    private contentful:ContentfulService) 
  { }
  
  @Input() userForEditId:any;
  @Input() userRoleDetails:any;
  @Input() letModalRole:any;

  userdata:any;
  showGlobalError:boolean = false;
  topErrorModalMessage:any;
  addUserObj:InvitedUser;
  emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; 
  errorsObj :UserInviteErrorsObj;
  editUserObj:any;
  userMobile:any;
  showTooltip:boolean = false;
  toolTipMessage:string = 'Select a role to know details';
  editUserObjLocationList:any;
  contactPreCode :string = '27';

  rolesEntryIdObj = new rolesEntryIdObj();
  rolesCMSData:any = [];
  userRolesMetadata= {
    superUser:{
      code: "SuperUser",
      descriptionTooltip:""
    },
    programManager:{
      code: "PROGRAM_MANAGER",
      descriptionTooltip:""
    },
    marketingManager:{
      code: "MARKETING_MANAGER_BRAND",
      descriptionTooltip:""
    },
    financeManager:{
      code: "FINANCE_MANAGER_-_BRAND",
      descriptionTooltip:""
    },
    brandManager: {  //For Brand Manager 
      code: "SPONSOR_REPRESENTATIVE",
      descriptionTooltip:""
    },
    locationManager: {
      code: "SPONSOR_LOCATION_REPRESENTATIVE",
      descriptionTooltip:""
    }
  };
  showSponsorLocations:boolean = false;
  sponsorLocationsList:[];
  selectedLocationRepLocation:any;

  ngOnInit(): void {
    this.userdata = this.authenticationService.currentUserValue;
    this.addUserObj  =  JSON.parse(JSON.stringify(invitedUser[0]));
    this.errorsObj= JSON.parse(JSON.stringify(userInviteErrorsObj[0]));
    // console.log(this.userForEditId);
    this.resetErrorMessages();
    if(this.userRoleDetails && this.letModalRole == 'edit' && this.userForEditId){
      // console.log(this.userRoleDetails);
      this.getSystemUserDataForEdit(this.userForEditId);
    }
    else{
      setTimeout(()=>{
        this.handleShowHideOrTooltip();
      },100);
    }
    this.getData();
  }

  ngOnDestroy(): void {
    let roleDescTooltipEnding = document.getElementById('role-description-tooltip');
    if(roleDescTooltipEnding && roleDescTooltipEnding instanceof HTMLElement){
      roleDescTooltipEnding.removeEventListener('mouseenter',()=>{});
      roleDescTooltipEnding.removeEventListener('mouseleave',()=>{});
    }
  }

  getData() {
		let contentFulIds = this.rolesEntryIdObj.entryIds;
    let metadata = this.userRolesMetadata;
    // console.log(metadata);
		for (let entry of contentFulIds) {
			this.contentful.getContent(entry.id, 'en-US').subscribe((dataobj:any) => {
        Object.keys(metadata).forEach((key:any)=> { 
          if(metadata[key].code == dataobj.paragraphheader ) 
          {metadata[key].descriptionTooltip = dataobj.paragraphbody }
        });
			});
		}
	}

  handleShowHideOrTooltip(){
    let timePeriodSet;
    let roleDescTooltip = document.getElementById('role-description-tooltip');
    if(roleDescTooltip && roleDescTooltip instanceof HTMLElement){
      roleDescTooltip.addEventListener('mouseenter',()=>{
        timePeriodSet = setTimeout(()=>{
          this.showTooltip = true;
          this.fillToolTipDescription();
        },200);
      });
  
      roleDescTooltip.addEventListener('mouseleave',()=>{
        this.showTooltip = false;
        clearTimeout(timePeriodSet);
      });
    }
  }


  fillToolTipDescription(){
    let mode = this.letModalRole;
    let currentRole ;
    let currentCode;
    if(mode == 'add'){
     currentRole = this.addUserObj.role_name;
    }
    else{
      currentRole = this.editUserObj.role_name;
    }

    this.toolTipMessage = 'Select a role to know details';
    this.userRoleDetails.forEach((role:any)=>{
      if(role.name === currentRole ){
        currentCode = role.code;
      }
    });

    if(currentCode){
      let metadataroles= this.userRolesMetadata;
      Object.keys(metadataroles).forEach((key:any)=> { 
        if(metadataroles[key].code == currentCode) 
        {
          this.toolTipMessage = metadataroles[key].descriptionTooltip;
        }
      });
    }
  }

  handleUpdateUserRole(event:any){
    // console.log(event.target.value);
    let selectedRoleName = event?.target?.value;
    let selectedRoleCode = '';
    // console.log(selectedRoleName);
    this.resetAddUserObjValues();
    this.showSponsorLocations = false;
    this.userRoleDetails.forEach((role:any)=>{
      if(role.name === selectedRoleName){
        // console.log(role);
        selectedRoleCode  = role.code;
        if(this.letModalRole == 'add'){
          this.addUserObj.role= role.id;
          // addUserObj.role_name  = role.name; -- Not needed, comes from html select
          this.addUserObj.access_level = role.access_level;
        }
        else{
          this.editUserObj.role= role.id;
          // addUserObj.role_name  = role.name; -- Not needed, comes from html select
          this.editUserObj.access_level = role.access_level;
        }
       
      }
    });
    if(selectedRoleCode){
      if(this.userRolesMetadata.brandManager.code == selectedRoleCode ||
        this.userRolesMetadata.marketingManager.code == selectedRoleCode ||
        this.userRolesMetadata.financeManager.code == selectedRoleCode
        ){
        let sponsorArray = [{"id":this.userdata.sponsor_id}];
        if(this.letModalRole == 'add'){
          this.addUserObj.sponsor_sponsorlocations_data = sponsorArray;
        }
        else{
          this.editUserObj.sponsor_sponsorlocations_data = sponsorArray;
        }
      }

      else if(this.userRolesMetadata.locationManager.code == selectedRoleCode){
        this.errorsObj.location.present = true; 
        // intentionally adding error to not send invite API request unless locations are fetched.
        this.getLocationsForASponsor();
      }

      else if(this.userRolesMetadata.programManager.code == selectedRoleCode){
        this.addUserObj.sponsor_sponsorlocations_data = [];
        this.editUserObj.sponsor_sponsorlocations_data = [];
      }

    }
  }

  getLocationsForASponsor(){
    this.partnerCreationService.getPartnerLocations(this.userdata.sponsor_id).then((data:any)=>{
      // console.log(data.body);
      this.selectedLocationRepLocation = '';
      this.sponsorLocationsList = data.body;
      this.showSponsorLocations= true;
      this.errorsObj.location.present = false;
    }).catch((error)=>{
      console.log(error);
      this.errorsObj.location.present = false;
    })
  }

  checkForValidations(){
    this.resetErrorMessages();

    let userCopy;

    if(this.letModalRole == 'edit'){
      userCopy = this.editUserObj;
    }
    else{
      userCopy = this.addUserObj;
    }

    // console.log({userCopy});

    let currentRoleCoder ;

    if(userCopy.role_name){
      this.userRoleDetails.forEach((role:any)=>{
        if(role.name === userCopy.role_name){
          currentRoleCoder = role.code;}
      });
    }

    // console.log(currentRoleCoder);

    userCopy.mobile = this.userMobile;
    // console.log(userCopy.mobile);
    // console.log(this.emailRegex.test(userCopy.user.email));

    if(userCopy.user.first_name==''){
      this.errorsObj.first_name.present = true;
    }
    if(userCopy.user.last_name==''){
      this.errorsObj.last_name.present = true;
    }
    if(userCopy.user.email=='' || !this.emailRegex.test(userCopy.user.email)){
      this.errorsObj.email.present = true;
    }
    if((!userCopy.mobile) ||  userCopy.mobile=='' || (userCopy.mobile.length>12 || userCopy.mobile.length<8)){
      this.errorsObj.mobile.present = true;
    }
    if(userCopy.role_name==''){
      this.errorsObj.role.present = true;
    }
    if(currentRoleCoder &&  currentRoleCoder == this.userRolesMetadata.locationManager.code && this.selectedLocationRepLocation==''){
      this.errorsObj.location.present = true;
    }

    // console.log(userCopy);
    // console.log(this.userRolesMetadata.locationManager.code);

    return (this.errorsObj.location.present ||
    this.errorsObj.first_name.present ||
    this.errorsObj.last_name.present ||
    this.errorsObj.email.present ||
    this.errorsObj.mobile.present ||
    this.errorsObj.role.present ||
    this.errorsObj.location.present);
  }

  resetErrorMessages(){
    this.errorsObj.first_name.present= false;
    this.errorsObj.last_name.present = false;
    this.errorsObj.email.present = false;
    this.errorsObj.mobile.present = false;
    this.errorsObj.role.present = false;
    this.errorsObj.location.present = false;
    this.errorsObj.apiResponseError.present = false;
    this.errorsObj.apiResponseError.messege = 'Some Error Occurred';
  }

  resetAddUserObjValues(){
    this.addUserObj.sponsor_sponsorlocations_data = [];
    this.addUserObj.role= '';
    this.addUserObj.access_level = '';
  }

  updateUserLocation(event:any){
    // console.log(event.target.value);
    let locationData:any = {};
    this.sponsorLocationsList.forEach((value:any)=>{
      // console.log(value);
      if(value.location_code == event.target.value){
        locationData.id = 1;
        locationData.location_category = value.location_category;
        locationData.name = value.sponsor_name;
        locationData.sponsor_locations = [];
        locationData.sponsor_locations.push(value.id);
      }
    });
    if(this.letModalRole == 'add'){
      this.addUserObj.sponsor_sponsorlocations_data = [];
      this.addUserObj.sponsor_sponsorlocations_data.push(locationData);
    }
    else{
      this.editUserObj.sponsor_sponsorlocations_data = [];
      this.editUserObj.sponsor_sponsorlocations_data.push(locationData);
    }

    // console.log(this.addUserObj);

  }

  closeModal(){
		this.modalService.dismissAll();
  }

  closeModalAndUpdateList(updatedData) {
    this.topErrorModalMessage= '';
		this.modalService.dismissAll(updatedData);
	}

  sendInviteToUser(){
    let isErrorPresent = this.checkForValidations();
    if(!isErrorPresent){
      this.addUserObj.mobile = '+27' + this.userMobile;
      this.partnerCreationService.inviteASystemUser(this.addUserObj).then((data:any)=>{
        this.closeModal();
      }).catch((error:any)=>{
        this.showGlobalError = true;
        this.errorsObj.apiResponseError.present = true;
        if(error?.error?.message){
          this.errorsObj.apiResponseError.messege = error.error.message;
        }
      });
    }
  }

  getSystemUserDataForEdit(userId){
    if(this.userForEditId){
      this.partnerCreationService.getSystemUserDataUsingId(userId).then((data:any)=>{
        this.editUserObj  = data;
        // console.log(data.mobile);
        this.userMobile = data.mobile.split(this.contactPreCode)[1];
        // console.log(this.userMobile);
        if(this.editUserObj && this.editUserObj.role_name){
          this.userRoleDetails.forEach((data:any)=>{
            if(data.code == this.userRolesMetadata.locationManager.code && data.name == this.editUserObj.role_name){
              this.showSponsorLocations = true;
            }
          });
        }
        this.editUserObjLocationList = this.editUserObj.sponsor_sponsorlocations_data;
        // console.log(this.editUserObjLocationList);
        setTimeout(()=>{
          this.handleShowHideOrTooltip();
        },100);
      })
    }
  }

  updateUserdetails(){
    let isErrorPresent = this.checkForValidations();
    if(!isErrorPresent){
      this.editUserObj.mobile = '+27' + this.userMobile;
      this.partnerCreationService.updateSystemUserDetails(this.editUserObj,this.editUserObj.id).then((data)=>{
        // console.log(data);
        this.closeModalAndUpdateList(data);
        // console.log(this.editUserObj);
      }).catch((error:any)=>{
        console.log(error);
        this.showGlobalError = true;
        this.errorsObj.apiResponseError.present= true;
        this.editUserObj.apiResponseError.messege= error.error.messege;
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  
  removeGlobalError(){
    this.showGlobalError = false;
  }

}
