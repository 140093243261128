import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService, ContentfulService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { marketingPageCMSModel, marketingEntryIdObj } from "../../models";
import * as _ from "lodash";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import * as $ from 'jquery';

@Component({
  selector: 'app-skywardseveryday',
  templateUrl: './skywardseveryday.component.html',
  styleUrls: ['./skywardseveryday.component.less'],
  providers: [NgbCarouselConfig]
})
export class SkywardseverydayComponent implements OnInit {
  locale: string = 'en-US';
  marketingPageCMSData = new marketingPageCMSModel();
  marketingPageIdObj = new marketingEntryIdObj();
  showStickyHeader: boolean = false;
  slideMobileConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: true,
    infinite: true,
    variableWidth: false,
    autoplay: true
  };
  slides = [];
  slideContent: any;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.showStickyHeader = window.pageYOffset > 610;
  }


  constructor(
    config: NgbCarouselConfig,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private contentful: ContentfulService
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.commonService.scrollTop();
    this.getData();
  }

  gotoPage(url) {
    this.router.navigate([url]);
  }

  getData() {
    let contentFulIds = this.marketingPageIdObj.entryIds;
    let contentFulEssetIds = this.marketingPageIdObj.essetIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys":{
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent']!==undefined) {
            this.marketingPageCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }
        
      }
      this.slides[0] = {
        'img': this.marketingPageCMSData.CardOneImg,
        'title': this.marketingPageCMSData.powerOfEmiratesHeader,
        'description': [this.marketingPageCMSData.powerOfEmiratesDescription, this.marketingPageCMSData.powerOfEmiratesDescription1, this.marketingPageCMSData.powerOfEmiratesDescription2, this.marketingPageCMSData.powerOfEmiratesDescription3]
      }
      this.slides[1] = {
        'img': this.marketingPageCMSData.CardTwoImg,
        'title': this.marketingPageCMSData.ItsValueForMoneyHeader,
        'description': [this.marketingPageCMSData.ItsValueForMoneyDescription, this.marketingPageCMSData.ItsValueForMoneyDescription1, this.marketingPageCMSData.ItsValueForMoneyDescription2, this.marketingPageCMSData.ItsValueForMoneyDescription3]
      }
      this.slides[2] = {
        'img': this.marketingPageCMSData.CardThreeImg,
        'title': this.marketingPageCMSData.ItsSimpleAndEffortlessHeader,
        'description': [this.marketingPageCMSData.ItsSimpleAndEffortlessDescription, this.marketingPageCMSData.ItsSimpleAndEffortlessDescription1]
      }
    });

    this.contentful.getAssets(this.locale).subscribe(dataobj => {
      for (let entry of contentFulEssetIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys":{
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields'].file.url!==undefined) {
            this.marketingPageCMSData[entry.text] = dataobj.items[Index]['fields'].file.url;
        }
        
      }
    });
    
  }

}
