import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, PartnercreationService, ContentfulService, CommonService, OffercreationService } from '../../services';
import { offerDetailsCMSModel, offerDetailsEntryIdObj } from "../../models";
import { Offer, offer, Partner, partner, marker } from "../../models";
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from "lodash";
import * as moment from 'moment-timezone';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-offerdetails',
	templateUrl: './offerdetails.component.html',
	styleUrls: ['./offerdetails.component.less']
})
export class OfferdetailsComponent implements OnInit {
	partnerobj: Partner = partner[0];
	offerDetailsCMSData = new offerDetailsCMSModel();
	offerDetailsIdObj = new offerDetailsEntryIdObj();
	offerid: number = null;
	error: boolean = false;
	errorMsg: string;
	locale: string = 'en-US';
	lookups: any = {};
	offerobj: any;
	partnerlocations: any = [];
	rewardType = "";
	locationsLeft: any = [];
	locationsRight: any = [];
	locationCount: number = 0;
	offerStartTimeInAMPM = "00:01 am";
	offerEndTimeInAMPM = "23:59 pm";
	offerStartTime = null;
	offerEndTime = null;
	allDay: boolean = true;
	ageArray = ['18-34', '35-50', '50-70', '71+'];
	multiplierVal: string = '';
	endDate: string = '';
	startDate: string = ''
	showcancel: boolean = false;
	programtimezone: string = "Asia/Dubai";
	programCurrency: string = '';
	headerImage: any;
	today: any = moment(new Date()).format('YYYY-MM-DD');
	startTime: any = '';
	endTime: any = '';
	start_date: any = '';
	end_date: any = '';
	partnerId: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private contentful: ContentfulService,
		private authenticationService: AuthenticationService,
		private partnercreationService: PartnercreationService,
		private commonService: CommonService,
		private offercreationService: OffercreationService,
		private modalService: NgbModal,
	) { }

	ngOnInit() {
		this.getData();
		this.getProgramData();

		if (localStorage.getItem('partnerid') !== undefined && localStorage.getItem('partnerid')  !== null && localStorage.getItem('partnerid') !== '') {
      	  this.partnerId = parseInt(localStorage.getItem('partnerid'));
	    } else {
	      this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
	    }
		this.commonService.getPartnerData(this.partnerId).subscribe(data => {
			this.partnerobj = data;
		});
		this.commonService.getData("lookups", "BONUS_REWARD_MULTIPLIER").subscribe(result => {
			this.lookups["BONUS_REWARD_MULTIPLIER"] = result;
		});
		this.commonService.getData("lookups", "BONUS_REWARD_VALUE").subscribe(result => {
			this.lookups["BONUS_REWARD_VALUE"] = result;
		});
		this.commonService.getData("lookups", "REWARD_TYPE").subscribe(result => {
			this.lookups["REWARD_TYPE"] = result;
		});
		/*this.commonService.getData("lookups", "GENDER").subscribe(result => {
			this.lookups["GENDER"] = result;
		});
		this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
			this.lookups["TIER_CLASS"] = result;
		});*/
	    this.offerid = parseInt(this.route.snapshot.queryParamMap.get('offer'));
		this.getOfferDetails();
	}

	gotoOffersList() {
		if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
        	this.router.navigate(['/offers'], { queryParams: { partner: localStorage.getItem('partnerid') }});
      	} else {
         	this.router.navigate(['/offers']); 
      	}
	}

	getData() {
		let contentFulIds = this.offerDetailsIdObj.entryIds;
		this.contentful.getEntries(this.locale).subscribe(dataobj => {
			for (let entry of contentFulIds) {
				let Index = _.findIndex(dataobj.items, {
					"sys": {
						"id": entry.id
					}
				});
				if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
					this.offerDetailsCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
				}

			}
		});
		this.contentful.getContent('2ixR25RFMFiu4hROVnWJSs', this.locale).subscribe(dataobj => {
			this.headerImage = dataobj['imageContent'].fields.file.url;
		});
	}

	getOfferDetails() {
		this.error = false;
		this.partnercreationService
			.getOfferDetails(this.offerid)
			.then((data: any) => {
				if (moment(data.start_date).tz(this.programtimezone).format('YYYY-MM-DD HH:mm') > moment(new Date()).tz(this.programtimezone).format('YYYY-MM-DD HH:mm')) {
					this.showcancel = true;
				}
				if (data.template_params.offer_start_hour == '00' && data.template_params.offer_start_minute == '00' && data.template_params.offer_end_hour == '23' && data.template_params.offer_end_minute == '59') {
					this.allDay = true;
				} else {
					this.allDay = false;
				}
				this.offerStartTimeInAMPM = moment(data.start_date).tz(this.programtimezone).format("HH:mm a");
				this.offerEndTimeInAMPM = moment(data.end_date).tz(this.programtimezone).format("HH:mm a");
				this.endTime = moment(data.end_date).tz(this.programtimezone).format("HH") + ':' + moment(data.end_date).tz(this.programtimezone).format("mm");
				this.startTime = moment(data.start_date).tz(this.programtimezone).format("HH") + ':' + moment(data.start_date).tz(this.programtimezone).format("mm");
				this.endDate = moment(data.end_date).tz(this.programtimezone).format('D MMM');
				this.startDate = moment(data.start_date).tz(this.programtimezone).format('D MMM');
				this.start_date = moment(data.start_date).tz(this.programtimezone).format('YYYY-MM-DD');
				this.end_date = moment(data.end_date).tz(this.programtimezone).format('YYYY-MM-DD');
				data.start_date = moment(data.start_date).tz(this.programtimezone).format('DD MMM YYYY');
				data.end_date = moment(data.end_date).tz(this.programtimezone).format('DD MMM YYYY');
				this.offerobj = data;
				this.partnerlocations = this.offerobj.locations_data;
				let locationsData = [];
				locationsData = cloneDeep(this.partnerlocations);
				var half_length = Math.ceil(locationsData.length / 2);
				this.locationsLeft = locationsData.splice(0, half_length);
				this.locationsRight = locationsData.splice(0, half_length);
				this.locationCount = this.partnerlocations.length;
				for (var j = 0; j < this.lookups["REWARD_TYPE"].length; j++) {
					if (this.lookups["REWARD_TYPE"][j].code === this.offerobj.template_params.reward_type) {
						this.rewardType = this.lookups["REWARD_TYPE"][j].name; 
					}
				}

				/*for (let i = 0; i < this.offerobj.member_target_json.length; i++) {
					if (this.offerobj.member_target_json[i]['value'] !== undefined) {
						for (let k = 0; k < this.offerobj.member_target_json[i]['value'].length; k++) {
							let genderIndex = _.findIndex(this.lookups["GENDER"], {
								"code": this.offerobj.member_target_json[i].value[k]
							});
							if (genderIndex !== -1) {
								if (this.previewtargetObj.gender === '') {
									this.previewtargetObj.gender = this.lookups["GENDER"][genderIndex].name;
								} else {
									this.previewtargetObj.gender = this.previewtargetObj.gender + ", " + this.lookups["GENDER"][genderIndex].name;
								}
							}
							let tierIndex = _.findIndex(this.lookups["TIER_CLASS"], {
								"code": this.offerobj.member_target_json[i].value[k]
							});
							if (tierIndex !== -1) {
								if (this.previewtargetObj.tier_class === '') {
									this.previewtargetObj.tier_class = this.lookups["TIER_CLASS"][tierIndex].name;
								} else {
									this.previewtargetObj.tier_class = this.previewtargetObj.tier_class + ", " + this.lookups["TIER_CLASS"][tierIndex].name;
								}
							}
						}
					}
					if (this.offerobj.member_target_json[i].complex_clause_criteria !== undefined) {
						let ageGroupArray = [];
						if (this.offerobj.member_target_json[i].complex_clause_criteria.length === 2) {
							for (var m = 0; m < this.offerobj.member_target_json[i].complex_clause_criteria.length; m++) {
								if (this.offerobj.member_target_json[i].complex_clause_criteria[m + 1] !== undefined) {
									let ageIndex = _.indexOf(this.ageArray, (this.offerobj.member_target_json[i].complex_clause_criteria[m].value + '-' + this.offerobj.member_target_json[i].complex_clause_criteria[m + 1].value));
									if (ageIndex !== -1) {
										ageGroupArray.push(this.ageArray[ageIndex]);
									}
								}
							}
						} else {
							for (var n = 0; n < this.offerobj.member_target_json[i].complex_clause_criteria.length; n++) {
								for (var o = 0; o < this.offerobj.member_target_json[i].complex_clause_criteria[n].complex_clause_criteria.length; o++) {
									if (this.offerobj.member_target_json[i].complex_clause_criteria[n].complex_clause_criteria[o + 1] !== undefined) {
										let ageIndex = _.indexOf(this.ageArray, (this.offerobj.member_target_json[i].complex_clause_criteria[n].complex_clause_criteria[o].value + '-' + this.offerobj.member_target_json[i].complex_clause_criteria[n].complex_clause_criteria[o + 1].value));
										if (ageIndex !== -1) {
											ageGroupArray.push(this.ageArray[ageIndex]);
										}
									}
								}
							}
						}
						ageGroupArray = ageGroupArray.sort();
						this.previewtargetObj.age = ageGroupArray.join();
					}
				} */
				this.commonService.scrollTop();
				/*this.setAllDayTime();*/
				if (this.offerobj.template_params.reward_type === 'FIXED') {
					this.setMultiplierVal('FIXED', this.lookups["BONUS_REWARD_VALUE"]);
				} else {
					this.setMultiplierVal('MULTIPLIER', this.lookups["BONUS_REWARD_MULTIPLIER"]);
				}

			})
			.catch(error => {
				this.error = true;
				this.showErrorMsg(error);
			});
	}

	setMultiplierVal(val, data) {
		let value;
		if (val === 'FIXED') {
			value = this.offerobj.template_params.reward_value;
		} else {
			value = this.offerobj.template_params.reward_multiplier;
		}
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].code === value) {
					if (val === 'FIXED') {
						this.multiplierVal = '+' + data[i].name;
					} else {
						if (data[i].name === '3x') {
							this.multiplierVal = 'Triple';
						}
						if (data[i].name === '2x') {
							this.multiplierVal = 'Double';
						}
					}
				}
			}
		}
	}

	cancelCampaign(id, modaloffercancel) {
		let req = { "offer_id": id, "status": "terminated" };
		this.error = false;
		this.offercreationService
			.launchCampaign(req)
			.then((data: any) => {
				this.open(modaloffercancel, 'offerCreationModal');
				let self = this;
				setTimeout(function() { self.modalService.dismissAll(); 
					
				  if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
		            self.router.navigate(['/offers'], { queryParams: { partner: localStorage.getItem('partnerid') }});
		          } else {
		             self.router.navigate(['/offers']); 
		          }
		      }, 3000);
			})
			.catch(error => {
				this.showErrorMsg(error);
			});
	}

	gotoProfile() {
		if (localStorage.getItem('partnerid') !== undefined && localStorage.getItem('partnerid')  !== null && localStorage.getItem('partnerid') !== '') {
			this.router.navigate(['/profile'], { queryParams: { partner: localStorage.getItem('partnerid') }});
		} else{
			this.router.navigate(['/profile']);
		}
	}

	showErrorMsg(error) {
		this.error = true;
		this.errorMsg = this.commonService.showCommonErrorMsg(error);
	}

	open(content, modalclass) {
		this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalService.dismissAll();
	}

	getProgramData() {
		this.partnercreationService
			.getProgramData()
			.then((data: any) => {
				this.programtimezone = data[0].timezone;
				this.programCurrency = data[0].currency;
			})
			.catch(error => {
				//
			});

	}
}
