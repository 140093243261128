export class landingPageCMSModel {
  	mainTitle: string;
  	partnerTitle:string;
  	partnerDescription:string;
  	userTitle:string;
  	userDescription:string;
  	partnerLearMoreCTA:string;
  	userLearMoreCTA:string;
  	partnerSigninCTA:string;
  	googleStoreImg:string;
  	playStoreImg:string;
    backgroundImg: string;
}


export class entryIdObj {
  	entryIds = [
  		{ id:'1Z8OeWWHc6KdO3fPKLBM40', text : 'mainTitle'},
  		{ id:'1EH8ePiiCNwSNL1Y0T5XLs', text : 'partnerTitle'},
  		{ id:'1UcxPBnbNC3MOwDvlrU7rd', text : 'partnerDescription'},
  		{ id:'6I790dAokw8EHdyJrhqPqt', text : 'userTitle'},
  		{ id:'7IqCvi5abJ6rnnvXvQr92K', text : 'userDescription'},
  		{ id:'2xXtwFE77IxC7Brly1sNJc', text : 'partnerLearMoreCTA'},
  		{ id:'2WcR7JIZiSxdKQGKN8iwyt', text : 'userLearMoreCTA'},
      { id:'39nw21gMEen7hWJhmSXNrr', text : 'partnerSigninCTA'},
      { id:'5Zc0N0ftjV65dPOhvkvIiK', text : 'backgroundImg'}
  	]
}