export class profileCMSModel {
    myAccountTabHeader: string;
    appContentTabHeader: string;
    localManagementAndTransactionTabHeader : string;
    trainingTabHeader : string;
    marketingMaterialTabHeader : string;
    myAccountTabDescription : string;
    myAccountTabDescription1 : string;
    appContentTabDescription : string;
    appContentYourCompanyLogo : string;
    appContentYourCompanyLogoDescription : string;
    appContentYourCompanyLogoDescription1 : string;
    localManagementHeader : string;
    localManagementDescription : string;
    locationnewHeader: string;
    transactionSettingsHeader : string;
    transactionSettingsDescription : string;
    transactionSettingsDescription1 : string;
    transactionSettingsDescription2 : string;
    transactionSettingsDescription3 : string;
    transactionSettingsDescription4 : string;
    transactionSettingsDescription5 : string;
    transactionSettingsDescription6 : string;
    trainingTabDescription : string;
    marketingMaterialTabDescrption : string;
    kycDocumentsTabDescription:string;
    checkIdentifier: string;
}


export class profileEntryIdObj {
  	entryIds = [
      { id:'7hVThKlu4P3udyoEm8gurc', text : 'myAccountTabHeader'},
      { id:'5mgovjiD6x9vROAyLdlleU', text : 'appContentTabHeader'},
      { id:'1CJQ7PvPm0VVJxcM07CJCP', text : 'localManagementAndTransactionTabHeader'},
      { id:'63qQLBf3VDTG9E4VE4remR', text : 'trainingTabHeader'},
      { id:'11C3FlsAEgT8dwlmdMCEzl', text : 'marketingMaterialTabHeader'},
      { id:'7fPXeK9Lkg0BOcX9aCYh6P', text : 'myAccountTabDescription'},
      { id:'5v3b4t1ZvzDNqivX9gi9ni', text : 'myAccountTabDescription1'},
      { id:'Pv7FJVr9MsKPSZprFkMD2', text : 'appContentTabDescription'},
      { id:'6h6vWeBnUR69JvWRZgb13z', text : 'appContentYourCompanyLogo'},
      { id:'71MzAbgKnYglTBGbYYgTnJ', text : 'appContentYourCompanyLogoDescription'},
      { id:'5ew6uyTqmZIGXun5pvgACx', text : 'appContentYourCompanyLogoDescription1'},
      { id:'2hOfULHFx03PMcTddvCyjs', text : 'localManagementHeader'},
      { id:'3RWYc7T0SOswc44vZpDyy3', text : 'localManagementDescription'},
      { id: '3Rs9UqYVHnwZldA0NG9yGj', text: 'locationnewHeader'},
      { id:'1ZMKOpVmq2yaDK7dla6YZS', text : 'transactionSettingsHeader'},
      { id:'7CXPz0VcTREhZZIq86om1g', text : 'transactionSettingsDescription'},
      { id:'6HkRVlz7267EA96BQPUIvS', text : 'transactionSettingsDescription1'},
      { id:'5iYDQwHX8ac3g74nmNVpvR', text : 'transactionSettingsDescription3'},
      { id:'4fmMfzPgYuGJ8IIVX7wsZY', text : 'transactionSettingsDescription4'},
      { id:'5l0jv6D5B4DbAajQ7cT0MQ', text : 'transactionSettingsDescription5'},
      { id:'5PbVDhCC2C5WDasIqI3e0y', text : 'transactionSettingsDescription6'},
      { id:'598WfTC8ybmNHA7478p87N', text : 'trainingTabDescription'},
      { id:'64bD1zoQg0Ws8hfOues2nh', text : 'marketingMaterialTabDescrption'},
      { id: '4ioM5iQWY0CJe4iQBK4cDt', text : 'checkIdentifier'},
      { id:'2xAXdX8SRaDwMLnN2lwrJ7',text:'kycDocumentsTabDescription'}
  	];
}