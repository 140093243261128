import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, CommonService, PartnercreationService } from '../../services';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";


@Component({
	selector: 'app-pinconfirmationmodal',
	templateUrl: './pinconfirmationmodal.component.html',
	styleUrls: ['./pinconfirmationmodal.component.less']
})
export class PinconfirmationmodalComponent implements OnInit {
	@Input() locationObj: any;
	@Input() pinType: any;
	@Input() partnerobj: any;
	onclicklocationpin: boolean = false;
	onclicksponsorpin: boolean = false; 
	constructor(
		private modalService: NgbModal,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService
	) { }

	ngOnInit() {
	}

	close() {
		this.modalService.dismissAll();
	}

	resetPin() {
		this.partnercreationService
			.resetAuthPin(this.locationObj.sponsor, this.locationObj.id)
			.then((data: any) => {
				this.locationObj.pin = data.body.new_pin;
				this.onclicklocationpin = false;
				this.close();
			})
			.catch(error => {
				this.onclicklocationpin = false;
				console.log(error.error);
			});
	}

	supervisorPinReset() {
		let request = { "pin": this.partnerobj.pin };
		this.partnercreationService
			.supervisorPinReset(this.partnerobj, request)
			.then((data: any) => {
				this.partnerobj.pin = data.new_pin;
				this.onclicksponsorpin = false;
				this.close();
			})
			.catch(error => {
				this.onclicksponsorpin = false;
				console.log(error.error);
			});
	}
}
