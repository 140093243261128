import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnercreationService } from 'src/services';

@Component({
  selector: 'app-active-inactive-modal',
  templateUrl: './active-inactive-modal.component.html',
  styleUrls: ['./active-inactive-modal.component.less']
})
export class ActiveInactiveModalComponent implements OnInit {

  constructor(private modalService:NgbModal,private partnerCreationService: PartnercreationService) { }
  @Input() userId:any;
  userData:any;
  topErrorMessage:string = '';
  userName:string = '';
  userStatus:boolean = false;

  ngOnInit(): void {
    this.getSystemUserData();
    this.topErrorMessage= '';
  }

  closeModalWithMessage(taskDone:boolean) {
    this.topErrorMessage= '';
		this.modalService.dismissAll({taskDone:taskDone});
	}

  changeActivityOfUser(){
    this.topErrorMessage= '';
    if(this.userData && this.userData.user){
      this.userData.active = !this.userData.active;
      this.userData.user.is_active = !this.userData.user.is_active;
      this.partnerCreationService.markUserActiveOrInactive(this.userData,this.userId).then((data)=>{
        this.closeModalWithMessage(true);
      }).catch((error:any)=>{
        this.showErrorMessage(error);
      })
    }
    
  }

  getSystemUserData(){
    if(this.userId){
      this.partnerCreationService.getSystemUserDataUsingId(this.userId).then((data:any)=>{
        console.log(data);
        this.userData = data;
        this.userName= this.userData.user?.first_name + this.userData.user?.last_name;
        this.userStatus=  this.userData.active;
      })
    }
  }

  showErrorMessage(error){
    this.topErrorMessage = 'Some Error occurred';
    if(error.error &&  error?.error?.message){
      this.topErrorMessage=  error.error.message;
    }
  }

}
