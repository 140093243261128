import { Component, OnInit, ViewChild } from '@angular/core';
import { Partner, partner } from "../../models";
import {
    FormControl,
    FormGroup,
    FormBuilder,
    FormsModule,
    NgForm
} from "@angular/forms";
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import * as countryCodesData from '../../assets/static/countryCodes.json';
import { Router, ActivatedRoute } from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import { PartnerinformationComponent } from '../partnerinformation/partnerinformation.component';
import * as moment from 'moment';
import { stepThreeCMSModel, stepThreeEntryIdObj } from "../../models";
import * as _ from "lodash";

@Component({
    selector: 'app-verifyyourbusiness',
    templateUrl: './verifyyourbusiness.component.html',
    styleUrls: ['./verifyyourbusiness.component.less']
})

export class VerifyyourbusinessComponent implements OnInit {
    @ViewChild(HeaderComponent) header: HeaderComponent;
    step = 3;
    showaaditionalinfo = true;
    error = false;
    errorMsg: string;
    partnerobj: Partner = partner[0];
    partnerData: any;
    showaccdetails: boolean = true;
    partnerInformation: boolean = false;
    errorObj = {
        "error": false,
        "errorMsg": ''
    };
    loaderObj = {
        "trade_license": false,
        "photo_id": false
    };
    lookups: any = {};
    partnerId: any;
    documentsObj: any = {
        "documents": [],
        "license": [],
        "allDocuments": [],
        "filesize": 15728640
    };
    dateerror: boolean = false;
    stepThreeCMSData = new stepThreeCMSModel();
    stepThreeIdObj = new stepThreeEntryIdObj();
    locale: string = 'en-US';
    loader = false;
    billingConfig: any;
    tax_rate: string = "";
    previd: number = null;
    prevbutton: string = "";
    associatedemail: string = "";
    constructor(private commonService: CommonService,
        private router: Router,
        private partnercreationService: PartnercreationService,
        private authenticationService: AuthenticationService,
        private contentful: ContentfulService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
        this.commonService.scrollTop();
        this.commonService.getPartnerData(this.partnerId).subscribe(result => {
            this.getBillingConfig(this.partnerId);
            for (let i in result) {
                if (i !== 'extra_data' && ((!result[i] && !(result[i] instanceof Array)) || result[i] instanceof Array && result[i].length < 1)  && this.partnerobj[i]) {
                    result[i] = this.partnerobj[i];
                }
                if (i === 'extra_data') {
                    for (let j in result.extra_data) {
                        if (!result.extra_data[j] && this.partnerobj.extra_data[j]) {
                            result.extra_data[j] = this.partnerobj.extra_data[j];
                        }
                    }
                }
            }
            this.partnerobj = result;
            this.getDocuments();
            if (this.partnerobj.country !== '' && this.partnerobj.country !== null) {
                this.getValues(this.partnerobj.country, "state", "REGION_DATA");
            }
            if (this.partnerobj.region !== '' && this.partnerobj.region !== null) {
                this.getValues(this.partnerobj.region, "city", "CITY_DATA");
            }
            if (this.partnerobj.extra_data.country1 !== undefined && this.partnerobj.extra_data.country1 !== '' && this.partnerobj.extra_data.country1 !== null) {
                this.getValues(this.partnerobj.extra_data.country1, "state", "STATE_DATA");
            }
            if (this.partnerobj.extra_data.state1 !== undefined && this.partnerobj.extra_data.state1 !== '' && this.partnerobj.extra_data.state1 !== null) {
                this.getValues(this.partnerobj.extra_data.state1, "city", "CITY1_DATA");
            }
        });
        this.commonService.getData("lookups", "LEGAL_ENTITY_TYPE").subscribe(result => {
            this.lookups["LEGAL_ENTITY_TYPE"] = result;
        });
        this.commonService.getData("lookups", "BUSINESS_TYPE").subscribe(result => {
            this.lookups["BUSINESS_TYPE"] = result;
        });
        this.commonService.getData("country", "").subscribe(result => {
            this.lookups["COUNTRY_CODES"] = [];
            for (let k in result) {
                // if (result[k].id == 2) {
                    this.lookups["COUNTRY_CODES"].push(result[k]);
                // }
            }
        });
        this.getData();
    }
    addRemoveDocuments(e, fromLicense, fromUpload) {
        let tag;
        this.documentsObj["fromlicense"] = false;
        this.documentsObj["fromdocs"] = false;
        this.errorObj.error = false;
        let array = [];
        if (fromLicense) {
            this.documentsObj["fromlicense"] = true;
            array = this.documentsObj["license"];
            tag = "trade_license";
        } else {
            this.documentsObj["fromdocs"] = true;
            array = this.documentsObj["documents"];
            tag = "photo_id";
        }
        if (fromUpload) {
            this.loaderObj[tag] = true;
            this.previd = null;
            this.prevbutton = null;
            this.commonService.addDocument(this.partnerId, e, array, this.documentsObj.filesize, tag, this.errorObj, this.loaderObj);
        } else {
            if (this.previd !== array[e].id) {
                this.commonService.removeDocument(this.partnerId, e, array, this.errorObj, false);
                this.previd = array[e].id;
                    this.prevbutton = tag;
                }
        }
    }

    showAccountDetails() {
        this.commonService.scrollTop();
        // this.partnerobj = partner[0];
        this.commonService.assignLookUpValues(this.partnerData, this.partnerobj);
        this.getDocuments();
        this.showaccdetails = false;
    }

    onChangeToggle(enabled) {
        if (enabled) {
            this.lookups.STATE_DATA = this.lookups.REGION_DATA;
            this.lookups.CITY1_DATA = this.lookups.CITY_DATA;
            this.partnerobj.extra_data.country1 = this.partnerobj.country;
            this.partnerobj.extra_data.state1 = this.partnerobj.region;
            this.partnerobj.extra_data.city1 = this.partnerobj.city;
            this.partnerobj.extra_data.street_line_1 = this.partnerobj.extra_data.office_street_line_1;
            this.partnerobj.extra_data.street_line_2 = this.partnerobj.extra_data.office_street_line_2;
            this.partnerobj.extra_data.zip_code1 = this.partnerobj.pin_code;
        } else {
            this.partnerobj.extra_data.country1 = '';
            this.partnerobj.extra_data.state1 = '';
            this.partnerobj.extra_data.city1 = '';
            this.partnerobj.extra_data.street_line_1 = '';
            this.partnerobj.extra_data.street_line_2 = '';
            this.partnerobj.extra_data.zip_code1 = '';
        }
    }

    editDetails(id) {
        this.partnerobj = this.partnerData;
        this.partnerInformation = false;
        this.showaccdetails = true;
        setTimeout(function() {
            let position = document.getElementById(id).offsetTop;
            window.scrollTo(0, position);
        }, 50);
    }

    updatePartner(saveexit) {
        if (!saveexit) {
            this.loader = true;
        }
        this.error = false;
        this.errorMsg = "";
        if (this.partnerData.extra_data["finanace_number"] !== null && this.partnerData.extra_data["finanace_number"] !== "") {
            this.partnerData.extra_data["finanace_number"] = this.partnerobj.extra_data["financecode"] + this.partnerData.extra_data["finanace_number"];
        }
        /*if (this.partnerData.extra_data["bank_number"] !== null && this.partnerData.extra_data["bank_number"] !== "") {
            this.partnerData.extra_data["bank_number"] = this.partnerData.extra_data["bankcode"] + this.partnerData.extra_data["bank_number"];
        } */
        // this.partnerData["address"] = this.partnerData.extra_data.office_street_line_1 + this.partnerData.extra_data.office_street_line_2;
        this.partnerData.industry = null;
        this.partnerobj.extra_data.onboarding_step_completed = 3;
        this.partnerData.extra_data.onboarding_step_completed = 3;
        delete this.partnerData.extra_data["financecode"];
       /* delete this.partnerData.extra_data["bankcode"]; */
        delete this.partnerData.logo;
        delete this.partnerData.header;
        delete this.partnerData.description;
        this.partnercreationService
            .updatePartnerData(this.partnerId, this.partnerData)
            .then((data: any) => {
                this.loader = false;
                if (saveexit) {
                    this.authenticationService.logout();
                } else {
                    this.router.navigate(['/partnerinformation']);
                }
            })
            .catch(error => {
                this.loader = false;
                this.showErrorMsg(error);
            });
    }
    showErrorMsg(error) {
        this.error = true;
        this.commonService.scrollTop();
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
    }

    getDocuments() {
        this.partnercreationService
            .getPartnerDocuments(this.partnerId)
            .then((data: any) => {
                this.documentsObj.allDocuments = data.documents;
                this.documentsObj.documents = [];
                this.documentsObj.license = [];
                if (data.documents.length > 0) {
                    for (let i = 0; i < data.documents.length; i++) {
                        switch (data.documents[i].tag) {
                            case 'photo_id': {
                                this.documentsObj.documents.push(data.documents[i]);
                                break;
                            }
                            case 'trade_license': {
                                this.documentsObj.license.push(data.documents[i]);
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                }
            })
            .catch(error => {
                this.showErrorMsg(error);
            });
    }

    assignValues(saveexit) {
        this.partnerData = cloneDeep(this.partnerobj);
        if (saveexit) {
            this.updatePartner(true);
        } else {
            this.showAccountDetails();
        }
    }

    getValues(val, type, lookuptype) {
        this.commonService.getData(type, val).subscribe(result => {
            this.lookups[lookuptype] = result;
        })
    }

    gotoCustomizePage() {
        partner[0] = cloneDeep(this.partnerData);
        this.router.navigate(['/customizeyourpackage']);
    }

    checkDate() {
        if (this.partnerobj.extra_data.date_of_establishment) {
            let date = this.partnerobj.extra_data.date_of_establishment.split('/');
            date = date[2] + '-' + (date[1].length > 1 ? date[1] : '0' + date[1]) + '-' + (date[0].length > 1 ? date[0] : '0' + date[0]);
            if (date > moment(new Date()).format('YYYY-MM-DD')) {
                this.dateerror = true;
            } else {
                this.dateerror = false;
            }
        }
    }

    getData() {
        let contentFulIds = this.stepThreeIdObj.entryIds;
        this.contentful.getEntries(this.locale).subscribe(dataobj => {
            this.header.getData(dataobj);
            for (let entry of contentFulIds) {
                let Index = _.findIndex(dataobj.items, {
                    "sys": {
                        "id": entry.id
                    }
                });
                if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
                    this.stepThreeCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
                }

            }
        });

    }
    checkValues(val, type, lookuptype) {
        switch (lookuptype) {
            case "REGION_DATA": {
                this.partnerobj.region = '';
                this.partnerobj.city = '';
                this.lookups['REGION_DATA'] = [];
                this.lookups['CITY_DATA'] = [];
                break;
            }
            case "CITY_DATA": {
                this.partnerobj.city = '';
                this.lookups['CITY_DATA'] = [];
                break;
            }
            case "STATE_DATA": {
                this.partnerobj.extra_data.state1 = '';
                this.partnerobj.extra_data.city1 = '';
                this.lookups['STATE_DATA'] = [];
                this.lookups['CITY1_DATA'] = [];
                break;
            }
            case "CITY1_DATA": {
                this.partnerobj.extra_data.city1 = '';
                this.lookups['CITY1_DATA'] = [];
                break;
            }

            default:
                break;
        }
        if (val) {
            this.getValues(val, type, lookuptype);
        }
    }

    showPreview(doc) {
        this.commonService.showPreview(doc);
    }

    getBillingConfig(partnerId) {
        this.partnercreationService
            .getBillingConfig(partnerId)
            .then((data: any) => {
                this.billingConfig = data[0];
                this.billingConfig['rate'] = '0.071';
                this.getTaxes();
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });

    }

    getTaxes() {
        this.partnercreationService
            .getTaxes()
            .then((data: any) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].id === this.billingConfig.tax_rate) {
                        this.tax_rate = data[i].name
                    }
                }
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });
    }

    checkNumber(event) {
        return this.commonService.checkNumber(event);
    }

    addEmails() {
        if(this.associatedemail){
            this.partnerobj.additional_emails.push(this.associatedemail);
            this.associatedemail = "";
        }
    }

    removeEmail(index) {
        this.partnerobj.additional_emails.splice(index,1);
    }
}