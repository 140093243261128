import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../services';

@Component({
	selector: 'app-contentsuccessmodal',
	templateUrl: './contentsuccessmodal.component.html',
	styleUrls: ['./contentsuccessmodal.component.less']
})
export class ContentsuccessmodalComponent implements OnInit {
	headerImage: any;
	locale: string = 'en-US';
	constructor(private contentful: ContentfulService) { }

	ngOnInit() {
		this.getData();
	}

	getData() {
		this.contentful.getContent('3iSoaqM6TOg2Xtgpy033a9', this.locale).subscribe(dataobj => {
			this.headerImage = dataobj['imageContent'].fields.file.url;
		});
	}
}
