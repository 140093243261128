export class stepTwoCMSModel {
    taxesTitle: string;
    taxesDescription: string;
    taxesDescription1: string;
    savesignout: string;
    savenext: string;
    kycmsgdesc: string;
    kycmsgtitle: string;
    kycssavenext: string;
    uploaddocument: string;
    commercecertificate: string;
    cashflowtitle: string;
    balsheetstitle: string;
    financialdocdesc: string;
    financialdoctitle: string;
    addbusinessdesc: string;
    addbusinesstitle: string;
    kycdescription2: string;
    kycdescription1: string;
    kyctitle: string;
    monthlygoingsdesc1: string;
    monthlycostofmiles: string;
    monthlymilesawarded: string;
    inputavgsales: string;
    monthlyoutgoings: string;
    outgoingsdesc: string;
    ratepermiletitle: string;
    ratepermiledesc1: string;
    ratepermiledesc2: string;
    ratepermiledesc3: string;
    rewardmemtitle: string;
    rewardmemdesc: string;
    commercialstitle: string;
    commercialsdesc1: string;
    commercialsdesc2: string;
    keycontract: string;
    kycsaveandsignout: string;
}


export class stepTwoEntryIdObj {
  	entryIds = [
      { id:'5CNzm6Y1rwZzHe38mLSxRU', text : 'taxesTitle'},
      { id:'6iCtYmns6r5kfjNPb9VVXa', text : 'taxesDescription'},
      { id:'SbN1RIhnLCsXqcGoklxi5', text : 'taxesDescription1'},
      { id:'3MzGK9WJw3Ipm1cgDPEqDo', text : 'savesignout'},
      { id:'5M34eeuZW7ddSCrI3FWxwM', text : 'savenext'},
      { id:'4UyEGpBjyD5FNmXAmVjdxy', text : 'kycmsgdesc'},
      { id:'3Mx0IDPlo5hQDLM3eG3IgS', text : 'kycmsgtitle'},
      { id:'3LAL7aePApVoaaJARjZeuO', text : 'kycssavenext'},
      { id:'7gU3llfrLoiSPjRWVqEhfN', text : 'uploaddocument'},
      { id:'4qb7KwTlZjeOry21Q1kL1I', text : 'commercecertificate'},
      { id:'5NEYp2RoHyzr3cMHORpGvn', text : 'cashflowtitle'},
      { id:'34aOGoIPgUKiuzGE7oQutn', text : 'balsheetstitle'},
      { id:'36FI9CtMUs3OmuZ3nBxFFm', text : 'financialdocdesc'},
      { id:'5ptEEkJlc2iFFmsN7df2c4', text : 'financialdoctitle'},
      { id:'14AOnjt54VFzWH7J07pmhD', text : 'addbusinessdesc'},
      { id:'6cvGZTe4Lq2avvB5mClaqV', text : 'addbusinesstitle'},
      { id:'1UoJuXe0sCfKpJwa52PPbs', text : 'kycdescription2'},
      { id:'49CHUDNT5Q66dNz7JFgm46', text : 'kycdescription1'},
      { id:'24Xr7kJpmajX7rqGHPZqAY', text : 'kyctitle'},
      { id:'1wfaDEn8bUGLF8kUlrvSZx', text : 'monthlygoingsdesc1'},
      { id:'2MrW2mTUqgRVALFIKkpntK', text : 'monthlycostofmiles'},
      { id:'3MpiwKBoaWZaZsbN9BDNFG', text : 'monthlymilesawarded'},
      { id:'15vDQa2iUxufk6ykM6MlpY', text : 'inputavgsales'},
      { id:'4YLy9raidrFGvXS78L0Flt', text : 'monthlyoutgoings'},
      { id:'HZj7Itydf7T6npxZQfT2v', text : 'outgoingsdesc'},
      { id:'4PYVJqVCBF27332qIjZl9s', text : 'ratepermiletitle'},
      { id:'6qM67Kat9UkoRf98K2PpqA', text : 'ratepermiledesc1'},
      { id:'70V03m1NqP8usy9HYZps2Q', text : 'ratepermiledesc2'},
      { id:'7KB5Mnhbr3VTPQAT8ZozlC', text : 'ratepermiledesc3'},
      { id:'1TeQGRt8F6YgN5KDGzFBSr', text : 'rewardmemtitle'},
      { id:'5SdohiuNGsDQTVUVoKvlSW', text : 'rewardmemdesc'},
      { id:'3jz7yBzk9MPaYspPlOxpih', text : 'commercialstitle'},
      { id:'tf3EQ44SCb27KtFR7fWRS', text : 'commercialsdesc1'},
      { id:'5MhsxPEJeySQb9PMeiG0x1', text : 'commercialsdesc2'},
      { id:'2oVE4vWt8YYVGQDK0aBlY7', text : 'keycontract'},
      { id:'4JDljfGry2TNsIXgxWBlDa', text : 'kycsaveandsignout'}
  	];
}