import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, ContentfulService } from '../../services';
import { contactUsCMSModel, contactUsEntryIdObj } from "../../models";

@Component({
	selector: 'app-contactus',
	templateUrl: './contactus.component.html',
	styleUrls: ['./contactus.component.less']
})
export class ContactusComponent implements OnInit {
	currentUser: any;
	partnerobj: any;
	locale: string = 'en-US';
	headerImage: any;
	contactUsCMSData: any = {};
	contactUsIdObj = new contactUsEntryIdObj();
	isstrategicpartner = false;
	strategicdata = [];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.strategicdata = JSON.parse(localStorage.getItem('parentSponsor'));
		if(this.strategicdata){
			this.isstrategicpartner = true;
		}
		this.commonService.scrollTop();
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if(this.currentUser) {
			this.commonService.getPartnerData(this.currentUser.sponsors[0].id).subscribe(data => {
			this.partnerobj = data;
			});
		}
		this.getData();
		
	}

	gotoPage(url) {
		this.router.navigate([url]);
	}
	gotoMargetingPage() {
		if (this.currentUser && (this.partnerobj.status === 'S' || this.partnerobj.status === 'T' || this.partnerobj.status === 'A') && this.isstrategicpartner === false) {
			this.router.navigate(['/profile']);
		} else if(this.isstrategicpartner){
			this.router.navigate(['/strategicpartners']);
		} else {
			this.router.navigate(['/skywardseveryday']);

		}

	}

	getData() {
		let contentFulIds = this.contactUsIdObj.entryIds;
		for (let entry of contentFulIds) {
			this.contentful.getContent(entry.id, this.locale).subscribe(dataobj => {
				this.contactUsCMSData = dataobj;
			});
		}
		this.contentful.getContent('1n4rpFEEHb5Ufsf31FyWuF', this.locale).subscribe(dataobj => {
			this.headerImage = dataobj['imageContent'].fields.file.url;
		});
	}
}
