import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/services';
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';

@Injectable({
  providedIn: 'root'
})
export class RolebasedaccessGuard implements CanActivate {

  
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private rolebasedrulesService:RolebasedrulesService
  ) {
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUserRoleCode = this.authenticationService?.currentUserValue?.role?.code;
    let routePath = route.routeConfig.path;
    let isRouteAllowed = this.rolebasedrulesService.checkIfPathAllowed(currentUserRoleCode,routePath);
    if(isRouteAllowed){
      return true;
    }

    // screen not allowed so redirecting to dashboard
    this.router.navigate(['dashboard']);
    return false;
  }
  
}
