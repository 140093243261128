import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, ContentfulService } from '../../services';
import { tncCMSModel, tncEntryIdObj } from "../../models";

@Component({
	selector: 'app-termsnconditions',
	templateUrl: './termsnconditions.component.html',
	styleUrls: ['./termsnconditions.component.less']
})
export class TermsnconditionsComponent implements OnInit {
	showsignin: boolean = false;
	locale: string = 'en-US';
	tncCMSData: any = {};
	tncIdObj = new tncEntryIdObj();
	headerImg: any;
	currentUser: any;
	partnerobj: any;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.getData();
	}

	gotoBackPage() {
		if (this.currentUser && (this.partnerobj.status === 'S' || this.partnerobj.status === 'T' || this.partnerobj.status === 'A')) {
			this.router.navigate(['/profile']);
		} else {
			this.router.navigate(['/skywardseveryday']);
		}
	}

	gotoPage(url) {
		this.router.navigate([url]);
	}

	getData() {
		let contentFulIds = this.tncIdObj.entryIds;
		for (let entry of contentFulIds) {
			this.contentful.getContent(entry.id, this.locale).subscribe(dataobj => {
				this.tncCMSData = dataobj;
			});
		}
		this.contentful.getContent('5Kc6ZxS4poK28gZoNTOslh', this.locale).subscribe(dataobj => {
			this.headerImg = dataobj['imageContent'].fields.file.url;
		});

	}

}
