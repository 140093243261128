import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Partner, partner, marker } from "../../models";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-welcomepage',
  templateUrl: './welcomepage.component.html',
  styleUrls: ['./welcomepage.component.less', '../header/header.component.less']
})
export class WelcomepageComponent implements OnInit {
  partnerobj: Partner = partner[0];
  error: boolean = false;
  errorMsg: string = '';
  partnerlocations: any = [];
  nextcursor: string = '';
  prevcursor: string = '';
  billingConfig: any = {
      'payment_plan': '',
      'rate': null
  };
  noPayments: boolean = true;
  headerImage: any;
  locale: string = 'en-US';
  constructor(
    private partnercreationService: PartnercreationService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private contentful: ContentfulService
  ) { }

  ngOnInit() {
    let partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
    this.commonService.scrollTop();
    this.getBillingConfig(partnerId);
    this.commonService.getPartnerData(partnerId).subscribe(data => {
      this.partnerobj = data;
      this.getPartnerLocations('');
    });
    this.getData();
  }
  getPartnerLocations(cursor) {
    if (cursor) {
      cursor = cursor.split("cursor=")[1];
    }
    this.partnercreationService
      .getPartnerLocations(this.partnerobj.id)
      .then((data: any) => {
        this.partnerlocations = data.body;
        this.prevcursor = data.headers.get('prev');
        this.nextcursor = data.headers.get('next');
      })
      .catch(error => {
        console.log(error.error);
      });
  }

  resetPin(location) {
    this.partnercreationService
      .resetAuthPin(location.sponsor, location.id)
      .then((data: any) => {
        location.pin = data.body.new_pin;
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  goLive() {
    let request = {
      "event": "go_live_button_is_clicked"
    };
    this.partnercreationService
      .goLive(request, this.partnerobj.id)
      .then((data: any) => {
        this.router.navigate(['/dashboard']);
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  partnerLogout() {
    this.authenticationService.logout();
  }

  gotoMargetingPage() {
    this.router.navigate(['/skywardseveryday']);
  }

  getBillingConfig(partnerId) {
    this.partnercreationService
      .getBillingConfig(partnerId)
      .then((data: any) => {
        this.billingConfig = data[0];
        this.billingConfig['rate'] = 0.071;
        if (this.billingConfig.payment_plan === 'prepaid') {
          this.getPayments(partnerId);
        }
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });

  }

  getPayments(partnerId) {
    this.partnercreationService
      .getPayments('', partnerId)
      .then((data: any) => {
        if (data.body.length > 0) {
          this.noPayments = false;
        } else {
          this.noPayments = true;
        }
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  getData() {
    this.contentful.getContent('3VDF0hzezUB232Sq1mYlNW', this.locale).subscribe(dataobj => {
      this.headerImage = dataobj['imageContent'].fields.file.url;
    });
  }

}
