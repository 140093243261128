import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { WebService, PartnercreationService, ContentfulService, AuthenticationService, CommonService, StrategicparternsService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})
export class SigninComponent implements OnInit {
  error; forgoterror; loginerror; showForgotForm; showUsernameForm: boolean = false;
  showSignInForm = true;
  errorMsg = "";
  signobj = {
    "username": "",
    "password": ""
  };
  forgotobj = {
    "username": "",
    "email": ""
  };
  forgotusernameobj = {
    "tradelicenced": "",
    "email": "",
    "brandname": ""
  };
  billingConfig: any;
  signinerror: boolean = false;
  locale: string = "en-US";
  headerImg: any;
  UserRestrictionData: any;
  isparent: boolean = false;
  parentSponsor: string;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private partnercreationService: PartnercreationService,
    private appComponent: AppComponent,
    private commonService: CommonService,
    private contentful: ContentfulService,
    private strategicparternsService: StrategicparternsService,
    private rolebasedrulesService : RolebasedrulesService
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.getData();
    this.getUserRestrictionData();
  }

  partnerLogin(sigininForm) {
    var request: any = {
      "username": this.signobj.username,
      "password": this.signobj.password
    };
    this.error = false;
    this.loginerror = false;
    this.errorMsg = "";
    for(var k=0; k<this.UserRestrictionData.length; k++){
      if(this.UserRestrictionData[k] === request.username){
        this.loginerror = true;
      }
    }

    if (sigininForm && this.loginerror === false) {
      this.authenticationService.login(request)
        .subscribe(
          data => {
            this.parentSponsor = data.sponsors[0].id;
            this.appComponent.setExpirationTime(data.expires_in);
            if(data.sponsors !== undefined && data.sponsors !== null){
              for(var spon=0; data.sponsors.length > spon; spon++){
                if(data.sponsors[spon].hierarchy_designation === "Parent"){
                  this.parentSponsor =  data.sponsors[spon].id;
                  localStorage.setItem('parentSponsor', JSON.stringify(data.sponsors[spon]));
                  this.strategicparternsService.setParentSponsor(data.sponsors[spon]);
                  this.isparent = true;
                  break;
                }
              }
            }
            let isProfileSuperUser = this.rolebasedrulesService.checkIfUserHasSuperUserRole(data.role.code);
            if (isProfileSuperUser) {
              if (this.isparent) {
                this.getChilds(this.parentSponsor);
              }
              else {
                this.appComponent.getPartnerData(this.parentSponsor, false);
              }
            }
            else{
              this.router.navigate(['/dashboard']);
            }
            
            localStorage.setItem('status','loggedin');
          },
          error => {
            if (error.error.error.code === 'invalid_credentials') {
              this.loginerror = true;
            } else {
              this.error = true;
            }
            this.errorMsg = this.commonService.showCommonErrorMsg(error);
          });
    }
  }

  getChilds(id){
    this.strategicparternsService
      .StrategicPartners(id)
      .then((data: any ) => {
          this.strategicparternsService.setChildSponsors(data);
        //  sessionStorage.setItem('partners', JSON.stringify(data));
          this.appComponent.getPartnerData(id, false);
          //this.isparent = false;
          setTimeout(function() {
            this.isparent = false;
          }, 120000)
      });
  }

  resetPassword(forgotpasswordform) {
    var request: any = {
      "email": this.forgotobj.email,
      "username": this.forgotobj.username
    };
    this.forgoterror = false;
    this.errorMsg = "";
    if (forgotpasswordform) {
      this.partnercreationService
        .resetPassword(request)
        .then((data: any) => {
          this.showSignInForm = false;
          this.showForgotForm = true;
          this.showUsernameForm = false;
        })
        .catch(error => {
          this.forgoterror = true;
          this.errorMsg = this.commonService.showCommonErrorMsg(error);
        });
    }
  }

  showForgotPassword() {
    this.error = false;
    this.loginerror = false;
    this.errorMsg = '';
    this.showSignInForm = false;
  }

  hideForgotPassword() {
    this.forgoterror = false;
    this.errorMsg = '';
    this.showSignInForm = true;
    this.showForgotForm = false;
    this.showUsernameForm = false;
    this.signinerror = false;
    this.loginerror = false;
  }

  gotoPage(url) {
    this.router.navigate([url]);
  }

  retrieveUsername(forgotusernameform) {
    if (forgotusernameform) {
      this.partnercreationService
        .retrieveUsername(this.forgotusernameobj.tradelicenced, this.forgotusernameobj.email, this.forgotusernameobj.brandname)
        .then((data: any) => {
          this.showSignInForm = false;
          this.showForgotForm = false;
          this.showUsernameForm = true;
          this.forgoterror = false;
        })
        .catch(error => {
          this.forgoterror = true;
          this.errorMsg = this.commonService.showCommonErrorMsg(error);
        });
    }
  }

  getData() {
    this.contentful.getContent('5bKog8B7Apaixd1GCpKc2r', this.locale).subscribe(dataobj => {
      this.headerImg = dataobj['imageContent'].fields.file.url;
    });
  }

  getUserRestrictionData(){
    this.contentful.getContent('5yrqtSE2AT1xcRBwHVkjOJ', this.locale).subscribe(dataobj => {
      this.UserRestrictionData = dataobj['stringContent'].split(', ');
    });
  }

}
