export class stepFourCMSModel {
    stepFourHeader: string;
    stepFourDescription1: string;
    stepFourDescription2: string;
    ContractSignedHeader: string;
    ContractSignedDescription: string;
    saveandsignout: string;
    saveandnext: string;
    signatoryHeader: string;
    signatoryDescription: string;
    showContractButtton: string;
    merchantPortalCopy: string;
}


export class stepFourEntryIdObj {
  	entryIds = [
      { id:'3kzdu7hBKBh6GdcPMLoHXN', text : 'stepFourHeader'},
      { id:'3f9elS4VVq1fXicPZtNrgM', text : 'stepFourDescription1'},
      { id:'6fesENnmlZmOe0b5IZ4tF2', text : 'stepFourDescription2'},
      { id:'516TFLY28wyldK6Rm2XeBI', text : 'ContractSignedHeader'},
      { id:'3p5NpjmgNLbIEdmTRbnBAd', text : 'ContractSignedDescription'},
      { id:'5jZJ7sffO9iut4BAH9UkeI', text : 'saveandsignout'},
      { id:'3nJQqjjBSWDoGqwQDgbiQV', text : 'saveandnext'},
      { id:'1jtQLutYhiGQaVOdUMeTzU', text : 'signatoryHeader'},
      { id:'6X0UwUaMq5Gjx68VZEnv0F', text : 'signatoryDescription'},
      { id:'482G4w7il9zSsUSS6R7bzy', text : 'showContractButtton'},
      { id:'36TiANWAXtbaIDSAUEFr3f', text : 'merchantPortalCopy'}
  	];
}