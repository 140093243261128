import { Component, OnInit } from '@angular/core';
import { AuthenticationService, PartnercreationService, CommonService} from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.less']
})
export class ChangepasswordComponent implements OnInit {
  error: boolean = false;
  partnerobj:any;
  errorMsg : string = "";
  changepasswordobj = {
    "current_password": "",
    "password": ""
  };
  passwordcompare: boolean = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private partnercreationService: PartnercreationService,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) { }

  ngOnInit() {
  }

  changePassword(resetpasswordform) {
    this.error = false;
    this.errorMsg = "";
    if (resetpasswordform && !this.passwordcompare) {
      this.partnercreationService
        .changePassword(this.changepasswordobj)
        .then((data: any) => {
          this.router.navigate(['/signin']);
        })
        .catch(error => {
          if(error.error.code==="expired_code"){
            this.router.navigate(['/linkexpired']);
          }else{
            this.error = true;
            this.errorMsg = this.commonService.showCommonErrorMsg(error);
          }
          
        });
    }
  }

  // gotoPage(page) {
  //   this.router.navigate([page]);
  // }

  comparePassword(password, confirmpassword) {
    if (password !== confirmpassword) {
      this.passwordcompare = true;
    } else {
      this.passwordcompare = false;
    }
  }

}
