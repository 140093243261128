import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { } from 'googlemaps';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { marker, Locationreq, locationmapreq } from "../../models";
import { AuthenticationService, CommonService, PartnercreationService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-locationsmodal',
	templateUrl: './locationsmodal.component.html',
	styleUrls: ['./locationsmodal.component.less']
})
export class LocationsmodalComponent implements OnInit {
	@Output('getPartnerLocations') partnerLocations = new EventEmitter<any>();
	@Input() partnerobj: any;
	@Input() index: any;
	@Input() markers: marker[] = [];
	@Input() locationObj: any;
	@Input() partnerlocations: any;
	selectedlocindex: any;
	updatelocation: boolean = false;
	showAddressBar: boolean = false;
	marker: marker[] = [];
	zoom: number = 15;
	lat: number = 51.673858;
	lng: number = 7.815982;
	geocoder: any = '';
	address: string = '';
	weekarray = {};
	locations: any = [];
	count: number = 0;
	error: boolean = false;
	errorMsg: string = "";
	location_name: string;
	Locatreq: Locationreq = locationmapreq;
	visa_acquirer_id: string= '';
	master_acquirer_id:string = '';
	visa_bin: string = '';
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private translate: TranslateService,
		private partnercreationService: PartnercreationService,
		private modalService: NgbModal,
	) { }

	ngOnInit() {
		if (this.index !== null && this.index !== '' && this.index !== undefined) {
			this.marker[0] = this.markers[this.index];
		} else {
			let data = {
				'latitude': 25.2048,
				'longitude': 55.2708,
				'label': '',
				'draggable': false,
				'location_code': '',
				'address': '',
				'location_name': '',
				'open': false,
				'location_timings': [],
				'active': true,
				'sponsor': this.partnerobj.id,
				'location_type': 'STR',
				'location_category':'OFFLINE',
				'area': '',
			    'city': '',
			    'country': '',
			    'state': '',
			    'card_setup_data': [],
			    'visa_acquirer_id': '',
			    'master_acquirer_id': '',
			    'master_card_id': null,
			    'visa_card_id': null,
			    'bin_id': '',
			    'network': ''
			};
			this.marker.push(data);
			this.locationObj = null;
		}
		setTimeout(() => {
			this.showManualLocationAdd(this.index);
		}, 1000);
	}

	showManualLocationAdd(index) {
		if (index !== null && index !== '' && index !== undefined) {
			this.selectedlocindex = index;
			this.address = this.marker[0].address;
			this.location_name = this.marker[0].location_name;
			this.visa_acquirer_id= this.marker[0].visa_acquirer_id;
			this.master_acquirer_id = this.marker[0].master_acquirer_id;
			this.visa_bin = this.marker[0].bin_id;
			this.placeMarker('', false, false);

		} else {
			this.address = '';
			this.location_name = '';
			this.lat = 25.2048;
			this.lng = 55.2708;
			for (let j = 1; j < 8; j++) {
				this.marker[0].location_timings.push({
					'open_time': '',
					'close_time': '',
					'day': j,
					'active': true
				});
			}
		}
		let scope = this;
		setTimeout(function() {
			scope.showAddressBar = true;
		}, 100);

	}

	placeMarker(event, fromUpdate, fromadd) {
		if (fromUpdate) {
			if(fromadd){
				this.marker[0].longitude = JSON.parse(event.geometry.location.lng().toFixed(7));
				this.marker[0].latitude = JSON.parse(event.geometry.location.lat().toFixed(7));
			} else{
				this.marker[0].latitude = JSON.parse(event.coords.lat.toFixed(7));
				this.marker[0].longitude = JSON.parse(event.coords.lng.toFixed(7));
			}
			if (this.selectedlocindex > -1) {
				this.lat = this.markers[this.selectedlocindex].latitude;
				this.lng = this.markers[this.selectedlocindex].longitude;
			}
			this.getAddress(this.marker[0].latitude, this.marker[0].longitude);
		} else {
			this.lat = this.markers[this.selectedlocindex].latitude;
			this.lng = this.markers[this.selectedlocindex].longitude;

		}
	}

	getAddress(lat, lng) {
		let scope = this;
		this.geocoder = new google.maps.Geocoder();
		let latlng = new google.maps.LatLng(lat, lng);
		scope.Locatreq = {   
						"area_name": '',
                        "city_name": '',
                        "country_name": '',
                        "region_name": '' 
                    };
		this.geocoder.geocode({
			'latLng': latlng
		}, function(results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				if (results[1]) {
					for(var locres=0; locres<results[1].address_components.length; locres++){
			            let addtype = results[1].address_components[locres].types[0];
			            if(addtype === 'country') {
			                scope.Locatreq["country_name"] = results[1].address_components[locres]['long_name'];
			            }
			            if(addtype === 'locality') {
			                scope.Locatreq["city_name"] = results[1].address_components[locres]['long_name'];
			            }
			            if(addtype === 'administrative_area_level_1') {
			                scope.Locatreq["region_name"] = results[1].address_components[locres]['long_name'];
			            }
			            if(addtype === 'sublocality_level_1' || results[1].address_components[locres].types[1] === 'sublocality_level_1' || results[1].address_components[locres].types[2] === 'sublocality_level_1') {
			                scope.Locatreq["area_name"] = results[1].address_components[locres]['long_name'];
			            }
			        }
					scope.address = results[1].formatted_address;
					if(scope.Locatreq["area_name"] === '' || scope.Locatreq["area_name"] === undefined){
						scope.Locatreq["area_name"] = scope.Locatreq["city_name"];
					}
					scope.marker[0].address = scope.address;
					scope.marker[0].location_name = scope.address;
					scope.location_name = scope.getAddressArray(scope.address);
					scope.getOpeningHours(results[1], scope.marker[0].latitude, scope.marker[0].longitude);
				}
			}
		});
	}

	setAddress(address) {
		this.marker[0].longitude = JSON.parse(address.geometry.location.lng().toFixed(7));
		this.marker[0].latitude = JSON.parse(address.geometry.location.lat().toFixed(7));
		this.marker[0].address = address.formatted_address;
		this.address = this.marker[0].address;
		this.marker[0].location_name = this.address;
		this.location_name = this.getAddressArray(this.address);
		this.getAddress(this.marker[0].longitude, this.marker[0].latitude);
		//this.getOpeningHours(address, this.marker[0].latitude, this.marker[0].longitude);
	}

	checkTimings(weekday, marker) {
		if (this.weekarray[weekday] && this.weekarray[weekday].length > 0) {
			let length = this.weekarray[weekday].length;
			if (this.weekarray[weekday][0].open && this.weekarray[weekday][0].open.minutes === 0) {
				this.weekarray[weekday][0].open.minutes = '00';
			}
			if (this.weekarray[weekday][0].open && this.weekarray[weekday][0].open.hours < 10 && this.weekarray[weekday][0].open.hours.toString().length === 1) {
				this.weekarray[weekday][0].open.hours = '0' + this.weekarray[weekday][0].open.hours;
			}
			if (this.weekarray[weekday][0].close && this.weekarray[weekday][0].close.minutes === 0) {
				this.weekarray[weekday][0].close.minutes = '00';
			}
			if (this.weekarray[weekday][0].close && this.weekarray[weekday][0].close.hours < 10 && this.weekarray[weekday][0].close.hours.toString().length === 1) {
				this.weekarray[weekday][0].close.hours = '0' + this.weekarray[weekday][0].close.hours;
			}
			marker.location_timings.push({
				'open_time': this.weekarray[weekday][0].open ? this.weekarray[weekday][0].open.hours + ":" + this.weekarray[weekday][0].open.minutes : '00:00',
				'close_time': this.weekarray[weekday][length - 1].close ? this.weekarray[weekday][length - 1].close.hours + ":" + this.weekarray[weekday][length - 1].close.minutes : '23:59',
				'day': this.weekarray[weekday][0].open.day !== 0 ? this.weekarray[weekday][0].open.day : 7,
				'active': true
			});
		} else {
			marker.location_timings.push({
				'open_time': '00:00',
				'close_time': '23:59',
				'day': weekday !== 0 ? weekday : 7,
				'active': true
			});
		}
	}

	checkOpeningHours(location) {
		if(this.Locatreq.area_name !== '' && this.Locatreq.city_name !== '' && this.Locatreq.country_name !== '' && this.Locatreq.region_name !== ''){
			this.partnercreationService
		      .addlocationsreqs(this.Locatreq)
		      .then((data: any) => {
		        location.address = this.address;
				location.location_name = this.location_name;

				let selectedloc = cloneDeep(location);
				delete selectedloc.location_timings;
				selectedloc.location_timings = [];
				selectedloc.area = data.id;
				selectedloc.city = data.city;
				selectedloc.state = data.region;
				selectedloc.country = data.country;
				if (location.location_timings.length > 0) {
					for (let i = 0; i < location.location_timings.length; i++) {
						if (location.location_timings[i].active && location.location_timings[i].close_time !== '' && location.location_timings[i].open_time !== '') {
							delete location.location_timings[i].active;
							selectedloc.location_timings.push(location.location_timings[i]);
						}

					}
				}
				this.editLocation(selectedloc);
		      }).catch(error => {
		         // this.showErrorMsg(error);
		        });
		} else {
			location.address = this.address;
			location.location_name = this.location_name;
			let selectedloc = cloneDeep(location);
			delete selectedloc.location_timings;
			selectedloc.location_timings = [];
			if (location.location_timings.length > 0) {
				for (let i = 0; i < location.location_timings.length; i++) {
					if (location.location_timings[i].active && location.location_timings[i].close_time !== '' && location.location_timings[i].open_time !== '') {
						delete location.location_timings[i].active;
						selectedloc.location_timings.push(location.location_timings[i]);
					}

				}
			}
			this.editLocation(selectedloc);
		}
	}

	getAddressArray(address): any {
		let locationArray = [];
		let address_array = address.split(',');
		for (var i = 0; i < address_array.length; i++) {
			if (i === 3) {
				break;
			}
			locationArray.push(address_array[i].trim());
		}
		let location = locationArray.toString();
		if (location.length > 100) {
			location = location.slice(0, 99);
		}
		return location;
	}

	editLocation(location) {
		let count = 0;
		for (let i = 0; i < location.location_timings.length; i++) {
			if (location.location_timings[i].open_time && location.location_timings[i].close_time) {
				count++;
			}
		}
		if (count > 0) {
			delete location['loctime'];
			if (this.locationObj && this.locationObj.id) {
				this.partnercreationService
					.updateLocation(location, this.partnerobj.id, this.locationObj.id)
					.then((data: any) => {
						let timings = cloneDeep(data.body);
						this.markers[this.selectedlocindex].location_timings = [];
						for (let j = 1; j < 8; j++) {
							if (timings.location_timings.length > 0) {
								let index = _.findIndex(timings.location_timings, {
									"day": j
								})
								if (index !== -1) {
									timings.location_timings[index]['active'] = true;
									this.markers[this.selectedlocindex].location_timings[index] = timings.location_timings[index];
								} else {
									this.markers[this.selectedlocindex].location_timings.push({
										'open_time': '',
										'close_time': '',
										'day': j,
										'active': false
									});
								}
							}
							else {
								this.markers[this.selectedlocindex].location_timings.push({
									'open_time': '',
									'close_time': '',
									'day': j,
									'active': false
								});
							}
						}
						this.closeModal();
					})
					.catch(error => {
						this.error = true;
						this.errorMsg = this.commonService.showCommonErrorMsg(error);
					});
			} else {
				this.partnercreationService
					.addPartnerLocations(location, this.partnerobj.id)
					.then((data: any) => {
						this.closeModal();
					})
					.catch(error => {
						this.error = true;
						this.errorMsg = this.commonService.showCommonErrorMsg(error);
					});
			}

		} else {
			this.error = true;
			this.errorMsg = this.translate.instant('Location_timings_error');
		}
	}

	closeModal() {
		this.address = "";
		this.location_name = '';
		this.close();
	}

	disableLocation(index) {
		this.marker[0].location_timings[index].active = !this.marker[0].location_timings[index].active;
	}

	close() {
		if (document.getElementsByClassName("pac-container").item(0)) {
			document.getElementsByClassName("pac-container").item(0).remove();
		}
		this.partnerLocations.emit(this.markers[this.selectedlocindex]);
		this.modalService.dismissAll();

	}

	showDeleteLocationConfirmation(locationdeletemodal) {
		this.open(locationdeletemodal, 'locationDeleteModal');
	}

	open(content, modalclass) {
		this.modalService.open(content, { windowClass: modalclass });
	}
	discardError() {
		this.error = false;
		this.errorMsg = '';
	}

	getLocation(input, event) {
		let lat = 25.2048;
		let lng = 55.2708;
		let a = document.getElementsByClassName("pac-container");
		if (a[0].classList.value.indexOf('hide') > -1) {
			a[0].classList.remove('hide');
		}
		if (event.keyCode === 13) {
			a[0].classList.add('hide');
			let loc = new google.maps.LatLng(lat, lng);
			let scope = this;
			let map = new google.maps.Map(document.createElement('div'), {
				center: loc,
				zoom: 15
			});
			var request = {
				location: loc,
				radius: 1500,
				query: input
			};
			let service = new google.maps.places.PlacesService(map);
			service.textSearch(request, function(results, status) {
				scope.setAddress(results[0]);
			});
		}
	}

	getOpeningHours(address, lat, lng) {
		let map, countt = 0;
		let service;
		let pyrmont = new google.maps.LatLng(lat, lng);
		this.weekarray = {};
		let selecteddata = [];
		map = new google.maps.Map(document.createElement('div'), {
			center: pyrmont,
			zoom: 15
		});
		var request = {
			placeId: address.place_id ? address.place_id : address.location_code,
			fields: ['opening_hours']
		};
		var scope = this;
		var count = 0;
		service = new google.maps.places.PlacesService(map);
		service.getDetails(request, function(place, status) {
			if (status == google.maps.places.PlacesServiceStatus.OK) {
				let mark;
				selecteddata.push({
					latitude: address.latitude ? address.latitude.toFixed(7) : lat.toFixed(7),
					longitude: address.longitude ? address.longitude.toFixed(7) : lng.toFixed(7),
					label: address.formatted_address ? address.formatted_address : address.address,
					location_code: address.place_id ? address.place_id : address.location_code,
					address: address.formatted_address ? address.formatted_address : address.address,
					location_name: scope.location_name,
					draggable: true,
					open: place.opening_hours ? place.opening_hours.open_now : false,
					location_timings: [],
					active: true,
					sponsor: scope.partnerobj.id,
					location_type: "STR"
				});
				scope.marker[0].location_timings = selecteddata[0].location_timings;
				mark = scope.marker[0];
				for (let k = 0; k < 7; k++) {
					scope.weekarray[k] = [];
				}
				if (place.opening_hours && place.opening_hours.periods.length > 0) {
					for (let i = 0; i < place.opening_hours.periods.length; i++) {
						scope.weekarray[place.opening_hours.periods[i].open.day].push(place.opening_hours.periods[i]);
					}
				}
				for (let j = 0; j < 7; j++) {
					scope.checkTimings(j, mark);
				}
			}
			if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
				setTimeout(function() {
					scope.getOpeningHours(address, lat, lng);
				}, 2500);
			}
		});

	}
}


