import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StrategicparternsService {
  constructor(private webService: WebService) { }
  private host = environment.host();
  private hostv2 = environment.hostv2();
  private childSponsors = [];
  private parentSponsor = [];

   getSponsorStages(){
      let requestUrl = this.host + 'sponsor-stage/?page_size=20';
      return this.webService.getAPI(requestUrl, false);
   }

   getParent(sponsorId){
     var requestUrl = this.host + 'trim/sponsors/' + sponsorId;
     return this.webService.getAPI(requestUrl, false);
   }

   getIndustriesLookupValues(){
      var requestUrl = this.host + 'lookups/?type=INDUSTRY_TYPE&active=True';
      return this.webService.getAPI(requestUrl, false);
   }

   getSearchFilterSponsorsData(id , stages, indus, search){
     var status = (stages !== '' && stages !== undefined) ? ('&status=' + stages) : '';
     var industy = (indus !== '' && indus !== undefined) ? ('&industry=' + indus) : ''; 
     var searchval = (search !== '' && search !== undefined) ? ('&search=' + search) : ''; 
     var requestUrl = this.host + 'sponsors/' + id + '/hierarchy/?page_size=500' + status + industy + searchval;
     return this.webService.getAPI(requestUrl, false);
   }

   StrategicPartners(id){
    let requestUrl = this.host + 'sponsors/' + id + '/hierarchy/?page_size=2000&status=A,S,T';
    return this.webService.getAPI(requestUrl, false);
  }

  setChildSponsors(data){
    this.childSponsors = data;
  }

  setParentSponsor(data){
    this.parentSponsor = [];
    this.parentSponsor.push(data);
  }

  getParentSponsor(){
    return this.parentSponsor;
  }

  getChildSponsors(){
    return this.childSponsors;
  }
}
