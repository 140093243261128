export class dashboardCMSModel {
    memberHeader: string;
    memberDescription: string;
    milesAwardedHeader : string;
    milesAwardedDescription : string;
    earnTransactionsHeader : string;
    earnTransactionsDescription : string;
    associatedSpendingHeader : string;
    associatedSpendingDescription : string;
    performanceSnapshotHeader : string;
    performanceSnapshotDescription : string;
    analyseYourPerformanceHeader : string;
    analyseYourPerformanceDescription : string;
    performanceMonth : string;
    performancetoDate : string;
}


export class dashboardEntryIdObj {
  	entryIds = [
      { id:'1Py6AXsK1SdYxAHYQYGLzq', text : 'memberHeader'},
      { id:'6KE4AEfEJjSi7BvcgSPpys', text : 'memberDescription'},
      { id:'6i4Y6MXplRW3gtM5jYCgn9', text : 'milesAwardedHeader'},
      { id:'7CFenbhlTNYz9HSWz27Nb3', text : 'milesAwardedDescription'},
      { id:'zeIC6gLhRGzOZfgJ1eEM5', text : 'earnTransactionsHeader'},
      { id:'3t8WFSdWuaVv8EMVDnarn4', text : 'earnTransactionsDescription'},
      { id:'5ELAyBS39MoyUCrothHYTu', text : 'associatedSpendingHeader'},
      { id:'38W751BtcMSLuHBZZwaLpU', text : 'associatedSpendingDescription'},
      { id:'mtS72ztOctsMcjVnpFDrg', text : 'performanceSnapshotHeader'},
      { id:'7A73Ak9kIb81Fy0bNmAN0x', text : 'performanceSnapshotDescription'},
      { id:'3RKKMOO0Zjz1NZey75TqLh', text : 'analyseYourPerformanceHeader'},
      { id:'6lWAmV1g22bynqYntxS1Do', text : 'analyseYourPerformanceDescription'},
      { id:'25nxTDVj3RUCyKKhxHcYgA', text : 'performanceMonth'},
      { id:'3ogYiIkdpkfgfdSqugUUmo', text : 'performancetoDate'}
  	];
}