import { Component, OnInit, Input } from '@angular/core';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-offeroverlapp',
	templateUrl: './offeroverlapp.component.html',
	styleUrls: ['./offeroverlapp.component.less']
})
export class OfferoverlappComponent implements OnInit {
	@Input() offerobj: any;
	constructor(private modalService: NgbModal) { }

	ngOnInit() {
	}

	close() {
		this.modalService.dismissAll();
	}
}
