import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService, PartnercreationService, CommonService} from '../../services';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.less']
})
export class ResetpasswordComponent implements OnInit {
  error; forgoterror; showForgotForm; passwordcompare : boolean = false;
  showSignInForm : boolean = true;
  partnerobj:any;
  errorMsg : string = "";
  resetpasswordobj = {
    "new_password": "",
    "confirm_password": "",
    "email": "",
    "reset_key": "",
  }
  changepasswordobj = {
    "current_password": "",
    "password": ""
  }
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private partnercreationService: PartnercreationService,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.resetpasswordobj.reset_key = this.route.snapshot.queryParamMap.get('reset_key');
    this.resetpasswordobj.email = this.route.snapshot.queryParamMap.get('email');
  }

  resetPassword(resetpasswordform) {
    this.error = false;
    this.errorMsg = "";
    if (resetpasswordform) {
      this.partnercreationService
        .doResetPassword(this.resetpasswordobj)
        .then((data: any) => {
          this.router.navigate(['/signin']);
        })
        .catch(error => {
          if(error.error.code==="expired_code"){
            this.router.navigate(['/linkexpired']);
          }else{
            this.error = true;
            this.errorMsg = this.commonService.showCommonErrorMsg(error);
          }
          
        });
    }
  }

  comparePassword(password, confirmpassword) {
    if (password !== confirmpassword) {
      this.passwordcompare = true;
    } else {
      this.passwordcompare = false;
    }
  }

  hideForgotPassword() {
    this.router.navigate(['/signin']);
  }

  gotoPage(url) {
    this.router.navigate([url]);
  }
}
