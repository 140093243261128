import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, PartnercreationService } from 'src/services';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-paymentsdownloadmodal',
  templateUrl: './paymentsdownloadmodal.component.html',
  styleUrls: ['./paymentsdownloadmodal.component.less']
})
export class PaymentsdownloadmodalComponent implements OnInit {

  constructor(
    private modalService:NgbModal,
    private partnerCreationService:PartnercreationService,
    private commonService:CommonService
    ) { }
  @Input() isUsedForDownloadingPayments:boolean;
  //This modal is being used for downloading Transactions and payments both
  @Input() partnerdata:any;
  @Input() payment_plan:any;
  isSelectedFormatOptionCSV:boolean=true;
  start_date:any ;
  end_date:any;
  errorMsg:any;
  showRangeError:boolean=false;
  maxDate = moment();
  isSelectedDateCorrect :boolean = false;
  ngOnInit(): void {
    
  }

  closeModal() {
		this.modalService.dismissAll();
	}
  
  choosedDate(event:any){
    this.showRangeError = false;
    let firstDate ;
    let secondDate;
    if (event.chosenLabel.split(' - ')[0]) {
      firstDate = moment(event.chosenLabel.split(' - ')[0]);
      this.start_date = firstDate.format('YYYY-MM-DD');
    }
    if (event.chosenLabel.split(' - ')[1]) {
      secondDate = moment(event.chosenLabel.split(' - ')[1])
      this.end_date = secondDate.format('YYYY-MM-DD');
    }

    this.isSelectedDateCorrect = this.start_date !==null && this.end_date !==null;

    if (!this.isUsedForDownloadingPayments) {
      let differenceInYears = moment(secondDate).diff(moment(firstDate), 'years');
      if (differenceInYears != 0) {
        this.showRangeError = true;
      }
    }

  }

  //Methods for downloading Payments starts


  downloadPayment() {
    if (this.start_date && this.end_date) {
      this.partnerCreationService.getPaymentsToDownload(this.partnerdata.id, this.start_date, this.end_date).then((data: any) => {
        data = this.formatData(data);
        if(this.isSelectedFormatOptionCSV){
          this.downloadFileAsCSV(data);
        }
        else{
          this.downloadASXLS(data);
        }
        this.closeModal();
      });
    }
  }

  changeSelection(event:any){
    this.isSelectedFormatOptionCSV=!this.isSelectedFormatOptionCSV;
  }

  formatData(data:any){
    let formatDataArray = [];
    formatDataArray = data.map(value => ({
      payment_date:value.payment_date,
      payment_id:value.payment_id,
      amount:value.currency_code +" "+ value.amount,
      milespurchased:value.miles_credited +' Miles'
    }));

    return formatDataArray;
  }

  downloadFileAsCSV(data, filename = 'data') {
    let csvData;
    if(this.payment_plan === 'postpaid'){
      csvData =  this.ConvertToCSV(data, ["payment_date","payment_id","amount"]);
    }
    else {
      csvData= this.ConvertToCSV(data, ["payment_date","payment_id","amount","milespurchased"]);
    }

    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    // console.log(array);
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }


  downloadASXLS(data){
    const workBook = XLSX.utils.book_new(); 
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); 
    XLSX.writeFile(workBook, 'temp.xlsx');
  }

  //Methods for downloading Payments ends



  // Methods for Downloading transactions starts

  downLoadFile(data: any, filename: string = null) {
		let a = document.createElement('a');
		a.href = data.url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
    this.closeModal();
	}

  downloadTransactions(type) {
		this.partnerCreationService
			.downloadTransactions(this.partnerdata.id, this.start_date, this.end_date, type)
			.then((data: any) => {
				this.downLoadFile(data);
			})
			.catch(error => {
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}
  // Methods for Downloading transactions ends

  

}
