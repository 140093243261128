export class Partner {
  id:number;
  email: string;
	confirmemail: string;
	user_name: string;
	password: string;
	confirmpassword: string;
	contactcode:string;
	landlinecode:string;
	contact_person_title:string;
	contact_person_first_name:string;
	contact_person_last_name:string;
	contact_person_name:string;
	brand_name:string;
	entity_name:string;
	logo:string;
	trade_license_number:string;
	phone_number:string;
	name:string;
	employee_count:string;
	url:string;
  showSignOut:boolean;
	extra_data:any;
  country: string;
  region: string;
  city: string;
  pin_code: string;
  header: string;
  status: string;
  description:string;
  pin:string;
  industry:string;
  username: string;
  currency: string;
  email_verified: boolean;
  sponsor_fax: string;
  additional_emails: any;
}



export const partner: Partner[] = [{
   id:null,
   email: '',
   confirmemail: '',
   user_name: '',
   password: '',
   confirmpassword: '',
   contactcode: '57',
   landlinecode: '57',
   contact_person_title: '',
   contact_person_first_name: '',
   contact_person_last_name: '',
   contact_person_name: '',
   brand_name: '',
   entity_name: '',
   logo:'https://gravty-lt.s3.amazonaws.com/data/38/sponsor/logos/45df45b3-93dc-4d77-98f0-dab0b861fc54-gravty.png',
   trade_license_number: '',
   phone_number: '',
   name: '',
   employee_count:null,
   url: '',
   showSignOut:false,
   extra_data: {
        no_of_locations_uae: '',
        no_of_locations_globally: '',
        landline: '',
        date_of_establishment: '',
        draft_logo : '',
        draft_header : '',
        legal_entity_type: '',
        statuatory_representative: '',
        partner_owner_name: '',
        business_type: '',
        finance_contact: '',
        finanace_email: '',
        finanace_number: '',
        bank_contact: '',
        bank_number: '',
        bank_email: '',
        financecode: '27',
        bankcode: '',
        form_of_payment: 'Bank Transfer',
        last_ownership_change: '',
        current_management_tenure: '',
        estimated_average_monthly_sales1: 'USD',
        market_position: '',
        level_of_sophistication_of_operations: '',
        type_of_business: '',
        country1: '',
        state1: '',
        city1: '',
        street_line_1: '',
        street_line_2: '',
        zip_code1: '',
        office_street_line_1: '',
        office_street_line_2: '',
        transaction_threshold: '',
        capture_receipt_number: '',
        receipt_check : false,
        onboarding_step_completed: 0,
        sponsor_pin_threshold: 0,
        monthly_cost_of_miles: 0,
        contract_signed: false,
        percentage_of_sales: 0
    },
    country: '',
    region: '',
    city: '',
    pin_code: '',
   header: '',
   status: '',
   description: '',
   pin:'',
   industry:'',
   username: '',
   currency: 'USD',
   email_verified: false,
   sponsor_fax: '',
   additional_emails: []
}];