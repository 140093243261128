import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, ContentfulService } from '../../services';
import { faqCMSModel, faqEntryIdObj } from "../../models";

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.less', './../termsnconditions/termsnconditions.component.less']
})
export class FaqComponent implements OnInit {
	showsignin: boolean = false;
	locale: string = 'en-US';
	faqCMSData: any = {};
	faqIdObj = new faqEntryIdObj();
	questionsArray: any = [];
	subquesArray: any;
	mainQues: number = null;
	subQues: number = null;
	headerImg: any;
	currentUser: any;
	partnerobj: any;


	constructor(private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.getData();
	}

	gotoBackPage() {
		if (this.currentUser && (this.partnerobj.status === 'S' || this.partnerobj.status === 'T' || this.partnerobj.status === 'A')) {
			this.router.navigate(['/profile']);
		} else {
			this.router.navigate(['/skywardseveryday']);
		}
	}

	gotoPage(url) {
		this.router.navigate([url]);
	}

	getData() {
		let contentFulIds = this.faqIdObj.entryIds;
		for (let entry of contentFulIds) {
			this.contentful.getContent(entry.id, this.locale).subscribe(dataobj => {
				this.faqCMSData = dataobj;
				for (let i = 0; i < this.faqCMSData.faqPointerRef.length; i++) {
					this.questionsArray.push({
						'mainquestion': this.faqCMSData.faqPointerRef[i].fields.topicPointers,
						'id': this.faqCMSData.faqPointerRef[i].sys.id
					})
				}
			});
		}
		this.contentful.getContent('5Kc6ZxS4poK28gZoNTOslh', this.locale).subscribe(dataobj => {
			this.headerImg = dataobj['imageContent'].fields.file.url;
		});

	}

	getQuestions(question, index) {
		this.contentful.getContent(question.id, this.locale).subscribe(dataobj => {
			if (this.mainQues >= 0 && this.mainQues === index) {
				this.mainQues = null;
				this.subQues = null;
			} else {
				this.mainQues = index;
				this.subQues = null;
			}
			this.subquesArray = dataobj;
		});
	}

	setIndex(index) {
		this.subQues = index;
	}

	clearIndex(index){
		this.subQues = null;
	}
}
