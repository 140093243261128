import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "./../environments/environment";
import { PartnercreationService } from '../services/partnercreation.service';
import { TranslateService } from "@ngx-translate/core";
import * as cloneDeep from 'lodash/cloneDeep';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    backClick: boolean = false;

    constructor(private http: HttpClient,
        private partnercreationService: PartnercreationService,
        private translate: TranslateService) { }
        private host = environment.host();
        private hostv2 = environment.hostv2();
    scrollTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 5);
    }
    showErrorMsg(error, errorFlag, errorMsg) {
        errorFlag = true;
        if (Object.keys.length > 0) {
            this.scrollTop();
            let Messages = Object.keys(error).map((key) => (error[key][0].message));
            errorMsg = Messages.toString();
        }
    }

    addDocument(partnerId, event, array, filesize, tag, errorObj, loaderObj) {
        let myReader: FileReader = new FileReader();
        let file = event.target.files[0];
        if (file) {
            file["file_size_in_kb"] = file.size;
            myReader.addEventListener('load', function(loadevent) {
                file["image"] = loadevent['target']['result'];
            });
            myReader.readAsDataURL(file);
            let extension = file.name.split('.').pop();
            if (filesize !== undefined && filesize !== '' && file.file_size_in_kb > filesize) {
                if (errorObj) {
                    errorObj.error = true;
                    if (tag === 'trade_license' || tag === 'photo_id') {
                        errorObj.errorMsg = this.translate.instant("File_size_error");
                    } else {
                        errorObj.errorMsg = this.translate.instant("File_size_error_5mb");
                    }
                    
                }
                if (loaderObj && tag) {
                    loaderObj[tag] = false;
                } else {
                    loaderObj.showloader = false;
                }
            } else if ((file.type !== "application/pdf" && file.type !== "image/jpeg" && file.type !== "image/png") || (file.type === "image/jpeg" && (extension === 'jfif' || extension === 'JFIF' || extension === 'JFI' || extension === 'jfi' || extension === 'jif' || extension === 'JIF'))) {
                if (errorObj) {
                    errorObj.error = true;
                    errorObj.errorMsg = this.translate.instant("File_format_error"); 
                }
                if (loaderObj && tag) {
                    loaderObj[tag] = false;
                } else {
                    loaderObj.showloader = false;
                }
            } else {
                if (errorObj) {
                    errorObj.error = false;
                }
                let request = {
                    "file_name": file.name.replace(/[^A-Z0-9._-]+/ig, ""),
                    "file_type": file.type,
                    "file_size_in_kb": file.size
                };
                if (tag !== undefined && tag !== null && tag !== '') {
                    request["tag"] = tag;
                    this.partnercreationService
                        .uploadDocument(partnerId, request)
                        .then((data: any) => {
                            if (!file['image']) {
                                file['image'] = myReader.result;
                            }
                            file["id"] = data.body.id;
                            this.uploadtos3(data, file, true, errorObj, loaderObj, tag, array);
                            array.push(file);
                        })
                        .catch(error => {
                            if (errorObj) {
                                errorObj.error = true;
                                errorObj.errorMsg = this.showCommonErrorMsg(error);
                            }
                            if (loaderObj) {
                                loaderObj[tag] = false;
                            }
                        });
                } else {
                    request["subfolder"] = "sponsor/logos/";
                    this.partnercreationService
                        .uploadImage(partnerId, request)
                        .then((data: any) => {
                            if (!file['image']) {
                                file['image'] = myReader.result;
                            }
                            file["image_url"] = data.body.url;
                            array.push(file);
                            this.uploadtos3(data, file, false, errorObj, {}, '', '');
                        })
                        .catch(error => {
                            if (loaderObj) {
                                loaderObj.showloader = false;
                            }
                        });
                }
            }
        }
    }

    /*uploadImage(partnerId, event, filearray) {
        let myReader: FileReader = new FileReader();
        let file = event.target.files[0];
        if (file) {
            myReader.onloadend = (event) => {
                file["image"] = myReader.result;
            }
            myReader.readAsDataURL(file);
            let request = {
                "file_name": file.name.replace(/[^A-Z0-9._-]+/ig, ""),
                "file_type": file.type,
                "subfolder": "sponsor/logos/"
            };
            this.partnercreationService
                .uploadImage(partnerId, request)
                .then((data: any) => {
                    file["image_url"] = data.body.url;
                    filearray.push(file);
                    this.uploadtos3(data, file, {}, {});
                })
                .catch(error => {

                });
        }
    } */

    public partnerObj: any;
    public getPartnerData(partnerId): any {
        let url = this.host + "sponsors/" + partnerId + '/';
        return this.http.get(url).map(res => {
            return res;
        });
    }

    public getNotifications(partnerId): any {
        let url = this.host + "sponsors/" + partnerId + '/notifications/';
        return this.http.get(url).map(res => {
            return res;
        });
    }

    public enableReceiptCheck = function(partnerId, request) {
        var url = this.host + "sponsors/" + partnerId + '/bit-correlation/';
        return this.http.post(url, request).map(function(res) {
            return res;
        });
    };

    public getMemberSegments(id: any): any {
        let url = this.host + "members-segments/?ordering=-created_ts&sponsor="+id+"&active=True&page_size=500";
        return this.http.get(url).map(res => {
            return res;
        });
    }

    public downloadTransactions = function(partnerId, startdate, enddate) {
        let headers = new HttpHeaders();
        const options: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'arraybuffer';
            withCredentials?: boolean;
        } = {
            responseType: 'arraybuffer'
        };
        var url = this.hostv2 + "bit/export/?sponsor_id=" + partnerId + '&date_from=' + startdate + '&date_to=' + enddate + '&status=SUCCESS';
        return this.http.get(url, options).map(function(res) {
            return res;
        });
    };

    public downloadTransactionsXLS = function(partnerId, startdate, enddate) {
        let headers = new HttpHeaders();
        const options: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'arraybuffer';
            withCredentials?: boolean;
        } = {
            responseType: 'arraybuffer'
        };
        var url = this.hostv2 + "bit/export/?sponsor_id=" + partnerId + '&date_from=' + startdate + '&date_to=' + enddate + '&status=SUCCESS';;
        return this.http.get(url, options).map(function(res) {
            return res;
        }); 
    };

    removeDocument(partnerId, index, array, errorObj, fromUpload) {
        if (!fromUpload) {
            errorObj.error = false;
            errorObj.errorMsg = false;
        }
        this.partnercreationService
            .deleteDocument(partnerId, array[index].id)
            .then((data: any) => {
                array.splice(index, 1);
                if (!fromUpload) {
                    errorObj.error = false;
                    errorObj.errorMsg = '';
                }
            })
            .catch(error => {
                errorObj.error = true;
                errorObj.errorMsg = this.showCommonErrorMsg(error);
            });
    }

    getData(datatype, value) {
        let url;
        switch (datatype) {
            case 'lookups': {
                url = this.host + "lookups/?type=" + value + "&active=True";
                break;
            }
            case 'state': {
                url = this.host + "regions/" + value + "/";
                break;
            }
            case 'city': {
                url = this.host + "cities/" + value + "/";
                break;
            }
            case 'country': {
                url = this.host + "countries/";
                break;
            }
            case 'attributes': {
                url = this.host + "attributelibrary/custom/?module_type=SPONSOR";
                break;
            }
            default: {
                break;
            }
        }
        return this.http.get(url).map(res => {
            return res;
        });
    }

    uploadtos3(data, file, showpreview, errorObj, loaderObj, tag, array) {
        let xhr = new XMLHttpRequest();
        let scope = this;
        xhr.open("PUT", data.body.signed_request, true);
        if (window.navigator.userAgent.indexOf("Edge") < 0) {
            xhr.setRequestHeader('Content-Type', file.type);
        }
        //xhr.setRequestHeader('x-amz-acl', 'public-read');
        xhr.onload = function() {
            if (this['status'] === 200) {
                if (showpreview) {
                    scope.getPreview(data.body.entity_id, data.body.id).subscribe(result => {
                        file['preview_url'] = result['signed_request'];
                        if (errorObj) {
                            errorObj.error = false;
                        }
                        if (loaderObj && tag) {
                            loaderObj[tag] = false;
                        } else {
                            loaderObj.showloader = false;
                        }
                    },error => {
                       errorObj.error = true;
                       if (loaderObj && tag) {
                            loaderObj[tag] = false;
                        } else {
                            loaderObj.showloader = false;
                        }
                       errorObj.errorMsg = scope.translate.instant('COMMON_ERROR');
                    });
                } else {
                    if (errorObj) {
                        errorObj.error = false;
                    }
                    if (loaderObj && tag) {
                        loaderObj[tag] = false;
                    } else {
                        loaderObj.showloader = false;
                    }
                }
            } else {
                errorObj.error = true;
                errorObj.errorMsg = scope.translate.instant('COMMON_ERROR');
                if (loaderObj && tag) {
                    loaderObj[tag] = false;
                    scope.removeDocument(data.body.entity_id, array.length-1, array, errorObj, true);
                } else {
                    loaderObj.showloader = false;
                }
            }
        };
        xhr.onerror = function() {
            if (errorObj) {
                errorObj.error = true;
                errorObj.errorMsg = scope.translate.instant('COMMON_ERROR');
            }
            if (loaderObj) {
                loaderObj.showloader = false;
                scope.removeDocument(data.body.entity_id, array.length-1, array, errorObj, true);
            }
        };
        let blob = this.dataURItoBlob(file.image);
        xhr.send(blob);
    }

    dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let bytesarray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            bytesarray[i] = byteString.charCodeAt(i);
        }
        let dataView = new DataView(arrayBuffer);
        let blob = new Blob([dataView], { type: mimeString });
        return blob;
    }

    getPreview(partnerId, documentId) {
        let url = this.host + 'sponsors/' + partnerId + '/documents/' + documentId + '/preview/';
        return this.http.get(url).map(res => {
            return res;
        },error => {
            return error;
        });
    }

    assignLookUpValues(data, partnerobj) {
        let index, partnerAttributes: any = [];
        this.getData('attributes', '').subscribe(result => {
            partnerAttributes = result;
            for (let i in data) {
                if (i === "billing_plan" && partnerobj.billing_plan !== null) {
                    partnerobj.billing_plan = cloneDeep(data.billing_plan);
                    if (partnerobj.billing_plan.min_commitment === 0) {
                        partnerobj.billing_plan.min_commitment = this.translate.instant('No_commitment');
                    } else if (partnerobj.billing_plan.min_commitment === 100000) {
                        partnerobj.billing_plan.min_commitment = "100, 000";
                    } else if (partnerobj.billing_plan.min_commitment === 250000) {
                        partnerobj.billing_plan.min_commitment = "250, 000";
                    }
                }
                else if (i === "country") {
                    this.getDataValues("country", "country", '', false, data[i], partnerobj);
                }
                else if (i === "region") {
                    this.getDataValues("state", "region", data["country"], false, data[i], partnerobj);
                }
                else if (i === "city") {
                    this.getDataValues("city", "city", data["region"], false, data[i], partnerobj);
                } else if (i === "employee_count") {
                    partnerobj[i] = data[i];
                }
                else if (data.hasOwnProperty(i) && i !== "extra_data" && data[i] !== '' && data[i] !== null) {
                    index = partnerAttributes.findIndex(
                        x => x.name === i
                    );
                    if (index !== -1) {
                        if (partnerAttributes[index].data_type === "LOOKUP") {
                            this.getLookupData(partnerAttributes[index].lookup_type, i, partnerobj, data);
                        } else {
                            partnerobj[i] = data[i];
                        }
                    } else {
                        partnerobj[i] = data[i];
                    }
                } else if (i === "extra_data" && data[i] !== '' && data[i] !== null) {
                    for (let j in data.extra_data) {
                        if (j === "country1") {
                            this.getDataValues("country", "country1", '', true, data.extra_data[j], partnerobj);
                        }
                        else if (j === "state1") {
                            this.getDataValues("state", "state1", data.extra_data["country1"], true, data.extra_data[j], partnerobj);
                        }
                        else if (j === "city1") {
                            this.getDataValues("city", "city1", data.extra_data["state1"], true, data.extra_data[j], partnerobj);
                        }
                        else if (data.extra_data.hasOwnProperty(j)) {
                            index = partnerAttributes.findIndex(
                                x => x.name === j
                            );
                            if (index !== -1) {
                                if (partnerAttributes[index].data_type === "LOOKUP") {
                                    this.getLookupData(partnerAttributes[index].lookup_type, j, partnerobj, data);
                                } else {
                                    partnerobj.extra_data[j] = data.extra_data[j];
                                }
                            } else {
                                partnerobj.extra_data[j] = data.extra_data[j];
                            }
                        } else {
                            partnerobj.extra_data[j] = data.extra_data[j];
                        }
                    }
                }
            }
        });
    }

    getDataValues(type, fromattribute, attributeval, fromextradata, code, partnerobj) {
        let index, data;
        if (attributeval !== undefined && attributeval !== null) {
            this.getData(type, attributeval).subscribe(result => {
                data = result;
                index = data.findIndex(
                    x => x.id === parseInt(code)
                );
                if (index !== -1 && fromextradata) {
                    partnerobj.extra_data[fromattribute] = data[index].name;
                }
                if (index !== -1 && !fromextradata) {
                    partnerobj[fromattribute] = data[index].name;
                }
            });
        }
    }

    getLookupData(lookupname, attribute, partnerobj, data) {
        let lookupsData: any = [];
        this.getData('lookups', lookupname).subscribe(result => {
            lookupsData = result;
            for (let i = 0; i < lookupsData.length; i++) {
                if (data[attribute] === lookupsData[i].code) {
                    partnerobj[attribute] = lookupsData[i].name;
                }
                if (data.extra_data[attribute] === lookupsData[i].code) {
                    partnerobj.extra_data[attribute] = lookupsData[i].name;
                }
            }
        })
    }

    showPreview(doc) {
        window.open(doc.signed_request);
    }

    showCommonErrorMsg(error) {
        let errorMsg = '';
        if (error && error.error) {
            error = error.error;
            if (error && error.message) {
                errorMsg = error.message;
            } else if (error[0] && error[0].message) {
                errorMsg = error[0].message;
            } else if (error.error && error.error.error && error.error.error[0] && error.error.error[0].message) {
                errorMsg = error.error.error[0].message;
            } else if (error.error && error.error.message) {
                errorMsg = error.error.message;
            } else if (error.error) {
                errorMsg = error.error;
            } else {
                errorMsg = this.translate.instant('COMMON_ERROR');
            }
        } else {
            errorMsg = this.translate.instant('COMMON_ERROR');
        }
        return errorMsg;
    }

    setBackClicked(backclick) {
        this.backClick = backclick;
    }

    getBackClicked() {
        return this.backClick;
    }

    checkNumber(event) {
        return event.charCode >= 48 && event.charCode <= 57;
    }

}