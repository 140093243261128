import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-refundmodal',
	templateUrl: './refundmodal.component.html',
	styleUrls: ['./refundmodal.component.less']
})
export class RefundmodalComponent implements OnInit {
	@Output('getAllTransactions') transactionDetails = new EventEmitter<any>();
	@Input() transactionobj: any;
	@Input() partnerobj: any;
	refundtype: string = "fullrefund";
	refundfailure: boolean = false;
	refundsuccess: boolean = false;
	refundamnt: number = null;
	baseMilesDeducted: number = null;
	bonusMilesDeducted: number = null;
	milesDeductedToPartner: number = null;
	remaningCostOfMiles: number = null;
	retaPerMile: number = 0.333;
	error: boolean = false;
	errorMsg: string;
	refundObj: any;
	transactionReq: any;
	refundinprogress: boolean = false;
	showreceipt: boolean = false;
	receiptval: string = '';
	remainingamt: number = 0;
	locale: string = "en-US";
	headerImg: any;
	constructor(
		private modalService: NgbModal,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private contentful: ContentfulService,
		private translate: TranslateService
	) { }

	ngOnInit() {
		this.getBillingConfig(this.partnerobj.id);
		this.getLocationPin(this.transactionobj.h_location, this.partnerobj.id);
		if (!this.transactionobj.header.h_bit_source_generated_id) {
			this.showreceipt = true;
		}
		this.getData();
	}

	close(fromcancel) {
		if (!fromcancel) {
			this.transactionDetails.emit();
		}
		this.modalService.dismissAll();
	}

	refundTransaction() {
		this.cancelTransaction();
	}

	cancelTransaction() {
		this.refundsuccess = false;
		this.refundsuccess = false;
		this.refundinprogress = true;
		//let sponsorpin = (this.transactionobj.header.h_bit_sponsor_pin==='') ? this.partnerobj.pin : this.transactionobj.header.h_bit_sponsor_pin
		this.transactionReq = {
			"h_bit_category": "CANCELLATION",
			"h_bit_type": "REVERSAL",
			"h_program_id": this.transactionobj.h_program_id,
			"h_sponsor_id": this.transactionobj.h_sponsor_id,
			"h_location": this.transactionobj.h_location,
			"h_member_id": this.transactionobj.header.h_member_id,
			"cancel_bit_id": this.transactionobj.bit_reference,
			"h_refund_amount": (this.transactionobj.header.h_bit_amount - this.refundamnt),
			"h_bit_source": "WEB",
			"h_bit_location_pin": this.transactionobj.header.h_bit_location_pin,
			"h_bit_sponsor_pin": this.partnerobj.pin,
			"h_bit_amount": this.transactionobj.header.h_bit_amount
		};
		this.partnercreationService
			.createTransaction(this.transactionReq)
			.then((data: any) => {
				this.refundObj = data.original_bit;
				if (this.refundtype !== 'fullrefund' && (this.transactionReq.h_refund_amount < this.transactionReq.h_bit_amount)) {
					this.accrualTransaction();
				} else {
					this.refundamnt = this.transactionReq.h_refund_amount;
					if (this.transactionobj.base_offer.value_n !== undefined) {
						this.baseMilesDeducted = this.transactionobj.base_offer.value_n;
					} else {
						this.baseMilesDeducted = 0;
					}
					if (this.transactionobj.bonus_offer.value_n !== undefined) {
						this.bonusMilesDeducted = this.transactionobj.bonus_offer.value_n;
					} else {
						this.bonusMilesDeducted = 0;
					}
					if (this.transactionobj.base_offer.value_n !== undefined || this.transactionobj.bonus_offer.value_n !== undefined) {
						let baseval = 0;
						let bonusval = 0;
						if (this.transactionobj.base_offer.value_n !== undefined && this.transactionobj.base_offer.value_n !== null) {
							baseval = this.transactionobj.base_offer.value_n;
						}
						if (this.transactionobj.bonus_offer.value_n !== undefined && this.transactionobj.bonus_offer.value_n !== null) {
							bonusval = this.transactionobj.bonus_offer.value_n;
						}
						this.milesDeductedToPartner = (baseval + bonusval) * this.retaPerMile;
					} else {
						this.milesDeductedToPartner = 0;
					}
					this.remaningCostOfMiles = (this.baseMilesDeducted + this.bonusMilesDeducted) * this.retaPerMile;
					this.refundsuccess = true;
					this.refundfailure = false;
					this.refundinprogress = false;
				}

			})
			.catch(error => {
				this.error = true;
				if (error && error.error) {
					if (error.error.code === "NUMBER_OF_DAYS_EXCEEDED") {
						this.getErrorMessage();
					} else {
						this.errorMsg = error.error.message;
					}
				} else {
					this.errorMsg = this.translate.instant('COMMON_ERROR');
				}
				this.refundsuccess = false;
				this.refundfailure = true;
				this.refundinprogress = false;
			});
	}

	accrualTransaction() {
		this.refundsuccess = false;
		this.refundsuccess = false;
		let req = {
			"h_sponsor_id": this.transactionobj.h_sponsor_id,
			"h_member_id": this.transactionobj.header.h_member_id,
			"h_bit_type": "SPEND",
			"payment_details": [],
			"h_location": this.transactionobj.h_location,
			"h_bit_amount": (this.transactionobj.header.h_bit_amount - this.refundamnt),
			"h_bit_date": this.transactionobj.h_bit_date,
			"cancel_bit_id": this.transactionobj.bit_reference,
			"h_bit_category": "ACCRUAL",
			"h_program_id": this.transactionobj.h_program_id,
			"h_bit_location_pin": this.transactionobj.header.h_bit_location_pin,
			"h_bit_currency": "USD",
			"h_bit_source": "WEB",
			"lines": [],
			"h_bit_sponsor_pin": this.partnerobj.pin,
			"h_bit_source_generated_id": this.showreceipt ? this.receiptval : this.transactionobj.header.h_bit_source_generated_id
		};
		this.partnercreationService
			.createTransaction(req)
			.then((data: any) => {
				this.refundObj = data;
				this.remainingamt = this.transactionobj.header.h_bit_amount - this.refundamnt;
				this.refundObj['base_offer'] = {};
				this.refundObj['bonus_offer'] = {};
				for (var j = 0; j < this.refundObj.offer_actions.length; j++) {
					if (this.refundObj.offer_actions[j].reward_tags !== null && this.refundObj.offer_actions[j].reward_tags[0] === 'BASE') {
						this.refundObj['base_offer'] = this.refundObj.offer_actions[j];
					}
					if (this.refundObj.offer_actions[j].reward_tags !== null && this.refundObj.offer_actions[j].reward_tags[0] === 'BONUS') {
						this.refundObj['bonus_offer'] = this.refundObj.offer_actions[j];
					}
				}
				if (this.transactionobj.base_offer.value_n !== undefined && this.refundObj.base_offer.value_n !== undefined) {
					this.baseMilesDeducted = this.transactionobj.base_offer.value_n - this.refundObj.base_offer.value_n;
				} else {
					this.baseMilesDeducted = 0;
				}
				if (this.transactionobj.bonus_offer.value_n !== undefined && this.refundObj.bonus_offer.value_n !== undefined) {
					this.bonusMilesDeducted = this.transactionobj.bonus_offer.value_n - this.refundObj.bonus_offer.value_n;
				} else {
					this.bonusMilesDeducted = 0;
				}
				if (this.refundObj.base_offer.value_n !== undefined && this.refundObj.bonus_offer.value_n !== undefined) {
					this.milesDeductedToPartner = (this.refundObj.base_offer.value_n + this.refundObj.bonus_offer.value_n) * this.retaPerMile;
				} else if (this.refundObj.base_offer.value_n !== undefined) {
					this.milesDeductedToPartner = this.refundObj.base_offer.value_n * this.retaPerMile;
				} else if (this.refundObj.bonus_offer.value_n) {
					this.milesDeductedToPartner = this.refundObj.bonus_offer.value_n * this.retaPerMile;
				} else {
					this.milesDeductedToPartner = 0;
				}
				this.remaningCostOfMiles = (this.baseMilesDeducted + this.bonusMilesDeducted) * this.retaPerMile;
				this.refundsuccess = true;
				this.refundfailure = false;
				this.refundinprogress = false;
			})
			.catch(error => {
				this.error = true;
				if (error && error.error) {
					this.errorMsg = error.error.message;
				} else {
					this.errorMsg = this.translate.instant('COMMON_ERROR');
				}
				this.refundsuccess = false;
				this.refundfailure = true;
				this.refundinprogress = false;
			});
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.retaPerMile = data[0].billing_plan.rate;
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getLocationPin(locationCode, partnerId) {
		this.partnercreationService
			.getLocationData(locationCode, partnerId)
			.then((data: any) => {
				if (data.length > 0) {
					this.transactionobj.header.h_bit_location_pin = data[0].pin;
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getData() {
		this.contentful.getContent('3k9pMaKwznNtYL9qzNqAXw', this.locale).subscribe(dataobj => {
			this.headerImg = dataobj['imageContent'].fields.file.url;
		});
	}

	getErrorMessage() {
		this.contentful.getContent('7Es4n4dxETUF6qyH00zF6M', this.locale).subscribe(dataobj => {
			this.errorMsg = dataobj['errorMessageCopy'];
		});
	}
}
