import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import {environment} from '../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import * as marked from 'marked';


@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

	private client = contentful.createClient({
		space: environment.contentful.spaceId,
		accessToken : environment.contentful.token
	});

	constructor() { }

	//console logs a response for debugging
	logContent(contentId){
		this.client.getEntry(contentId)
		.then((entry) => console.log(entry))
	}

	//retrieves content mapped to its data fields.
	getContent(contentId,userLang){
		const promise = this.client.getEntry(contentId,{
        locale: userLang
    	});
		return Observable.fromPromise(promise).map(entry => entry.fields);
	}

	getAsset(aseetId,userLang){
		const promise = this.client.getAsset(aseetId,{
        	locale: userLang
    	});
		return Observable.fromPromise(promise).map(entry => entry.fields);
	}

	// markdownToHtml(md : string){
	// 	return marked(md);
	// }

	getAssets(userLang){
		const promise = this.client.getAssets({
        	locale: userLang,
        	limit:100
    	});
		return Observable.fromPromise(promise).map(entries => entries);
	}

	getEntries(userLang){
		const promise = this.client.getEntries({
        	locale: userLang,
        	limit:1000
    	});
		return Observable.fromPromise(promise).map(entries => entries);
	}

}
