import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, ContentfulService } from '../../services';
import { paymenttncCMSModel, paymenttncEntryIdObj } from "../../models";

@Component({
	selector: 'app-paymentterms',
	templateUrl: './paymentterms.component.html',
	styleUrls: ['./paymentterms.component.less']
})
export class PaymenttermsComponent implements OnInit {
	locale: string = 'en-US';
	paymenttncCMSData: any = {};
	paymenttncIdObj = new paymenttncEntryIdObj();
	headerImg: any;
	currentUser: any;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.commonService.scrollTop();
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.getData();
	}

	getData() {
		let contentFulIds = this.paymenttncIdObj.entryIds;
		for (let entry of contentFulIds) {
			this.contentful.getContent(entry.id, this.locale).subscribe(dataobj => {
				this.paymenttncCMSData = dataobj;
			});
		}
		this.contentful.getContent('5Kc6ZxS4poK28gZoNTOslh', this.locale).subscribe(dataobj => {
			this.headerImg = dataobj['imageContent'].fields.file.url;
		});

	}

}
