import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolebasedrulesService {

  private routesNotAllowedToUserCode = [];

  constructor() {

    // all paths are ALLOWED to roles NOT Mentioned in routesNotAllowedToUserCode Array.
    this.routesNotAllowedToUserCode = [ // use this JSON object to edit/add rules
      {
        "roleCode": "PROGRAM_MANAGER",
        "notAllowedArray": [],
        "NotEditable":[],
        "internalHiddenTabsInProfilePage":[],
        "isSuperUser":true
      },
      {
        "roleCode": "SPONSOR_REPRESENTATIVE", // It's rolename is Brand manager in UAT
        "notAllowedArray": [],
        "NotEditable":[],
        "internalHiddenTabsInProfilePage":[],
        "isSuperUser":true
      },
      {
        "roleCode": "SPONSOR_LOCATION_REPRESENTATIVE",
        // "roleCode": "SPONSOR_REPRESENTATIVE",
        "notAllowedArray": ['payments','offercreation','invoicedetails','transactiondetails','users'],
        "NotEditable":['offers','profile'], // offers not editable means offercreation not allowed
        "internalHiddenTabsInProfilePage":[],
        "isSuperUser":false
      },
      {
        "roleCode": "MARKETING_MANAGER_BRAND",
        "notAllowedArray": ['payments', 'transactions','invoicedetails','transactiondetails','users'],//hiding invoicedetails and transactiondetails because payments not allowed
        "NotEditable":[],
        "internalHiddenTabsInProfilePage":['myaccount','appcontent','settings'],
        "isSuperUser":false
      },
      
      {
        "roleCode": "FINANCE_MANAGER_-_BRAND",
        "notAllowedArray": ['offers', 'profile','offercreation','offerdetails','users'], // also adding offercreation offerdetails because offers not allowed
        "NotEditable":[],
        "internalHiddenTabsInProfilePage":[],
        "isSuperUser":false
      }
    ];

  }


  checkIfPathAllowed(userRoleCode: string,path:string) {
    let pathAllowed = true;
    if (userRoleCode && userRoleCode.length > 0) {
      this.routesNotAllowedToUserCode.forEach((roleNotAllowedObj: any) => {
        if (roleNotAllowedObj.roleCode && roleNotAllowedObj.roleCode === userRoleCode && roleNotAllowedObj.notAllowedArray && roleNotAllowedObj.notAllowedArray.length > 0 ) {
          pathAllowed = !(roleNotAllowedObj.notAllowedArray.includes(path));
        }
      });
    }
    return pathAllowed;// all paths are ALLOWED to roles NOT Mentioned in routesNotAllowedToUserCode Array.

  }

  getHiddenRoutesBasedOnRoleCode(userRoleCode) {
    let hiddenPaths = [];
    if (userRoleCode && userRoleCode.length > 0) {
      this.routesNotAllowedToUserCode.forEach((roleNotAllowedObj: any) => {
        if (roleNotAllowedObj.roleCode && roleNotAllowedObj.roleCode === userRoleCode) {
          hiddenPaths = roleNotAllowedObj.notAllowedArray;
        }
      });
    }
    return hiddenPaths;
  }

  checkIfPathEditable(userRoleCode: string,path:string){
    let pathEditable = true;
    if (userRoleCode && userRoleCode.length > 0) {
      this.routesNotAllowedToUserCode.forEach((roleNotAllowedObj: any) => {
        if (roleNotAllowedObj.roleCode && roleNotAllowedObj.roleCode === userRoleCode && roleNotAllowedObj.NotEditable && roleNotAllowedObj.NotEditable.length > 0 ) {
          pathEditable = !(roleNotAllowedObj.NotEditable.includes(path));
        }
      });
    }
    return pathEditable;
  }

  getHiddenInternalTabsBasedOnRoleCode(userRoleCode){
    let hiddenInternalTabs = [];
    if (userRoleCode && userRoleCode.length > 0) {
      this.routesNotAllowedToUserCode.forEach((roleNotAllowedObj: any) => {
        if (roleNotAllowedObj.roleCode && roleNotAllowedObj.roleCode === userRoleCode) {
          hiddenInternalTabs = roleNotAllowedObj.internalHiddenTabsInProfilePage;
        }
      });
    }
    return hiddenInternalTabs;
  }

  checkIfUserHasSuperUserRole(userRoleCode){
    let isCodeSuperUser = false;
    if(userRoleCode && userRoleCode.length > 0){
      this.routesNotAllowedToUserCode.forEach((rolesObj: any) => {
        if (rolesObj.roleCode && rolesObj.roleCode === userRoleCode) {
          isCodeSuperUser = rolesObj.isSuperUser;
        }
      });
    }
    return isCodeSuperUser;
  }
  
}
