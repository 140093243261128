export class offerCMSModel {
    createOfferHeader : string;
    offerDetailsHeader : string;
    offerDetailsDescription : string;
    targetAudienceHeader : string;
    targetAudienceDescription1 : string;
    targetAudienceDescription2 : string;
    offerPeriodHeader : string;
    offerPeriodDescription : string;
    participatingOutletsHeader : string;
    participatingOutletsDescription : string;
    reviewOfferHeader : string;
    reviewOfferDescription : string;
    reviewOfferCTA : string;
    submitOfferCTA : string;
    backCTA : string;
    offerTypeDescription1: string;
    offerTypeDescription2: string;
    offerTypeDescription3:string;
    scheduleCommunicationTitle:string;
    scheduleCommunicationDescription:string;
}


export class offerEntryIdObj {
  	entryIds = [
      { id:'1nv4oZUq4TQCeqRctnANsG', text : 'createOfferHeader'},
      { id:'1CxLcbu0Xs7wGzhdwi3JZT', text : 'offerDetailsHeader'},
      { id:'7GtKVDzsruyhzS2GbUUgs3', text : 'offerDetailsDescription'},
      { id:'7yeYtZoADdJvImaNLb0GaD', text : 'targetAudienceHeader'},
      { id:'6W5MwqMhSj3dIBFzepvd8n', text : 'targetAudienceDescription1'},
      { id:'5ZQ1mGIHccy8BO7y7v4AgD', text : 'targetAudienceDescription2'},
      { id:'5WdGzBu0k2O75GhV8DWDEk', text : 'offerPeriodHeader'},
      { id:'6l4Pm7WcP7s7jhu4LVWyhH', text : 'offerPeriodDescription'},
      { id:'39PeJq1rZzLChggEq2IPi3', text : 'participatingOutletsHeader'},
      { id:'39PeJq1rZzLChggEq2IPi3', text : 'participatingOutletsDescription'},
      { id:'326SFsgzMky4VJTFmqmiEk', text : 'reviewOfferHeader'},
      { id:'1sI0UggBwXourjNWO1jV7N', text : 'reviewOfferDescription'},
      { id:'21CAxWajBxZUJtMPnLs4A7', text : 'reviewOfferCTA'},
      { id:'1Gw8mO07jokzKlMz569EZp', text : 'submitOfferCTA'},
      { id:'Yur9FWZETwmZtWmiO3Rat', text : 'backCTA'},
      { id:'7e7E92rPvg12M00DSPiNrB', text : 'offerTypeDescription1'},
      { id:'3yGWSwTc7dByaC9sihXORn', text : 'offerTypeDescription2'},
      { id:'32tmORAZkI1A0svJa4YOap', text : 'offerTypeDescription3'},
      { id:'6XlpesEJdoQT8duMcuYtiR', text : 'scheduleCommunicationTitle'},
      { id:'5HrGduNowHPzcnFkHFEjE2', text: 'scheduleCommunicationDescription'}
  	];
}