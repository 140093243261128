export class stepThreeCMSModel {
    verifyYourIdentityTitle: string;
    verifyYourIdentityDescription: string;
  	verificationDocumentsTitle: string;
    verificationDocumentsDescription: string;
    legalVerficationPassortDescription:string;
    validTradeLicence:string;
    uploadDocumentText:string;
    additionalBusinessDetails:string;
    additionalBusinessDetailsDescription:string;
    registerOfficeTitle:string;
    registerOfficeDescription:string;
    registerBusinessHeader:string;
    registerBusinessDescription1:string;
    registerBusinessDescription2:string;
    reviewApplication:string;
    savesignout:string;
    reviewsubmission:string;
    reviewsubmissionDescription:string;
    submitApplication:string;
    thanksApplicationHeader: string;
    thanksApplicationDescription1 : string;
    thanksApplicationDescription2 : string;
    weareReviewingApplication : string;
    weareReviewingApplicationDescription : string;
    weareReviewingDescription1: string;
    weareReviewingDescription: string;
    applicationRejectedDescription: string;
    communicationEmailTitle:string;
    communicationEmailDescription:string;
}


export class stepThreeEntryIdObj {
  	entryIds = [
      { id:'2tnKHnUxymQpnnlos6sZM4', text : 'verifyYourIdentityTitle'},
      { id:'297ZqWdMARkkyi3zQ0aaII', text : 'verifyYourIdentityDescription'},
  		{ id:'43m9sTdC9lAQNKgj3aEgO9', text : 'verificationDocumentsTitle'},
      { id:'689Clbmznr25ePnuPhzkdh', text : 'verificationDocumentsDescription'},
      { id:'5gkcRGVbDIr55qyXkRqlyG', text : 'legalVerficationPassortDescription'},
      { id:'1gvXLQQLN22vlpobwClTcG', text : 'validTradeLicence'},
      { id:'5OpE1LnhvtLm2L3saRSWTQ', text : 'uploadDocumentText'},
      { id:'7vcbaCwSElZbTg57OcGNlU', text : 'additionalBusinessDetails'},
      { id:'2LK24MEMSYZbpZARRpm7BZ', text : 'additionalBusinessDetailsDescription'},
      { id:'7splXGBGkJqXLaqSYgdvF2', text : 'additionalContactDetails'},
      { id:'2iBB7x7HMhRzIWp8ufKQil', text : 'additionalContactDetailsDescription'},
      { id:'2eMXy6xUuCJHbZhfYgX2JZ', text : 'registerOfficeTitle'},
      { id:'4dMKurMl2Tw9vBsenK7wPs', text : 'registerOfficeDescription'},
      { id:'7d0AKaGXm1ASOBhugMZNVn', text : 'registerBusinessHeader'},
      { id:'2Rr3VybO0PDnq9OAq4GL79', text : 'registerBusinessDescription1'},
      { id:'6R8uoFsF4asLv0Z0SUDr6G', text : 'registerBusinessDescription2'},
      { id:'3QzM3X5RVM0ASGeCZRaJBB', text : 'reviewApplication'},
      { id:'1ZxCl82n9As8Rbun8tcHg2', text : 'savesignout'},
      { id:'O7q9jqWw57mOWiCa8w0Ll',  text : 'reviewsubmission'},
      { id:'1XOeJHto7eJPDKYk3P8aOg',  text : 'reviewsubmissionDescription'},
      { id:'1CPEqe5FQixFTY2PP47d8f',  text : 'submitApplication'},
      { id:'3bGMVlQ1q7s8zFghwVrA6H',  text : 'thanksApplicationHeader'},
      { id:'3qRe2VJmdAJe29W4Btt8kV',  text : 'thanksApplicationDescription1'},
      { id:'7JMGIgR9eaZWtPCgjcDtL4',  text : 'thanksApplicationDescription2'},
      { id:'7zQzB9X4aJIn5BtjrZnSky',  text : 'weareReviewingApplication'},
      { id:'VkJwajraSqeiPwHueEbN6',  text : 'weareReviewingApplicationDescription'},
      { id:'1TvR4yqNRzOnX3rThh05CS',  text : 'weareReviewingDescription1'},
      { id:'kfSsQ9hmAgJd2jOYsfuCp',  text : 'weareReviewingDescription'},
      { id: '4QmYGqsIkvbX9o7zdB13fK', text: 'applicationRejectedDescription'},
      { id:'4Zmy30r6YDlwgtPvOy0edV',  text : 'communicationEmailTitle'},
      { id:'1p9PyufHIin2k2o6SJR7Qv',  text : 'communicationEmailDescription'}
  	];
}