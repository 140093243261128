import { Component, OnInit , Input} from '@angular/core';
import { AuthenticationService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-innerheader',
  templateUrl: './innerheader.component.html',
  styleUrls: ['./innerheader.component.less']
})
export class InnerheaderComponent implements OnInit {
    @Input() step: any;
    showbrands = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router, 
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
      this.showbrands = true;
    } 
  }

  partnerLogout() {
    this.authenticationService.logout();
  }

  gotoProfilePage() {
    if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined && this.step !== 'strategicpage'){
      this.router.navigate(['/profile'], { queryParams: { partner: localStorage.getItem('partnerid') }});
    } else if(this.step !== 'strategicpage'){
        this.router.navigate(['/profile']);
    }
  }

  shoowLogoutModal(modallogout) {
    this.open(modallogout, 'logoutModal');
  }

  open(content, modalclass) {
    this.modalService.open(content, { windowClass: modalclass });
  }

  close() {
    this.modalService.dismissAll();
  }

  gottoStrategicPartners(){
      this.router.navigate(['/strategicpartners']);
      localStorage.removeItem('partnerimg');
      localStorage.removeItem('partnername');
    }
}
