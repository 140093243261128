import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';

@Component({
	selector: 'app-invoicedetails',
	templateUrl: './invoicedetails.component.html',
	styleUrls: ['./invoicedetails.component.less']
})
export class InvoicedetailsComponent implements OnInit {
	partnerobj: any;
	step: string = 'invoices';
	error: boolean = false;
	errorMsg: string;
	invoiceid: number = null;
	invoiceDetails: any = {
		'invoice_num': ''
	};
	billingConfig: any;
	headerImage: any;
	locale: string = 'en-US';
	disableDownloadButton:boolean=false;
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.invoiceid = parseInt(this.route.snapshot.queryParamMap.get('invoice'));
		this.partnerobj = this.authenticationService.currentUserValue;
		this.getBillingConfig(this.partnerobj.sponsors[0].id);
		this.getPartnerData(this.partnerobj.sponsors[0].id);
		this.getData();
	}

	getInvoiceDetails() {
		this.partnercreationService
			.getInvoiceDetails(this.invoiceid, this.partnerobj.id)
			.then((data: any) => {
				data.start_date = moment(data.start_date).format('DD MMM YYYY');
				data.end_date = moment(data.end_date).format('DD MMM YYYY');
				data.issue_date = moment(data.issue_date).format('DD MMM YYYY');
				if(data.points && data.points.length >0){
					data['total_miles'] =  0;
					data.points.forEach((pointGiven)=>{
						// console.log(pointGiven);
						if(pointGiven.cost && pointGiven.cost > 0){
							data['total_miles'] += pointGiven.points;
						}
					});
				}
				else{
					data['total_miles']  = 0;
				}
				this.invoiceDetails = data;
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}
	downloadInvoice(){
		this.disableDownloadButton=true;
		this.partnercreationService.downloadPartnerInvoice(this.invoiceid,this.partnerobj.id).then((invoicedata:any)=>{
			this.downloadFile(invoicedata);
		}).catch((error:any)=>{
			this.disableDownloadButton=false;
			this.error = true;
			this.errorMsg = this.commonService.showCommonErrorMsg(error);
		})
	}

	downloadFile(data: any, filename: string = null) {
		let a = document.createElement('a');
		a.href = data.url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		this.disableDownloadButton = false;
	}
	backToPaymens() {
		this.router.navigate(['/payments']);
	}
	gotoMargetingPage() {
		this.router.navigate(['/payments']);
	}
	getPartnerData(partnerId) {
		this.partnercreationService
			.getPartnerData(partnerId)
			.then((data: any) => {
				this.partnerobj = data;
				this.getInvoiceDetails();
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getData() {
		this.contentful.getContent('3bup83uErb0vGXZhptHjG8', this.locale).subscribe(dataobj => {
			this.headerImage = dataobj['imageContent'].fields.file.url;
		});
	}
}
