export class Sales {
  	sales: number;
	percenatge: number;
	revenue: any;
	monthly_miles_awarded: any;
	monthly_cost_of_miles: any;
}

export const sales: Sales[] = [{
   	sales : null,
	percenatge:null,
	revenue :null,
	monthly_miles_awarded:null,
	monthly_cost_of_miles : null
}];