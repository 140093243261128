import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OffercreationService {

  constructor(
  	private webService: WebService
  ) { }
  private host = environment.host();
  getMembersTargeted(request) {
    let requestUrl = this.host + "offers/target/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  createCampaign(request) {
    let requestUrl = this.host + "template-offer/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  getCampaigns(cursor, partnerid) {
    let requestUrl = '';
    if(partnerid !== undefined && partnerid !== null && partnerid !== ''){
      requestUrl = this.host + "offers/?page_size=12&status=launched,expired&ordering=-start_date&sponsor=" + partnerid;
    } else{
      requestUrl = this.host + "offers/?page_size=12&status=launched,expired&ordering=-start_date";
    }
    let cursorstr = (cursor !== '' && cursor !== undefined) ? ('&cursor=' + cursor) : "";
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    return this.webService.getAPI(requestUrl, true);
  }

  createCampaignDRL(offerId) {
    let requestUrl = this.host + "offers/"+offerId+"/drl/";
    return this.webService.postAPI(requestUrl, '' , false);
  }

  overlapCampaign(start_date,end_date, id) {
    let requestUrl = "";
    if(id === ''){
      requestUrl = this.host + "offers/overlap/?start_date="+start_date+"&end_date="+end_date;
    } else {
      requestUrl = this.host + "offers/overlap/?start_date="+start_date+"&end_date="+end_date +'&sponsor_id=' + id;
    }
   /* if(allday){
      requestUrl = this.host + "offers/overlap/?start_date="+start_date+"&end_date="+end_date;
    }else{
      requestUrl = this.host + "offers/overlap/?start_date="+start_date+"&end_date="+end_date+"&start_hour="+start_hour+"&start_minute="+start_minute+"&end_hour="+end_hour+"&end_minute="+end_minute;
    } */
    
    return this.webService.getAPI(requestUrl, true);
  }
  launchCampaign(request) {
    let requestUrl = this.host + "offers/status/";
    return this.webService.postAPI(requestUrl, request, false);
  }

  getCampaignsList(id) {
    let requestUrl = this.host + "offers/?page_size=200&ordering=-start_date";
    if(id !== undefined && id !== null && id !== ''){
      requestUrl = requestUrl + '&sponsor=' + id;
    }
    return this.webService.getAPI(requestUrl, true);
  }

  getPartnerLocations(partnerId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/?active=True&page_size=200';
    return this.webService.getAPI(requestUrl, true);
  }
}
