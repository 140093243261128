export class marketingPageCMSModel {
  	mainTitle: string;
  	subTitle:string;
    description:string;
    subdescription:string;
    becomeaPartnerCTA:string;
    whySkywardsEverydayTitle:string;
    powerOfEmiratesHeader:string;
    powerOfEmiratesDescription:string;
    powerOfEmiratesDescription1:string;
    powerOfEmiratesDescription2:string;
    powerOfEmiratesDescription3:string;
    ItsValueForMoneyHeader:string;
    ItsValueForMoneyDescription:string;
    ItsValueForMoneyDescription1:string;
    ItsValueForMoneyDescription2:string;
    ItsValueForMoneyDescription3:string;
    ItsSimpleAndEffortlessHeader:string;
    ItsSimpleAndEffortlessDescription:string;
    ItsSimpleAndEffortlessDescription1:string;
    becomeASkywardsEverydayPartnerTitle:string;
    registerYourBusinessHeader:string;
    registerYourBusinessDescription:string;
    customizeYourPackageHeader:string;
    customizeYourPackageDescription:string;
    customizeYourPackageDescription1:string;
    verifyYourBusinessHeader:string;
    verifyYourBusinessDescription:string;
    signTheAgreementHeader:string;
    signTheAgreementDescription:string;
    letsGoHeader:string;
    letsGoDescription:string;
    PoweredByTitle:string;
    signInCTA:string;
    registerBusinessImg:string;
    customizeYourPackageImg:string;
    verifyYourBusinessImg:string;
    signTheAgreementImg:string;
    letsGoImg:string;
    CardOneImg:string;
    CardTwoImg:string;
    CardThreeImg:string;
    mainImg: string;
}


export class marketingEntryIdObj {
  	entryIds = [
  		{ id:'1EH8ePiiCNwSNL1Y0T5XLs', text : 'mainTitle'},
  		{ id:'6Ww8QCWlaGwTeV03xtsnUO', text : 'subTitle'},
  		{ id:'AxaZmP6vJcUShdr2yCGBY' , text : 'description'},
  		{ id:'eWxDQRguGh2rBxFRTQPZo' , text : 'subdescription'},
      { id:'4ZSmvt2hSLpr5Rhqmd2794', text : 'becomeaPartnerCTA'},
      { id:'28AQu65zjG5gyZI7fD1GeK', text : 'whySkywardsEverydayTitle'},
      { id:'2ilaRIUvT9Cwsi1K32sbEs', text : 'powerOfEmiratesHeader'},
      { id:'3FOW6pqZCEM7FpASdKjTPE', text : 'powerOfEmiratesDescription'},
      { id:'2FPQ7FDVLDWDhLWwQ0cz0I', text : 'powerOfEmiratesDescription1'},
      { id:'79NbnfuotO1yvo88zERG7R', text : 'powerOfEmiratesDescription2'},
      { id:'1P420v3e8XYPebzkrDKh20', text : 'powerOfEmiratesDescription3'},
      { id:'6bphjbUGN0ZUNCzSYNmYBc', text : 'ItsValueForMoneyHeader'},
      { id:'tzsJJtPYCV1rf9O0ta6dP' , text : 'ItsValueForMoneyDescription'},
      { id:'6u4KbFUfiiJv3LvliYxGZ3', text : 'ItsValueForMoneyDescription1'},
      { id:'1YBWqQ7LMwGDJdSYlxdTYs', text : 'ItsValueForMoneyDescription2'},
      { id:'1Y5q669k3G6X8rfgOAJ3Yu', text : 'ItsValueForMoneyDescription3'},
      { id:'2R94fMXZURGcYiGE5ZoPMA', text : 'ItsSimpleAndEffortlessHeader'},
      { id:'63okkdtDjqYtJj8raQHFip', text : 'ItsSimpleAndEffortlessDescription'},
      { id:'6VCcVtcYWSlu6RvgHElmzH', text : 'ItsSimpleAndEffortlessDescription1'},
      { id:'3nKRhvPytDE9pXZXuQyHlX', text : 'becomeASkywardsEverydayPartnerTitle'},
      { id:'4DHnrLKGhBbZDWNTcGuHxb', text : 'registerYourBusinessHeader'},
      { id:'6X0G7Lv9sPOBElMoNGIFcm', text : 'registerYourBusinessDescription'},
      { id:'1bpzjhCSFzKSn24mcAvEWH', text : 'customizeYourPackageHeader'},
      { id:'EF4MVBePciSUY4GPjw4hW' , text : 'customizeYourPackageDescription'},
      { id:'6slhsf5EhbPE7O2UBP9gvT', text : 'customizeYourPackageDescription1'},
      { id:'2D25FLQxw83leKCBVTfy3p', text : 'verifyYourBusinessHeader'},
      { id:'3S1YlJ2HT5jadjYrbiZbh1', text : 'verifyYourBusinessDescription'},
      { id:'6NWYMtK0Dnw5CmCTRoyDSd', text : 'signTheAgreementHeader'},
      { id:'6JxMxUi5LcMdDy96GKoFm2', text : 'signTheAgreementDescription'},
      { id:'apZoFm4nLdt3WIwsvx5wQ',  text : 'letsGoHeader'},
      { id:'7LcjGFaCNj2ml9HT1ETxAT',  text : 'letsGoDescription'},
      { id:'4cqdLTgSY74u7mwVkKbKOE',  text : 'PoweredByTitle'},
      { id:'39nw21gMEen7hWJhmSXNrr',  text : 'signInCTA'}
  	];
    essetIds = [
      { id:'5Eqj9joqs080MPrdB8hH8H', text : 'registerBusinessImg'},
      { id:'5hUCE5De3E4hxXJiYnMPpZ', text : 'customizeYourPackageImg'},
      { id:'4djv3XU8uuK6dqsurtpTZV', text : 'verifyYourBusinessImg'},
      { id:'5vBFPNgXI9fVF0njIypwWb', text : 'signTheAgreementImg'},
      { id:'79K66RGya8Q2VmLC6HvTOb', text : 'letsGoImg'},
      { id:'6OjKq6xBEuYbZlF8DnfG6n', text : 'CardOneImg'},
      { id:'2ob8VFApgyoVSkWW4Vnhcg', text : 'CardTwoImg'},
      { id:'dJPfl7lbJW8oVAVOdZHWZ', text : 'CardThreeImg'},
      { id:'4pc4Llwc3AbHI19cGonGuD', text : 'mainImg'}

    ];
}