// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let accessToken1='5_ONXO1TN12pKG__dKcS0';
let accessToken2 = '4dLyLcVKCQba9uqsY8i1XU';
export const environment = {
	production: true,
	version: '0.0.0',
	contentful:{
		spaceId : '0bj3x7b5mvkp',
		//Content delivery token
		token : accessToken1+accessToken2
	},
	apiendpoint:{
		url:'https://sanlam.demov3.gravtyshow.tech/',
		version:'v1/',
		endpoint:'bolapi/',
	},
	host : function () {
		return this.apiendpoint.url+ this.apiendpoint.endpoint + this.apiendpoint.version;
	},
	hostv2 : function () {
		return this.apiendpoint.url+ this.apiendpoint.endpoint + 'v2/';
	},
	map_key : 'AIzaSyCQB3MKiSqyL5rKWu-qATcSH-2kqPcZ3dk',
	helloSignClientId: 'cc2247041482c2c29d93e3b7a7f6aa23',
	versionCheckURL : '/auto-reload/version.json'
};
/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.