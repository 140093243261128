import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { Partner, partner } from "../../models";
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
import { stepThreeCMSModel, stepThreeEntryIdObj } from "../../models";
@Component({
    selector: 'app-partnerinformation',
    templateUrl: './partnerinformation.component.html',
    styleUrls: ['./partnerinformation.component.less']
})
export class PartnerinformationComponent implements OnInit {
    @ViewChild(HeaderComponent) header: HeaderComponent;
    constructor
        (
            private partnercreationService: PartnercreationService,
            private route: ActivatedRoute,
            private authenticationService: AuthenticationService,
            private commonService: CommonService,
            private contentful: ContentfulService,
            private router: Router
        ) { }
    @Input() partnerobj: Partner = partner[0];
    @Input() step: any = 1;
    @Input() showaaditionalinfo: boolean = false;
    @Input() showedit: boolean = false;
    @Input() showreview: boolean = true;
    showProspectInformation: boolean = true;
    error: boolean = false;
    errorMsg: string = "";
    partnerdocuments: any = [];
    partnerId: any;
    lookupsData: any = [];
    stepThreeCMSData = new stepThreeCMSModel();
    stepThreeIdObj = new stepThreeEntryIdObj();
    locale: string = 'en-US';
    billingConfig: any;
    showsuccess: boolean = false;
    tax_rate: string = "";
    reviewImg: any;
    reviewPendingImg: any;
    ngOnInit() {
        this.getData();
        this.commonService.scrollTop();
        if (this.route.snapshot.queryParamMap.get('partner')) {
            this.partnerId = parseInt(this.route.snapshot.queryParamMap.get('partner'));
            this.showreview = true;
        } else {
            this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
            this.showreview = false;
        }
        this.getPartnerData(this.partnerId);
        if (this.showaaditionalinfo === true) {
            this.getSponsordocuments();
        }
    }

    getPartnerData(partnerId) {
        if (isNaN(partnerId)) {
            this.showProspectInformation = false;
        } else {
            this.showProspectInformation = true;
        }
        this.partnercreationService
            .getPartnerData(partnerId)
            .then((data: any) => {
                this.partnerobj.showSignOut = true;
                if (data.extra_data.onboarding_step_completed === 3 || data.extra_data.onboarding_step_completed === '3') {
                    this.getBillingConfig(this.partnerId, data);
                    this.getSponsordocuments();
                } else {
                    this.commonService.assignLookUpValues(data, this.partnerobj);
                }
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });
    }



    getSponsordocuments() {
        this.partnerdocuments = [];
        this.partnercreationService
            .getPartnerDocuments(this.partnerId)
            .then((data: any) => {
                this.partnerdocuments = data.documents;
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });
    }

    partnerLogout() {
        this.authenticationService.logout();
    }

    gotoNextStep() {
        this.router.navigate(['/signtheagreement']);
    }

    showPreview(doc) {
        this.commonService.showPreview(doc);
    }
    getData() {
        let contentFulIds = this.stepThreeIdObj.entryIds;
        this.contentful.getEntries(this.locale).subscribe(dataobj => {
            this.header.getData(dataobj);
            for (let entry of contentFulIds) {
                let Index = _.findIndex(dataobj.items, {
                    "sys": {
                        "id": entry.id
                    }
                });
                if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
                    this.stepThreeCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
                }

            }
        });
    }
    resendVerification() {
        let request = {
            "email": this.partnerobj.email
        };
        this.partnercreationService
            .resendVerificationLink(this.partnerId, request)
            .then((data: any) => {
                if (data.status === 'Success') {
                    let self = this;
                    this.showsuccess = true;
                    setTimeout(function() {
                        self.showsuccess = false;
                    }, 3000);
                } else {
                    this.showsuccess = false;
                }
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = error;
                this.showsuccess = false;
            });
    }
    getBillingConfig(partnerId, partnerdata) {
        this.partnercreationService
            .getBillingConfig(partnerId)
            .then((data: any) => {
                this.billingConfig = data[0];
                this.billingConfig['rate'] = 0.071;
                this.getTaxes();
                this.step = 3;
                this.showaaditionalinfo = true;
                this.commonService.assignLookUpValues(partnerdata, this.partnerobj);
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });

    }

    getTaxes() {
        this.partnercreationService
            .getTaxes()
            .then((data: any) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].id === this.billingConfig.tax_rate) {
                        this.tax_rate = data[i].name
                    }
                }
            })
            .catch(error => {
                this.error = true;
                this.errorMsg = this.commonService.showCommonErrorMsg(error);
            });
    }
}