import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Partner, partner, marker, Map, map, Locationreq, locationmapreq } from "../../models";
import { TranslateService } from "@ngx-translate/core";
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { } from 'googlemaps';
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
import {
  NgbModal, NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { LocationsresetmodalComponent } from '../locationsresetmodal/locationsresetmodal.component';
import { stepFiveCMSModel, stepFiveEntryIdObj } from "../../models";

@Component({
  selector: 'app-letsgo',
  templateUrl: './letsgo.component.html',
  styleUrls: ['./letsgo.component.less']
})
export class LetsgoComponent implements OnInit {
  @ViewChild(HeaderComponent) header: HeaderComponent;
  partnerobj: Partner = partner[0];
  markers: marker[] = [];
  mapobj: Map = map[0];
  step: number = 5;
  error: boolean = false;
  checked: boolean = false;
  logoArray: any = [];
  headerArray: any = [];
  lookups: any = {};
  marker: marker[] = [];
  errorObj = {
    "error": false,
    "errorMsg": ''
  };
  loader: boolean = false;
  errorMsg: string = '';
  modalref: any;
  stepFiveCMSData = new stepFiveCMSModel();
  stepFiveIdObj = new stepFiveEntryIdObj();
  locale: string = 'en-US';
  modalerror: boolean = false;
  modalData: any;
  Locatreq: Locationreq = locationmapreq;
  schemeselected: string;
  isvisascheme: boolean = false;
  ismasterscheme: boolean = false;
  cardLinkRequest = [{
    "sponsor_location": null,
    "network": "",
    "acquirer_id": "",
    "terminal_code": null,
    "bin": ""
  }, {
    "sponsor_location": null,
    "network": "",
    "acquirer_id": "",
    "terminal_code": null,
  }];
  locationcarderror: boolean = false;
  locationid: string = '';
  locationdata: any = [];
  payschemeval: string='select';
  partnerlocations: any = [];
  partnercardsetupdata: any = [];
  cardlocationcalled = 0;
  cardlocationreceived = 0; 
  locationerror: boolean = false;
  constructor(
    private partnercreationService: PartnercreationService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: NgbModal,
    private contentful: ContentfulService,
  ) { }

  ngOnInit() {
    this.getData();
    this.partnerobj["industry"] = "";
    let partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
    this.commonService.scrollTop();
    this.commonService.getPartnerData(partnerId).subscribe(data => {
      this.partnerobj = data;
      this.partnerobj["industry"] = data.industry;
      if (this.partnerobj.extra_data.draft_logo) {
        this.logoArray.push({
          "image_url": this.partnerobj.extra_data.draft_logo,
          "image": this.partnerobj.extra_data.draft_logo
        });
      }
      if (this.partnerobj.extra_data.draft_header) {
        this.headerArray.push({
          "image_url": this.partnerobj.extra_data.draft_header,
          "image": this.partnerobj.extra_data.draft_header
        });
      }
    });
    this.commonService.getData("lookups", "INDUSTRY_TYPE").subscribe(result => {
      this.lookups["INDUSTRY_TYPE"] = result;
    });
  }

  mapClicked($event: google.maps.MouseEvent) {
    this.markers.push({
      latitude: $event.latLng.lat(),
      longitude: $event.latLng.lng(),
      location_code: null,
      address: '',
      location_name: '',
      draggable: true,
      open: true,
      location_timings: [],
      active: true,
      sponsor: this.partnerobj.id,
      location_type: "STR",
      location_category:'OFFLINE',
      area: '',
      city: '',
      country: '',
      state: '',
      card_setup_data: [],
      visa_acquirer_id: '',
      master_acquirer_id: '',
      master_card_id: null,
      visa_card_id: null,
      bin_id: null,
      network: ''
    });
  }

  showManualLocationAdd(fromupdate, index, locationmodal) {
    this.error = false;
    this.errorMsg = "";
    this.modalerror = false;
    this.mapobj.selectedlocindex = '';
    if (fromupdate) {
      if(this.markers[index].card_setup_data.length === 2){
        this.isvisascheme = true;
        this.ismasterscheme = true;
        this.cardLinkRequest = this.markers[index].card_setup_data;
      } else if(this.markers[index].card_setup_data[0].network === 'visa'){
        this.isvisascheme = true;
        this.ismasterscheme = false;
        this.cardLinkRequest[0] = this.markers[index].card_setup_data[0];
      } else if(this.markers[index].card_setup_data[0].network === 'mastercard'){
         this.ismasterscheme = true;
         this.isvisascheme = false;
        this.cardLinkRequest[1] = this.markers[index].card_setup_data[0];
      }

      this.mapobj.updatelocation = true;
      this.mapobj.selectedlocindex = index;
      let loc = this.markers[index];
      this.mapobj.address = loc.address;
      this.mapobj.location_name =  loc.location_name;
      this.placeMarker(loc, true, false, locationmodal);
      if (loc.location_timings.length < 1) {
        this.getOpeningHours(loc, loc.latitude, loc.longitude, false, false, locationmodal);
      } else {
        this.mapobj.manuallocation = true;
        this.marker[0] = loc;
      }
      this.scrollToPostion('map-cont');
    } else {
      this.mapobj.updatelocation = false;
      this.mapobj.manuallocation = true;
    }
  }

  hideManualLocationAdd() {
    this.resetCardlocation();
    this.isvisascheme = false;
    this.ismasterscheme  = false; 
    this.mapobj.manuallocation = false;
    this.mapobj.lat = 25.2048;
    this.mapobj.lng = 55.2708;
    this.mapobj.address = '';
    this.mapobj.location_name = '';
    this.marker = [];
    if (document.getElementsByClassName("pac-container").item(0)) {
      document.getElementsByClassName("pac-container").item(0).remove();
    }
  }

  uploadImage(e, fromheader) {
    let array = [];
    if (e) {
      if (fromheader) {
        this.headerArray = [];
        array = this.headerArray;
      } else {
        this.logoArray = [];
        array = this.logoArray;
      }
    }
    this.commonService.addDocument(this.partnerobj.id, e, array, 5242880, '', this.errorObj, '');
  }

  checkActiveLocations(locationmodal) {
    this.loader = true;
    if (this.markers.length > 0) {
      this.mapobj.locations = [];
      this.mapobj.count = this.markers.length;
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].active) {
          if (this.markers[i].location_timings.length < 1) {
            this.getOpeningHours(this.markers[i], this.markers[i].latitude, this.markers[i].longitude, true, false, locationmodal);
          } else {
            this.checkOpeningHours(this.markers[i]);
          }
        } else {
          this.mapobj.count--;
        }
        if (this.mapobj.count === 0) {
          this.errorMsg = "Add atleast one location";
          this.error = true;
          this.commonService.scrollTop();
          this.loader = false;
        }
      }
    }
    else {
      this.errorMsg = "Add atleast one location";
      this.error = true;
      this.commonService.scrollTop();
      this.loader = false;
    }
  }

  checkOpeningHours(location) {
    let selectedloc = cloneDeep(location);
    delete selectedloc.location_timings;
    selectedloc.location_timings = [];
    for (let i = 0; i < location.location_timings.length; i++) {
      selectedloc.location_code = null;
      if (location.location_timings[i].active && location.location_timings[i].close_time !== '' && location.location_timings[i].open_time !== '') {
        selectedloc.location_timings.push(location.location_timings[i]);
      }
    }
    if (selectedloc.location_timings.length === 0) {
      this.mapobj.count--;
    } else {
      this.mapobj.locations.push(selectedloc);
    }
    if (this.mapobj.locations.length === this.mapobj.count) {
      this.updateLocations();
    }
  }

  updateLocations() {
    this.partnerobj.extra_data.onboarding_step_completed = 5;
    this.error = false;
    this.partnercreationService
      .addPartnerLocations(this.mapobj.locations, this.partnerobj.id)
      .then((data: any) => {
        //this.updatePartner();
        this.cardlocationcalled = 0;
        for(var locdata=0; locdata<data.body.length; locdata++){
          this.cardlocationcalled++;
          this.createLocationCards(this.mapobj.locations[locdata].card_setup_data, data.body[locdata].id);
        }
      })
      .catch(error => {
        this.loader = false;
        this.showErrorMsg(error);
      });
  }

  createLocationCards(cardsreqdata, locid){
    if(cardsreqdata.length === 2){
      cardsreqdata[0].sponsor_location = locid;
      cardsreqdata[1].sponsor_location = locid;
    } else {
      cardsreqdata[0].sponsor_location =locid;
    }
    this.partnercreationService
       .createLocationCard(this.partnerobj.id, locid, cardsreqdata)
       .then((data: any) => {
         this.cardlocationreceived++;
         if(this.cardlocationcalled === this.cardlocationreceived){
           this.updatePartner();
         }
       });
  }

 

  updatePartner() {
    this.error = false;
    this.partnerobj.extra_data.draft_logo = (this.logoArray[0] !== undefined) ? this.logoArray[0].image_url : '';
    this.partnerobj.extra_data.draft_header = (this.headerArray[0] !== undefined) ? this.headerArray[0].image_url : '';
    if (!this.partnerobj.extra_data.sponsor_pin_threshold) {
      this.partnerobj.extra_data.sponsor_pin_threshold = 0;
    }
    delete this.partnerobj.logo;
    delete this.partnerobj.header;
    delete this.partnerobj.description;
    this.partnercreationService
      .updatePartnerData(this.partnerobj.id, this.partnerobj)
      .then((data: any) => {
        this.loader = false;
        this.router.navigate(['/welcomepage']);
      })
      .catch(error => {
        this.loader = false;
        this.showErrorMsg(error);
      });
  }

  updateReceiptCheck() {
    var request = {
      "extra_data": {
        'receipt_check': this.partnerobj.extra_data.receipt_check
      }
    }
    this.error = false;
    this.partnercreationService
      .updateReceiptCheck(this.partnerobj.id, request)
      .then((data: any) => {
        this.enableReceiptCheck();
      })
      .catch(error => {
        this.showErrorMsg(error);
      });
  }

  showErrorMsg(error) {
    this.error = true;
    this.commonService.scrollTop();
    this.errorMsg = this.commonService.showCommonErrorMsg(error);
  }

  getAddressArray(address): any {
    let locationArray = [];
    let address_array = address.split(',');
    for (var i = 0; i < address_array.length; i++) {
      if (i === 3) {
        break;
      }
      locationArray.push(address_array[i].trim());
    }
    let location = locationArray.toString();
    if (location.length > 100) {
      location = location.slice(0, 99);
    }
    return location;
  }



  getPlacesFromGoogle() {
    this.error = false;
    this.errorMsg = "";
    let map;
    let service;
    let pyrmont = new google.maps.LatLng(this.mapobj.lat, this.mapobj.lng);
    this.mapobj.showloader = true;
    let scope = this;
    this.markers = [];
    map = new google.maps.Map(document.createElement('div'), {
      center: pyrmont,
      zoom: 15
    });
    var request = {
      location: pyrmont,
      radius: '1500',
      query: scope.partnerobj.name
    };
    service = new google.maps.places.PlacesService(map);
    service.textSearch(request, function(results, status, pagination) {
      pagination.nextPage();
      results.forEach(result => {
        result.locationArray = [];
        result.locationArray = scope.getAddressArray(result.formatted_address);
        scope.markers.push({
          latitude: JSON.parse(result.geometry.location.lat().toFixed(7)),
          longitude: JSON.parse(result.geometry.location.lng().toFixed(7)),
          label: result.formatted_address,
          location_code: result.place_id,
          address: result.formatted_address,
          location_name: result.locationArray,
          draggable: true,
          open: result.opening_hours ? result.opening_hours.open_now : false,
          location_timings: [],
          active: true,
          sponsor: scope.partnerobj.id,
          location_type: "STR",
          location_category:'OFFLINE',
          area: '',
          city: '',
          country: '',
          state: '',
          card_setup_data: [],
          visa_acquirer_id: '',
          master_acquirer_id: '',
          master_card_id: null,
          visa_card_id: null,
          bin_id: null,
          network: ''

        })
      });
      scope.mapobj.bounds = true;
      scope.mapobj.showloader = false;
    });
  }

  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen && gm.lastOpen.isOpen) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  onMouseOut(infoWindow, gm) {
    infoWindow.close();
  }

  setAddress(address, locationmodal) {
    if (address) {
      this.mapobj.weekarray = {};
      this.marker = [];
      address.locationArray = [];
      address.locationArray = this.getAddressArray(address.formatted_address);
      address.location = address.locationArray;
      this.mapobj.lng = JSON.parse(address.geometry.location.lng().toFixed(7));
      this.mapobj.lat = JSON.parse(address.geometry.location.lat().toFixed(7));
      this.mapobj.address = address.formatted_address;
      this.mapobj.location_name = address.locationArray;
      this.getOpeningHours(address, this.mapobj.lat, this.mapobj.lng, false, false, locationmodal);
    } else {
      this.mapobj.address = null;
      this.mapobj.location_name = null;
    }
  }

  openerrorsmodal(cont){
    this.open(cont, 'locationpayschemeserrorscont');
  }

  open(content, modalclass) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: modalclass
    };
    this.modalService.open(content, ngbModalOptions);
  }


  addUpdateLocation(fromUpdate, locationmodal) {
    if(this.cardLinkRequest[0].network !== '' && this.cardLinkRequest[1].network === ''){
      if(this.cardLinkRequest[0].acquirer_id === '' || this.cardLinkRequest[0].bin === ''){
        this.locationerror = true;
        this.openerrorsmodal(locationmodal);
      } else {
        this.locationerror = false;
      }
    } else if(this.cardLinkRequest[0].network === '' && this.cardLinkRequest[1].network !== ''){
      if(this.cardLinkRequest[1].acquirer_id === ''){
        this.locationerror = true;
        this.openerrorsmodal(locationmodal);
      } else {
        this.locationerror = false;
      }
    } else if(this.cardLinkRequest[0].network !== '' && this.cardLinkRequest[1].network !== ''){
      if(this.cardLinkRequest[0].acquirer_id !== '' && this.cardLinkRequest[0].bin !== '' && this.cardLinkRequest[1].acquirer_id !== '' ){
        this.locationerror = false;
      } else {
        this.locationerror = true;
        this.openerrorsmodal(locationmodal);
      }
    } else{
      this.locationerror = false;
    }
    
    if(this.locationerror === false){
      this.partnercreationService
        .addlocationsreqs(this.Locatreq)
        .then((data: any) => {
          //console.log(data);
          this.modalerror = false;
          this.errorMsg = '';
          this.marker[0].address = this.mapobj.address;
          this.marker[0].location_name = this.mapobj.location_name;
          this.marker[0].area = data.id;
          this.marker[0].city = data.city;
          this.marker[0].state = data.region;
          this.marker[0].country = data.country;
          this.marker[0].card_setup_data = [];
          if(this.cardLinkRequest[0].network !== ''){
            this.marker[0].card_setup_data.push(this.cardLinkRequest[0]);
          } 
          if(this.cardLinkRequest[1].network !== ''){
            this.marker[0].card_setup_data.push(this.cardLinkRequest[1]);
          }
          if(this.cardLinkRequest[0].network  === 'visa'){
            this.marker[0].visa_acquirer_id = this.cardLinkRequest[0].acquirer_id;
            this.marker[0].bin_id = this.cardLinkRequest[0].bin;
          }
          if(this.cardLinkRequest[1].network  === 'mastercard'){
            this.marker[0].master_acquirer_id = this.cardLinkRequest[1].acquirer_id;
          }

          if(this.marker[0].card_setup_data.length === 2){
            this.marker[0].network = 'VISA/MasterCard';
          } else if(this.cardLinkRequest[0].network !== ''){
            this.marker[0].network = 'VISA';
          } else if(this.cardLinkRequest[1].network !== ''){
            this.marker[0].network = 'MasterCard';
          }
          
          let count = 0;
          for (let i = 0; i < this.marker[0].location_timings.length; i++) {
            if (this.marker[0].location_timings[i].active && this.marker[0].location_timings[i].open_time && this.marker[0].location_timings[i].close_time) {
              count++;
            }
          }
          if (count > 0) {
            if (fromUpdate) {
              this.markers[this.mapobj.selectedlocindex] = this.marker[0];
            } else {
              this.markers.push(this.marker[0]);
              this.mapobj.lat = null;
              this.mapobj.lng = null;
            }
            this.mapobj.address = "";
            this.mapobj.location_name = "";
            this.marker = [];
            document.getElementsByClassName("pac-container").item(0).remove();
            this.scrollToPostion('loc-list');
            this.resetCardlocation();
            this.hideManualLocationAdd();
          } else {
            this.modalerror = true;
            this.errorMsg = this.translate.instant('Location_timings_error');
          }
        }).catch(error => {
            this.showErrorMsg(error);
          });
    }
  }

  resetCardlocation(){
    this.cardLinkRequest = [{
      "sponsor_location": null,
      "network": "",
      "acquirer_id": "",
      "terminal_code": null,
      "bin": ""
    }, {
      "sponsor_location": null,
      "network": "",
      "acquirer_id": "",
      "terminal_code": null,
    }];
  }

  scrollToPostion(id) {
    setTimeout(function() {
      let position = document.getElementById(id).offsetTop;
      window.scrollTo(0, position);
    }, 50);
  }

  checkTimings(weekday, marker) {
    if (this.mapobj.weekarray[weekday] && this.mapobj.weekarray[weekday].length > 0) {
      let length = this.mapobj.weekarray[weekday].length;
      if (this.mapobj.weekarray[weekday][0].open && this.mapobj.weekarray[weekday][0].open.minutes === 0) {
        this.mapobj.weekarray[weekday][0].open.minutes = '00';
      }
      if (this.mapobj.weekarray[weekday][0].open && this.mapobj.weekarray[weekday][0].open.hours < 10 && this.mapobj.weekarray[weekday][0].open.hours.toString().length === 1) {
        this.mapobj.weekarray[weekday][0].open.hours = '0' + this.mapobj.weekarray[weekday][0].open.hours;
      }
      if (this.mapobj.weekarray[weekday][0].close && this.mapobj.weekarray[weekday][0].close.minutes === 0) {
        this.mapobj.weekarray[weekday][0].close.minutes = '00';
      }
      if (this.mapobj.weekarray[weekday][0].close && this.mapobj.weekarray[weekday][0].close.hours < 10 && this.mapobj.weekarray[weekday][0].close.hours.toString().length === 1) {
        this.mapobj.weekarray[weekday][0].close.hours = '0' + this.mapobj.weekarray[weekday][0].close.hours;
      }
      marker.location_timings.push({
        'open_time': this.mapobj.weekarray[weekday][0].open ? this.mapobj.weekarray[weekday][0].open.hours + ":" + this.mapobj.weekarray[weekday][0].open.minutes : '00:00',
        'close_time': this.mapobj.weekarray[weekday][length - 1].close ? this.mapobj.weekarray[weekday][length - 1].close.hours + ":" + this.mapobj.weekarray[weekday][length - 1].close.minutes : '23:59',
        'day': this.mapobj.weekarray[weekday][0].open.day !== 0 ? this.mapobj.weekarray[weekday][0].open.day : 7,
        'active': true
      });
    } else {
      marker.location_timings.push({
        'open_time': '00:00',
        'close_time': '23:59',
        'day': weekday !== 0 ? weekday : 7,
        'active': true
      });
    }
  }

  placeMarker(event, fromUpdate, fromadd, locationmodal) {
    if (fromUpdate) {
      this.mapobj.lat = JSON.parse(event.latitude);
      this.mapobj.lng = JSON.parse(event.longitude);
    } else {
      if(fromadd){
        this.mapobj.lng = JSON.parse(event.geometry.location.lng().toFixed(7));
        this.mapobj.lat = JSON.parse(event.geometry.location.lat().toFixed(7));
      } else{
        this.mapobj.lat = JSON.parse(event.coords.lat.toFixed(7));
        this.mapobj.lng = JSON.parse(event.coords.lng.toFixed(7));
      }
      this.mapobj.weekarray = {};
      this.getAddress(this.mapobj.lat, this.mapobj.lng, locationmodal);
    }
  }

  disableLocation(index) {
    this.marker[0].location_timings[index].active = !this.marker[0].location_timings[index].active;
  }

  getAddress(lat, lng, locationmodal) {
    let scope = this;
    this.mapobj.geocoder = new google.maps.Geocoder();
    scope.Locatreq = {  
                        "area_name": '',
                        "city_name": '',
                        "country_name": '',
                        "region_name": '' 
                     }; 
    let latlng = new google.maps.LatLng(lat, lng);
    this.mapobj.geocoder.geocode({
      'latLng': latlng
    }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[1]){
          for(var locres=0; locres<results[1].address_components.length; locres++){
            let addtype = results[1].address_components[locres].types[0];
            if(addtype === 'country') {
                scope.Locatreq["country_name"] = results[1].address_components[locres]['long_name'];
            }
            if(addtype === 'locality' ||  addtype=== 'administrative_area_level_2' ) {
                scope.Locatreq["city_name"] = results[1].address_components[locres]['long_name'];
            }
            if(addtype === 'administrative_area_level_1') {
                scope.Locatreq["region_name"] = results[1].address_components[locres]['long_name'];
            }
            if(addtype === 'sublocality_level_1' || results[1].address_components[locres].types[1] === 'sublocality_level_1' || results[1].address_components[locres].types[2] === 'sublocality_level_1') {
                scope.Locatreq["area_name"] = results[1].address_components[locres]['long_name'];
            }
          }
          scope.mapobj.address = results[1].formatted_address;
          if(scope.Locatreq["area_name"] === '' || scope.Locatreq["area_name"] === undefined){
            scope.Locatreq["area_name"] = scope.Locatreq["city_name"];
          }
          results[1].locationArray = [];
          results[1].locationArray = scope.getAddressArray(results[1].formatted_address);
          results[1].location = results[1].locationArray;
          scope.mapobj.location_name = results[1].location;
          scope.getOpeningHours(results[1], scope.mapobj.lat, scope.mapobj.lng, false, false, locationmodal);
        }
      }
    });
  }

  getOpeningHours(address, lat, lng, fromUpdate, fromTimings, locationmodal) {
    let map, countt = 0;
    let service;
    let pyrmont = new google.maps.LatLng(lat, lng);
    this.marker = [];
    this.mapobj.weekarray = {};
    map = new google.maps.Map(document.createElement('div'), {
      center: pyrmont,
      zoom: 15
    });
    var request = {
      placeId: address.place_id ? address.place_id : address.location_code,
      fields: ['opening_hours']
    };
    var scope = this;
    var count = 0;
    service = new google.maps.places.PlacesService(map);
    service.getDetails(request, function(place, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        let mark;
        if (!fromUpdate) {
          scope.marker.push({
            latitude: address.latitude ? address.latitude.toFixed(7) : lat.toFixed(7),
            longitude: address.longitude ? address.longitude.toFixed(7) : lng.toFixed(7),
            label: address.formatted_address ? address.formatted_address : address.address,
            location_code: address.place_id ? address.place_id : address.location_code,
            address: address.formatted_address ? address.formatted_address : address.address,
            location_name: address.location ? address.location : address.location_name,
            draggable: true,
            open: place.opening_hours ? place.opening_hours.open_now : false,
            location_timings: [],
            active: true,
            sponsor: scope.partnerobj.id,
            location_type: "STR",
            location_category:'OFFLINE',
            area: '',
            city: '',
            country: '',
            state: '',
            card_setup_data: [],
            visa_acquirer_id: '',
            master_acquirer_id: '',
            master_card_id: null,
            visa_card_id: null,
            bin_id: null,
            network: ''
          });
          mark = scope.marker[0];
        } else {
          mark = address;
        }
        for (let k = 0; k < 7; k++) {
          scope.mapobj.weekarray[k] = [];
        }
        if (place.opening_hours && place.opening_hours.periods.length > 0) {
          for (let i = 0; i < place.opening_hours.periods.length; i++) {
            scope.mapobj.weekarray[place.opening_hours.periods[i].open.day].push(place.opening_hours.periods[i]);
          }
        }
        for (let j = 0; j < 7; j++) {
          scope.checkTimings(j, mark);
        }

        if (fromUpdate) {
          scope.checkOpeningHours(address);
        }
        if (scope.mapobj.updatelocation) {
          scope.mapobj.manuallocation = true;
        }
        if (fromTimings) {
          scope.markers[scope.mapobj.selectedlocindex].location_timings = scope.marker[0].location_timings;
        }
      }
      if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
        setTimeout(function() {
          scope.getOpeningHours(address, lat, lng, fromUpdate, fromTimings, locationmodal);
        }, 2500);
      }
    });
  }

  setIndex(event, location, index, locationmodal) {
    if (location.location_timings.length === 0 && this.mapobj.selectedlocindex !== index) {
      location.latitude = JSON.parse(JSON.parse(location.latitude).toFixed(6));
      location.longitude = JSON.parse(JSON.parse(location.longitude).toFixed(6));
      this.mapobj.selectedlocindex = index;
      this.getOpeningHours(location, location.latitude, location.longitude, false, true, locationmodal);
    }
  }

  resetIndex() {
    this.mapobj.selectedlocindex = '';
    this.marker = [];
  }

  // updateLocation() {
  //   this.markers[this.mapobj.selectedlocindex] = this.marker[0];
  //   this.mapobj.address = "";
  //   this.marker = [];
  //   this.scrollToPostion('loc-list');
  // }

  activateDeactivateLocation(allloc, index) {
    if (allloc) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].active = this.checked;
      }
    } else {
      this.markers[index].active = !this.markers[index].active;
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  showRemoveLocationConfirmation(locationsresetmodal) {
    if (this.markers.length > 0) {
      this.modalService.open(locationsresetmodal, { windowClass: 'locationsResetModal' });
    } else {
      this.getPlacesFromGoogle();
    }
  }
  getData() {
    let contentFulIds = this.stepFiveIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      this.header.getData(dataobj);
      this.modalData = dataobj;
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys": {
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
          this.stepFiveCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }

      }
    });

  }

  enableReceiptCheck() {
    let request = {
      "sponsor_id": this.partnerobj.id,
      "bit_category": "SPEND",
      "bit_type": "Accrual",
      "mandatory_attributes": ["h_bit_source_generated_id"]
    };
    if (this.partnerobj.extra_data.receipt_check === false) {
      request.mandatory_attributes = [];
    }
    this.commonService.enableReceiptCheck(this.partnerobj.id, request).subscribe(data => {
    });
  }
  discardError() {
    this.modalerror = false;
    this.errorMsg = '';
  }

  selectScheme(scheme){
    this.schemeselected = scheme;
    if(scheme === 'VISA'){
      this.isvisascheme = !this.isvisascheme;
      if(this.isvisascheme){
        this.cardLinkRequest[0].network = 'visa';
      } else {
        this.cardLinkRequest[0].network = '';
        this.cardLinkRequest[0].acquirer_id = '';
        this.cardLinkRequest[0].bin = '';
      }
    } else if(scheme === 'MASTERCARD'){
      this.ismasterscheme = !this.ismasterscheme;
      if(this.ismasterscheme){
        this.cardLinkRequest[1].network = 'mastercard';
      } else {
        this.cardLinkRequest[1].network = '';
        this.cardLinkRequest[1].acquirer_id = '';
      }
    }
  }


  getLocation(input, event, locationmodal) {
    let a = document.getElementsByClassName("pac-container");
    if (a[0].classList.value.indexOf('hide') > -1) {
      a[0].classList.remove('hide');
    }
    if (event.keyCode === 13) {
      a[0].classList.add('hide');
      let loc = new google.maps.LatLng(this.mapobj.lat, this.mapobj.lng);
      let scope = this;
      let map = new google.maps.Map(document.createElement('div'), {
        center: loc,
        zoom: 15
      });
      var request = {
        location: loc,
        radius: 1500,
        query: input
      };
      let service = new google.maps.places.PlacesService(map);
      service.textSearch(request, function(results, status) {
        scope.setAddress(results[0], locationmodal);
      });
    }
  }

  checkNumber(event) {
    return this.commonService.checkNumber(event);
  }
}