import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as HC_exporting from 'highcharts/modules/exporting';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';


declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
	selector: 'app-output-graph',
	templateUrl: './output-graph.component.html',
	styleUrls: ['./output-graph.component.less']
})
export class OutputGraphComponent implements OnInit {
	@Input() xaxis: any;
	@Input() yaxis: any;
	@Input() type: any;
	@Input() chartData: any;
	@Input() name: string;
	@Input() colors: any;
	@Input() id: string;
	@Input() charts: any;
	@Input() title: any;
	@Input() tooltip: any;
	public options: any = {
	}
	subscription: Subscription;
	constructor(private http: HttpClient) { }

	ngOnInit() {
		let name = this.name;
		let dataLabels = (this.type === 'column') ? {
			enabled: true,
			color: '#2D2926',
			style: {
				textOutline: false
			},
			align: 'center',
			x: this.xaxis.intervalInfo =='month' ? 3:-1,
			y: 40,
			rotation: 0
		} : {};
		let scope = this;
		this.options = {
			chart: {
				type: this.type,
				height: 175,
				events: {
			      render: function() {
			      	if(scope.type === 'column'){
			      		var tick0 = this.yAxis[0].toPixels(0, true);
				        Highcharts.each(this.series[0].points, function(p) {
				          if (p.y < 0) {
				            p.dataLabel.attr({
				              y: tick0 - 17
				            });
				          } 
				        });
			      	}
			        
			      }
			    }
			},
			colors: this.colors,
			title: {
				text: this.title
			},
			tooltip: this.tooltip,
			credits: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			xAxis: this.xaxis,
			yAxis: this.yaxis,
			plotOptions: {
				areaspline: {
					fillOpacity: 0.5
				}
			},
			exporting: {
				csv: {
					dateFormat: '%b-%y'
				},
				buttons: {
					contextButton: {
						enabled: false
					}
				},
				fallbackToExportServer: true
			},
			series: [
				{
					name: name,
					turboThreshold: 500000,
					data: this.chartData,
					dataLabels: dataLabels
				}
			]
		}

		Highcharts.chart(this.id, this.options);
		if (this.charts !== undefined) {
			if (this.id === 'miles-awarded-container') {
				this.charts[0] = Highcharts.chart(this.id, this.options);
			}
			if (this.id === 'asociated-spend-container') {
				this.charts[1] = Highcharts.chart(this.id, this.options);
			}
		}

	}
}