import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: "root"
})
export class PartnercreationService {
  constructor(private webService: WebService) { }
  private host = environment.host();
  private hostv2 = environment.hostv2();
  createPartner(request) {
    let requestUrl = this.host + "sponsors/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  partnerLogin(request) {
    let requestUrl = this.host + "login/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  updatePartnerData(partnerId, request) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/";
    return this.webService.patchAPI(requestUrl, request, true);
  }
  getPartnerData(partnerId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/";
    return this.webService.getAPI(requestUrl, false);
  }
  updatePartner(partnerId, request) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/";
    return this.webService.putAPI(requestUrl, request, true);
  }
  resetPassword(request) {
    let requestUrl = this.host + "accounts/reset-password/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  doResetPassword(request) {
    let requestUrl = this.host + "accounts/do-reset-password/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  changePassword(request) {
    let requestUrl = this.host + "accounts/change-password/";
    return this.webService.putAPI(requestUrl, request, true);
  }
  signedgetAPI(request, partnerId) {
    let requestUrl = this.host + "signature/sponsor/"+ partnerId + "/embed/?document_template=" + request;
    return this.webService.getAPI(requestUrl, false);
  }
  getPartnerDocuments(partnerId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/documents/";
    return this.webService.getAPI(requestUrl, false);
  }
  uploadDocument(partnerId, request) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/documents/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  uploadImage(partnerId, request) {
    let requestUrl = this.host + "sign-s3/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  deleteDocument(partnerId, documentId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/documents/" + documentId + "/";
    return this.webService.deleteAPI(requestUrl, true);
  }
  downloadNDADocument(request, partnerId) {
    let requestUrl = this.host + "signature/sponsor/"+ partnerId +"/pdf/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  downloadPartnerInvoice(invoiceId,sponsorId){
    let requestUrl = this.host +"sponsors/"+sponsorId+"/invoices/"+invoiceId +"/export/";
    return this.webService.getAPI(requestUrl,false);
  }

  
  getTransactions(startdate, enddate, cursor, partnerId, receiptId, memberId, datesort,location_code) {
    let requestUrl = this.host + "bits/?page_size=12&bit_category=ACCRUAL&status=SUCCESS" + "&sponsor_id=" + partnerId;
    let receiptstring = (receiptId !== '' && receiptId !== undefined) ? ('&h_bit_source_generated_id=' + receiptId) : "";
    let memberstring = (memberId !== '' && memberId !== undefined) ? ('&member_id=' + memberId) : "";
    let cursorstr = (cursor !== '' && cursor !== undefined) ? ('&cursor=' + cursor) : "";
    let location_code_string = (location_code !== '' && location_code !== undefined) ? ('&location=' + location_code) : "";
    if (receiptId !== undefined && receiptId !== '') {
      requestUrl += receiptstring;
    }
    else if (memberId !== undefined && memberId !== '') {
      requestUrl += memberstring;
    }
    else if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    else if(location_code_string !=undefined && location_code_string!==''){
      requestUrl +=location_code_string;
    }
    if (startdate !== undefined && startdate !== '' && startdate !== null) {
      requestUrl += ('&date_from=' + startdate);
    }
    if (enddate !== undefined && enddate !== '' && enddate !== null) {
      requestUrl += ('&date_to=' + enddate);
    }
    if (datesort) {
      requestUrl += '&ordering=h_bit_date';
    } else {
      requestUrl += '&ordering=-h_bit_date';
    }
    return this.webService.getAPI(requestUrl, true);
  }

  getInvoiceTransactions(cursor, invoiceid, partnerid) {
    let requestUrl = this.host + "sponsors/" + partnerid + "/invoices/" + invoiceid + "/bits/?bit_category=ACCRUAL&status=SUCCESS";
    let cursorstr = (cursor !== '' && cursor !== undefined) ? ('&cursor=' + cursor) : "";
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    return this.webService.getAPI(requestUrl, true);
  }

  getInvoiceDetails(invoiceId, partnerid) {
    let requestUrl = this.host + "sponsors/" + partnerid + "/invoices/" + invoiceId + "/";
    return this.webService.getAPI(requestUrl, false);
  }
  downloadTransactions(partnerId, startdate, enddate, type) {
    let requestUrl = this.hostv2 + "bit/export/" + type + "/?sponsor_id=" + partnerId + '&date_from=' + startdate + '&date_to=' + enddate + '&status=SUCCESS&bit_category=ACCRUAL,CANCELLATION&ordering=-h_bit_date' ;
    return this.webService.getAPI(requestUrl, false);
  }

  downloadTransactionsEkSpecificAPI(partnerId, startdate, enddate, type) {
    let requestUrl = this.hostv2 + "bit/export/" + type + "/?sponsor_id=" + partnerId + '&processed_date_from=' + startdate + '&processed_date_to=' + enddate + '&status=SUCCESS&bit_category=ACCRUAL,CANCELLATION&ordering=-h_bit_date' ;
    return this.webService.getAPI(requestUrl, false);
  }

  downloadInvoiceTransactions(invoiceId, type, partnerId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/invoices/" + invoiceId + "/bits/" + type + "/";
    return this.webService.getAPI(requestUrl, false);
  }

  getInvoices(cursor, partnerId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/invoices/?page_size=11&ordering=-issue_date";
    let cursorstr = (cursor !== '' && cursor !== undefined) ? ('&cursor=' + cursor) : "";
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    return this.webService.getAPI(requestUrl, true);
  }
  getPayments(cursor, sponsorId) {
    let requestUrl = this.host + "sponsors/" + sponsorId + "/payments/?page_size=11";
    let cursorstr = (cursor !== '' && cursor !== undefined) ? ('&cursor=' + cursor) : "";
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    return this.webService.getAPI(requestUrl, true);
  }

  getPaymentsToDownload(sponsorId,start_date,end_date){
    let requestUrl = this.host + "sponsors/" + sponsorId + "/payments/?start_date=" + start_date+ "&end_date=" + end_date; 
    return this.webService.getAPI(requestUrl, false);
  }

  addPartnerLocations(request, partnerId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/';
    return this.webService.postAPI(requestUrl, request, true);
  }
  addlocationsreqs(request){
    let requestUrl = this.host + 'location/';
    return this.webService.postAPI(requestUrl, request, false);
  }
  getPartnerLocations(partnerId) {
    // let cursorUrl;
    // if (cursor) {
    //   cursorUrl = '&cursor=' + cursor;
    // } else {
    //   cursorUrl = '';
    // }
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/?active=True&page_size=5000';
    return this.webService.getAPI(requestUrl, true);
  }
  getPartnerLocationsWithoutPagination(partnerId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/?active=True&page_size=300';
    return this.webService.getAPI(requestUrl, true);
  }
  resetAuthPin(partnerId, locId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/' + locId + '/pin/';
    return this.webService.putAPI(requestUrl, '', true);
  }
  supervisorPinReset(partneObj, request) {
    let requestUrl = this.host + 'sponsors/' + partneObj.id + '/pin/';
    return this.webService.putAPI(requestUrl, request, false);
  }
  getSupervisorPin(id) {
    let requestUrl = this.host + 'sponsors/' + id + '/pin/';
    return this.webService.getAPI(requestUrl, true);
  }
  deactivateLocation(partneObj, locationObj) {
    let requestUrl = this.host + 'sponsors/' + partneObj.id + '/locations/' + locationObj.id + "/";
    return this.webService.putAPI(requestUrl, locationObj, true);
  }

  updateLocation(request, partnerId, locationId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/locations/' + locationId + '/';
    return this.webService.putAPI(requestUrl, request, true);
  };
  goLive(request, partnerId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/stage-event/';
    return this.webService.postAPI(requestUrl, request, true);
  };
  retrieveUsername(licenseId, email, brandname) {
    let requestUrl = this.host + 'sponsors/logininfo/?tradelicensenumber=' + licenseId + '&email_address=' + email + '&brand_name=' + brandname;
    return this.webService.getAPI(requestUrl, false);
  }
  statusChange(request, partnerId) {
    let requestUrl = this.host + 'signature/sponsor/'+ partnerId +'/update-status/';
    return this.webService.patchAPI(requestUrl, request, true);
  }
  updateReceiptCheck(partnerId, request) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/";
    return this.webService.patchAPI(requestUrl, request, true);
  }
  resendVerificationLink(partnerId, request) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/verification/';
    return this.webService.postAPI(requestUrl, request, false);
  }
  getBillingConfig(partnerId) {
    let requestUrl = this.host + 'sponsors/' + partnerId + '/billing-config/';
    return this.webService.getAPI(requestUrl, false);
  }
  getOfferDetails(offerId) {
    let requestUrl = this.host + "offers/" + offerId + "/";
    return this.webService.getAPI(requestUrl, false);
  }
  refreshToken(request) {
    let requestUrl = this.host + "jwt/refresh-token/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  createTransaction(request) {
    let requestUrl = this.host + 'bit/';
    return this.webService.postAPI(requestUrl, request, false);
  }
  getTaxes() {
    let requestUrl = this.host + "sponsors/taxes/";
    return this.webService.getAPI(requestUrl, false);
  }
  updateBillingConfig(partnerId, request) {
    if(request.id !== undefined && request.id !== null && request.id !== ''){
      let requestUrl = this.host + 'sponsors/' + partnerId + '/billing-config/' + request.id;
      return this.webService.putAPI(requestUrl, request, false);
    }else{
      let requestUrl = this.host + 'sponsors/' + partnerId + '/billing-config/';
      return this.webService.postAPI(requestUrl, request, false);
    }
  }
  getLocationData(locationcode, partnerId) {
    let requestUrl = this.host + "sponsors/" + partnerId + "/locations/?active=true&search=" + locationcode;
    return this.webService.getAPI(requestUrl, false);
  }
  getProgramData() {
    let requestUrl = this.host + "programs/";
    return this.webService.getAPI(requestUrl, false);
  }
  getMemberId(id) {
    let requestUrl = this.host + "members/list/?extra_data[member_identifier]=" + id;
    return this.webService.getAPI(requestUrl, false);
  }
  signedpostAPI(request, partnerId) {
    let requestUrl = this.host + "signature/sponsor/"+ partnerId + "/embed/";
    return this.webService.postAPI(requestUrl, request, false);
  }

  updateUserInfo(id, request) {
    let requestUrl = this.host + "systemusers/" + id + '/';
    return this.webService.patchAPI(requestUrl, request, true);
  }
  getLoyaltyAccounts(){
    let requestUrl = this.host + "loyaltyaccounts/";
    return this.webService.getAPI(requestUrl, false);
  }
  getloyaltyaccounttags(id){
    let requestUrl = this.host + "loyaltyaccounts/" + id + "/tags/?active=true";
    return this.webService.getAPI(requestUrl, false);
  }
  
  createLocationCard(sponsorId,location_id, request){
    let requestUrl = this.host + "sponsors/" +  sponsorId + "/locations/" + location_id + "/card-link-setup/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  getlocationcarddatails(sponsorId, locationid){
    let requestUrl = this.host + "sponsors/" + sponsorId + "/locations/" + locationid + "/card-link-setup/";
    return this.webService.getAPI(requestUrl, false);
  }
  getSponsorLocations(searchTerm){
    let requestUrl = this.host + "sponsors/locations?location_name="+searchTerm;
    return this.webService.getAPI(requestUrl, false);
  }

  getSignedDocuments(partnerId){
    let requestUrl = this.host + "sponsors/" + partnerId + "/signed-documents/";
    return this.webService.getAPI(requestUrl, false);
  }
  getKYCDocuments(partnerId){
    let requestUrl = this.host + "sponsors/kyc/" +partnerId;
    return this.webService.getAPI(requestUrl, false);
  }

  getAllUserRoles(){
    let requestUrl = this.host + "roles/?page_size=500";
    return this.webService.getAPI(requestUrl, false);
  }

  getSystemUserDataUsingId(userId){
    let requestUrl = this.hostv2 + "systemusers/" + userId + "/";
    return this.webService.getAPI(requestUrl, false);
  }

  getActiveOrInactiveSystemUsers(active:boolean,cursor){
    let requestUrl = this.hostv2 + "systemusers/?active=" + ((active  && active == true) ? 'True' : 'False');
    let cursorstr =  (cursor !== undefined &&cursor !== '') ? ('&cursor=' + cursor) : "";
    if(cursorstr && cursorstr != ''){
      requestUrl = requestUrl + cursorstr;
    }
    return this.webService.getAPI(requestUrl, true);
  }

  markUserActiveOrInactive(userReq,userId){
    let requestUrl = this.hostv2 + "systemusers/" + userId + "/";
    return this.webService.putAPI(requestUrl, userReq, false);
  }

  updateSystemUserDetails(userReq,userId){
    let requestUrl = this.hostv2 + "systemusers/" + userId + "/";
    return this.webService.putAPI(requestUrl, userReq, false);
  }

  inviteASystemUser(userReq){
    let requestUrl = this.hostv2 + "systemusers/";
    return this.webService.postAPI(requestUrl, userReq, false);
  }
}