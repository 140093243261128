import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import * as _ from "lodash";
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-transactions',
	templateUrl: './transactions.component.html',
	styleUrls: ['./transactions.component.less'],
	host: {
		'(document:click)': 'onClick($event)',
	}
})
export class TransactionsComponent implements OnInit {
	noTransactions: boolean = false;
	step: string = 'transactions';
	error: boolean = false;
	errorMsg: string;
	transactions = [];
	selected: string = 'week';
	next: string = "None";
	prev: string = "None";
	dowloadmenu: boolean = false;
	partnerobj: any;
	refundflag: boolean = false;
	transactionobj: any;
	searchval: string;
	searchmenu: boolean = false;
	placeholder: string = 'Search by receipt ID';
	loader: boolean = true;
	datesort: boolean = false;
	from_date: any;
	to_date: any;
	programtimezone: string = "Asia/Dubai";
	noData: boolean = false;
	transHeaderImg: any;
	locale: string = 'en-US';
	transactionsloader: boolean = false;
	loyaltyAccounsData: any = [];
	loyaltyTagsData: any = [];
	gettagcalls= 0;
	fetechedtagdata = 0;
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private router: Router,
		private _eref: ElementRef,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private modalService: NgbModal,
		private contentful: ContentfulService
	) { }

	onClick(event) {
		if (event.target.className !== 'download-link' && event.target.className !== 'download-img') {
			this.hideDownloadMenu();
		}
		if (event.target.className !== 'down-arrow') {
			this.searchmenu = false;
		}
	}

	ngOnInit() {
		this.partnerobj = this.authenticationService.currentUserValue;
		this.getLoyaltyAccounts();
		this.getProgramData();
		if (this.route.snapshot.queryParamMap.get('partner')) {
			this.getPartnerData(parseInt(this.route.snapshot.queryParamMap.get('partner')));
		} else {
			this.getPartnerData(this.partnerobj.sponsors[0].id);
		}
		this.getData();
	}


	searchByval(val) {
		this.error = false;
		if (this.placeholder === 'Search by receipt ID') {
			this.getTransactions(this.selected, '', val, '','');
		} 
		else if(this.placeholder === 'Search by Participating Outlet'){
			if(this.searchval){
				this.partnercreationService.getSponsorLocations(this.searchval).then((sponsorData:any)=>{
				if(sponsorData && sponsorData.length > 0){
					var searchedLocationCode = sponsorData[0].location_code;
					this.getTransactions(this.selected,'','','',searchedLocationCode);
				}
				else{
					this.noData=true;
				}
			});
			}
			else{
				this.getTransactions(this.selected, '', '', val,'');
			}
			
		}
		else {
			this.noData = false;
			if (this.searchval) {
				this.partnercreationService.getMemberId(this.searchval)
					.then((data: any) => {
						if (data.length > 0) {
							this.getTransactions(this.selected, '', '', data[0].member_id,'');
						} else {
							this.noData = true;
						}
					})
					.catch(error => {
						this.error = true;
						this.errorMsg = this.commonService.showCommonErrorMsg(error);
					});
			} else {
				this.getTransactions(this.selected, '', '', val,'');
			}

		}

	}

	getPartnerData(partnerId) {
		this.error = false;
		this.partnercreationService
			.getPartnerData(partnerId)
			.then((data: any) => {
				this.partnerobj = data;
				//this.getTransactions('week', '', '', '');
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	partnerLogout() {
		this.authenticationService.logout();
	}

	showDownloadMenu() {
		this.dowloadmenu = true;
	}

	hideDownloadMenu() {
		this.dowloadmenu = false;
	}

	// downloadTransactions(type) {
	// 	this.transactionsloader = true;
	// 	this.error = false;
	// 	this.partnercreationService
	// 		.downloadTransactions(this.partnerobj.id, this.from_date, this.to_date, type)
	// 		.then((data: any) => {
	// 			this.downLoadFile(data);
	// 		})
	// 		.catch(error => {
	// 			this.error = true;
	// 			this.errorMsg = this.commonService.showCommonErrorMsg(error);
	// 		});
	// }

	// downLoadFile(data: any, filename: string = null) {
	// 	let a = document.createElement('a');
	// 	a.href = data.url;
	// 	document.body.appendChild(a);
	// 	a.click();
	// 	document.body.removeChild(a);
	// 	this.transactionsloader = false
	// }

	getTransactions(interval, cursor, receiptId, memberId,locationCode) {
		this.error = false;
		this.loader = true;
		this.selected = interval;
		this.noData = false;
		switch (interval) {
			case 'year':
				this.from_date = moment(new Date()).subtract(1, 'years').format('YYYY-MM-DD');
				this.to_date = moment(new Date()).format('YYYY-MM-DD');
				break;
			case 'month':
				this.from_date = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
				this.to_date = moment(new Date()).format('YYYY-MM-DD');
				break;
			case 'week':
				this.from_date = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD');
				this.to_date = moment(new Date()).format('YYYY-MM-DD');
				break;
			case 'today':
				this.from_date = moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD');
				this.to_date = moment(new Date()).format('YYYY-MM-DD');
				break;
			case 'all':
				this.from_date = '';
				this.to_date = '';
				break;
			default:
				break;
		}
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		this.partnercreationService
			.getTransactions(this.from_date, this.to_date, cursor, this.partnerobj.id, receiptId, memberId, this.datesort,locationCode)
			.then((data: any) => {
				this.transactions = data.body;
				for (var i = 0; i < this.transactions.length; i++) {
					this.transactions[i]['date'] = moment(data.body[i].h_bit_date).tz(this.programtimezone).format('DD MMM YYYY');
					this.transactions[i]['base_offer'] = {};
					this.transactions[i]['bonus_offer'] = {};
					this.transactions[i]['allmiledetails'] = []; 
					this.transactions[i]['total_miles'] = 0;
					this.transactions[i]['tooltip'] = false;

					this.transactions[i]['base_value'] = 0;
					this.transactions[i]['bonus_value'] = 0;
					for (var j = 0; j < this.transactions[i].offer_actions.length; j++) {
						if (this.transactions[i].offer_actions[j].reward_tags !== null && this.transactions[i].offer_actions[j].type === "REWARD_POINTS") {
							
							for(var loyt=0; loyt<this.loyaltyTagsData.length; loyt++){
								for(var tags=0; tags<this.loyaltyTagsData[loyt].length; tags++){
									if((this.loyaltyTagsData[loyt][tags].code === this.transactions[i].offer_actions[j].reward_tags[0]) && (this.transactions[i].offer_actions[j].reward_tags[0] !== 'HOST_BONUS') && (parseInt(this.transactions[i].offer_actions[j].subject) === this.loyaltyTagsData[loyt][tags].loyalty_account)){
										if(this.transactions[i]['allmiledetails'].length > 0){
										let index = _.findIndex(this.transactions[i]['allmiledetails'], {
													"code": this.loyaltyTagsData[loyt][tags].code 
												});
											if(index !== -1){
												this.transactions[i]['allmiledetails'][index].value = this.transactions[i]['allmiledetails'][index].value + this.transactions[i].offer_actions[j].value_n;
											} else {
												this.transactions[i]['allmiledetails'].push({'name': this.loyaltyTagsData[loyt][tags].name, 'code': this.loyaltyTagsData[loyt][tags].code, 'value': this.transactions[i].offer_actions[j].value_n});
											}
										} else{
											this.transactions[i]['allmiledetails'].push({'name': this.loyaltyTagsData[loyt][tags].name, 'code': this.loyaltyTagsData[loyt][tags].code, 'value': this.transactions[i].offer_actions[j].value_n});
										}
									}
									else if(this.transactions[i].offer_actions[j].offer_name && this.transactions[i].offer_actions[j].rule_id  !=='EK Base Offer' ){
										this.transactions[i]['bonus_offer']['offer_name'] = this.transactions[i].offer_actions[j].offer_name;
									}
								}
							}

						}

					}
					for(var totl=0; totl<this.transactions[i]['allmiledetails'].length; totl++){
						this.transactions[i]['total_miles'] = this.transactions[i]['total_miles'] + this.transactions[i]['allmiledetails'][totl].value;
					}
				}
				this.next = data.headers.get('next');
				this.prev = data.headers.get('prev');
				if (this.transactions.length > 0) {
					this.noTransactions = false;
				} else {
					this.noTransactions = true;
				}
				this.loader = false;
			})
			.catch(error => {
				this.loader = false;
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	getLoyaltyAccounts(){

		this.partnercreationService
			.getLoyaltyAccounts()
			.then((data: any) => {
				for(var loy=0; loy<data.length; loy++){
					if(data[loy].status === 'Active') {
						this.gettagcalls++;
						this.getloyaltyaccounttags(data[loy].id);
					}
				}
			})
			.catch(error => {

			});
	}

	getloyaltyaccounttags(id){
		this.loyaltyTagsData = [];
		this.partnercreationService
			.getloyaltyaccounttags(id)
			.then((data: any) => {
				this.fetechedtagdata++;
				this.loyaltyTagsData.push(data);
				if(this.gettagcalls === this.fetechedtagdata){
					this.getTransactions('week', '', '', '','');
				}
			})
			.catch(error => {

			});
	}

	showErrorMsg(error) {
		this.error = true;
		if (Object.keys.length > 0) {
			this.commonService.scrollTop();
			if (error.message) {
				this.errorMsg = error.message;
			} else {
				let Messages = Object.keys(error).map((key) => (error[key][0].message));
				this.errorMsg = Messages.toString();
			}

		}
	}

	showRefundModal(modalrefund, transactionobj) {
		this.error = false;
		transactionobj['date'] = moment(transactionobj.h_bit_date).tz(this.programtimezone).format('DD MMM YYYY');
		this.transactionobj = transactionobj;
		this.open(modalrefund, 'refundModal');
	}

	open(content, modalclass) {
		this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalService.dismissAll();
	}

	showDownloadList() {
		this.error = false;
		this.searchmenu = !this.searchmenu;
	}

	setPlaceholder(val) {
		this.error = false;
		this.getTransactions(this.selected, '', '', '','');
		this.searchval = "";
		this.placeholder = val;
	}

	getAllTransactions() {
		this.error = false;
		this.getTransactions(this.selected, '', '', '','');
	}

	getProgramData() {
		this.error = false;
		this.partnercreationService
			.getProgramData()
			.then((data: any) => {
				this.programtimezone = data[0].timezone;
			})
			.catch(error => {
				this.loader = false;
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	getData() {
		this.contentful.getContent('7JVMHH3aT9k2bzxZFdbcnA', this.locale).subscribe(dataobj => {
			this.transHeaderImg = dataobj['imageContent'].fields.file.url;
		});
	}
}
