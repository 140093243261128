import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-transactiondetails',
	templateUrl: './transactiondetails.component.html',
	styleUrls: ['./transactiondetails.component.less'],
	host: {
		'(document:click)': 'onClick($event)',
	}
})
export class TransactiondetailsComponent implements OnInit {
	noTransactions = false;
	step: string = 'transactions';
	error: boolean = false;
	errorMsg: string;
	transactions = [];
	next: string = "None";
	prev: string = "None";
	dowloadmenu: boolean = false;
	invoiceid: number = null;
	invoiceDetails: any;
	partnerobj: any;
	billingConfig: any;
	programtimezone: string = "Asia/Dubai";
	headerImage: any;
	locale: string = 'en-US';
	transactionsloader: boolean = false;
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private router: Router,
		private _eref: ElementRef,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private contentful: ContentfulService
	) { }

	onClick(event) {
		if (event.target.className !== 'download-link' && event.target.className !== 'download-img') {
			this.hideDownloadMenu();
		}
	}

	ngOnInit() {
		this.partnerobj = this.authenticationService.currentUserValue;
		this.invoiceid = parseInt(this.route.snapshot.queryParamMap.get('invoice'));
		this.getProgramData();
		this.getInvoiceDetails();
		this.getInvoiceTransactions('None');
		this.getBillingConfig(this.partnerobj.sponsors[0].id);
		this.getData();
	}


	getInvoiceTransactions(cursor) {
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		this.partnercreationService
			.getInvoiceTransactions(cursor, this.invoiceid, this.partnerobj.sponsors[0].id)
			.then((data: any) => {
				this.transactions = data.body;
				for (var i = 0; i < this.transactions.length; i++) {
					this.transactions[i]['date'] = moment(data.body[i].h_bit_date).tz(this.programtimezone).format('DD MMM YYYY');
					this.transactions[i]['base_offer'] = {};
					this.transactions[i]['bonus_offer'] = {};
					this.transactions[i]['base_value'] = 0;
					this.transactions[i]['bonus_value'] = 0;
					for (var j = 0; j < this.transactions[i].offer_actions.length; j++) {
						if (this.transactions[i].offer_actions[j].reward_tags !== null && this.transactions[i].offer_actions[j].reward_tags[0] === 'BASE') {
							this.transactions[i]['base_offer'] = this.transactions[i].offer_actions[j];
							this.transactions[i]['base_value'] = this.transactions[i]['base_value'] + this.transactions[i].offer_actions[j].value_n;
						}
						if (this.transactions[i].offer_actions[j].reward_tags !== null && this.transactions[i].offer_actions[j].reward_tags[0] === 'BONUS') {
							this.transactions[i]['bonus_offer'] = this.transactions[i].offer_actions[j];
							this.transactions[i]['bonus_value'] = this.transactions[i]['bonus_value'] + this.transactions[i].offer_actions[j].value_n;
						}
					}
				}
				this.next = data.headers.get('next');
				this.prev = data.headers.get('prev');
				if (this.transactions.length > 0) {
					this.noTransactions = false;
				} else {
					this.noTransactions = true;
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	getInvoiceDetails() {
		this.partnercreationService
			.getInvoiceDetails(this.invoiceid, this.partnerobj.sponsors[0].id)
			.then((data: any) => {
				this.invoiceDetails = data;
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}


	backToPaymens() {
		this.router.navigate(['/payments']);
	}

	showDownloadMenu() {
		this.dowloadmenu = true;
	}

	hideDownloadMenu() {
		this.dowloadmenu = false;
	}

	downloadInvoiceTransactions(type) {
		this.transactionsloader = true;
		this.partnercreationService
			.downloadTransactionsEkSpecificAPI(this.partnerobj.sponsors[0].id, this.invoiceDetails.start_date, this.invoiceDetails.end_date, type)
			.then((data: any) => {
				this.downLoadFile(data);
				this.transactionsloader = false;
			})
			.catch(error => {
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			this.transactionsloader = false;
		});
		// this.partnercreationService
		// 	.downloadInvoiceTransactions(this.invoiceid, type, this.partnerobj.sponsors[0].id)
		// 	.then((data: any) => {
		// 		let a = document.createElement('a')
		// 		a.href = data.url
		// 		a.download = 'transactions'
		// 		document.body.appendChild(a)
		// 		a.click()
		// 		document.body.removeChild(a)
		// 		this.hideDownloadMenu();
		// 		this.transactionsloader = false;
		// 	})
		// 	.catch(error => {
		// 		this.error = true;
		// 		this.errorMsg = this.commonService.showCommonErrorMsg(error);
		// 		this.transactionsloader = false;
		// 	});
	}

	downLoadFile(data: any, filename: string = null) {
		let a = document.createElement('a');
		a.href = data.url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} 

	gotoMargetingPage() {
		this.router.navigate(['/skywardseveryday']);
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getProgramData() {
		this.partnercreationService
			.getProgramData()
			.then((data: any) => {
				this.programtimezone = data[0].timezone;
			})
			.catch(error => {
				//
			});
	}

	getData() {
		this.contentful.getContent('7JVMHH3aT9k2bzxZFdbcnA', this.locale).subscribe(dataobj => {
			this.headerImage = dataobj['imageContent'].fields.file.url;
		});
	}
}
