import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from '../../services';

@Component({
  selector: 'app-linkexpired',
  templateUrl: './linkexpired.component.html',
  styleUrls: ['./linkexpired.component.less']
})
export class LinkexpiredComponent implements OnInit {
  locale: string ="en-US";
  headerImg: any;
  backgroundImg: any;
  constructor(
  	private router: Router,
    private contentful: ContentfulService
  ) { }

  ngOnInit() {
    this.getData();
  }

  gotoPage(url) {
    this.router.navigate([url]);
  }

  getData() {
    this.contentful.getContent('5bKog8B7Apaixd1GCpKc2r', this.locale).subscribe(dataobj => {
      this.backgroundImg = dataobj['imageContent'].fields.file.url;
    });

    this.contentful.getContent('3VDF0hzezUB232Sq1mYlNW', this.locale).subscribe(dataobj => {
      this.headerImg = dataobj['imageContent'].fields.file.url;
    });
  }
}
