export class User {
	token: string;
	expires_in: string;
	success: boolean;
	role_name: string;
	user: any;
	sponsor_location_name: number;
	sponsor_name: string;
	active: boolean;
	profile_image: string;
	mobile: string;
	role: any;
	sponsor_id: number;
	sponsor_logo: string;
	header: boolean;
	sponsors: any
}