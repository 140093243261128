import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import * as ratesData from "../../assets/static/rate_per_mile_ calculator.json";
import * as _ from "lodash";
import { Partner, partner, Sales, sales } from "../../models";
import {
	FormControl,
	FormGroup,
	FormBuilder,
	FormsModule,
	NgForm
} from "@angular/forms";
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { stepTwoCMSModel, stepTwoEntryIdObj } from "../../models";
import { HeaderComponent } from '../header/header.component';

@Component({
	selector: 'app-customizeyourpackage',
	templateUrl: './customizeyourpackage.component.html',
	styleUrls: ['./customizeyourpackage.component.less']
})
export class CustomizeyourpackageComponent implements OnInit {
	@ViewChild(HeaderComponent) header: HeaderComponent;
	step: number = 2;
	ratePerMiles = ratesData.default;
	expand; showkyc; error; loader: boolean = false;
	errorMsg: string;
	public salesObj: Sales = sales[0];
	public partnerobj: Partner = partner[0];
	errorObj = {
		"error": false,
		"errorMsg": ''
	};
	loaderObj = {
		"balance_sheet": false,
		"cash_flow_stmt": false,
		"comm_cert": false
	};
	lookups: any = {};
	documentObj: any = {
		"balancesheets": [],
		"cashflowstmts": [],
		"commercecertificates": [],
		"filesize": 5242880
	};
	taxes: any;
	billingConfig: any = {
		'payment_plan': 'prepaid',
		'rate': '0.071',
		'tax_rate': 1
	};
	tax_name: string = 'VAT Only';
	stepTwoCMSData = new stepTwoCMSModel();
	stepTwoIdObj = new stepTwoEntryIdObj();
	locale: string = 'en-US';
	previd: number = null;
	prevbutton: string = "";

	constructor(
		private partnercreationService: PartnercreationService,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		private contentful: ContentfulService
	) { }

	ngOnInit() {
		this.getData();
		let partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
		this.commonService.getPartnerData(partnerId).subscribe(data => {
			this.partnerobj = data;
			this.partnerobj.currency = 'USD';
			this.getTaxes();
			if (this.partnerobj.extra_data.onboarding_step_completed === 2 || this.partnerobj.extra_data.onboarding_step_completed === '2') {
				this.getBillingConfig(this.partnerobj.id);
				this.salesObj.sales = this.partnerobj.extra_data.estimated_average_monthly_sales;
				if (localStorage.getItem('salespercentage')) {
					this.salesObj.percenatge = parseInt(localStorage.getItem('salespercentage'));
				} else {
					this.salesObj.percenatge = this.partnerobj.extra_data.percentage_of_sales;
				}
				this.salesObj.monthly_cost_of_miles = this.partnerobj.extra_data.monthly_cost_of_miles;
			}
			this.getDocuments();
		});
		if (this.partnerobj.extra_data.onboarding_step_completed === 2 || this.partnerobj.extra_data.onboarding_step_completed === '2') {
			this.getBillingConfig(this.partnerobj.id);
		}
		this.commonService.getData("lookups", "LAST_OWNERSHIP_CHANGE").subscribe(result => {
			this.lookups["LAST_OWNERSHIP_CHANGE"] = result;
		});
		this.commonService.getData("lookups", "CURRENT_MANAGEMENT_TENURE").subscribe(result => {
			this.lookups["CURRENT_MANAGEMENT_TENURE"] = result;
		});
		this.commonService.getData("lookups", "LEVEL_OF_SOPHISTICATION_OF_OPERATIONS").subscribe(result => {
			this.lookups["LEVEL_OF_SOPHISTICATION_OF_OPERATIONS"] = result;
		});
		this.commonService.getData("lookups", "TYPE_OF_BUSINESS").subscribe(result => {
			this.lookups["TYPE_OF_BUSINESS"] = result;
		});
		this.commonService.getData("lookups", "MARKET_POSITION").subscribe(result => {
			this.lookups["MARKET_POSITION"] = result;
		});
	}
	partnerLogout() {
		this.authenticationService.logout();
	}

	caliculateEveryDayRevenue() {
		if (this.salesObj.percenatge && this.salesObj.percenatge < 1) {
			this.salesObj.percenatge = 1;
		}
		if (this.salesObj.percenatge && this.salesObj.percenatge > 100) {
			this.salesObj.percenatge = 100;
		}
		if (this.salesObj.sales && this.salesObj.percenatge) {
			this.salesObj.revenue = (this.salesObj.sales * (this.salesObj.percenatge / 100)).toFixed(2);
			this.salesObj.monthly_miles_awarded = (this.salesObj.revenue / 3).toFixed(2);
			this.salesObj.monthly_cost_of_miles = (this.salesObj.monthly_miles_awarded * this.billingConfig.rate).toFixed(2);
			this.partnerobj.extra_data["monthly_cost_of_miles"] = this.salesObj.monthly_cost_of_miles;
			let sales = cloneDeep(this.salesObj.sales);
			this.partnerobj.extra_data["estimated_average_monthly_sales"] = parseInt(sales);
			this.partnerobj.extra_data["percentage_of_sales"] = this.salesObj.percenatge;
		} if ((this.salesObj.sales && this.salesObj.percenatge === 0) || !this.salesObj.sales || !this.salesObj.percenatge) {
			this.salesObj.revenue = 0;
			this.salesObj.monthly_miles_awarded = 0;
			this.salesObj.monthly_cost_of_miles = 0;
			this.partnerobj.extra_data["monthly_cost_of_miles"] = 0;
			this.partnerobj.extra_data["estimated_average_monthly_sales"] = 0;
		}
		if (this.salesObj.percenatge) {
			localStorage.setItem('salespercentage', JSON.stringify(this.salesObj.percenatge));
		}
	}
	showKYC() {
		this.showkyc = true;
	}
	hideKYC() {
		this.showkyc = false;
	}
	updatePartner(saveexit) {
		if (!saveexit) {
			this.loader = true;
		}
		let index = _.findIndex(this.taxes, {
			"name": this.tax_name
		});
		if (index !== -1) {
			this.billingConfig.tax_rate = this.taxes[index].id;
		}
		this.partnerobj.extra_data["form_of_payment"] = "BankTransfer";
		this.partnerobj.extra_data.onboarding_step_completed = 2;
		if (this.billingConfig.payment_plan === 'prepaid') {
			this.partnerobj.extra_data["credit_limit"] = 0;
		} else {
			this.partnerobj.extra_data["credit_limit"] = 25000;
		}
		if (this.showkyc) {
			delete this.partnerobj['base_rates'];
		}
		delete this.partnerobj.logo;
		delete this.partnerobj.header;
		delete this.partnerobj.description;
		this.partnercreationService
			.updatePartnerData(this.partnerobj.id, this.partnerobj)
			.then((data: any) => {
				if (this.showkyc && !saveexit) {
					this.router.navigate(['/verifyyourbusiness']);
				} else if (this.showkyc && saveexit) {
					this.partnerLogout();
				} else {
					this.updateBillingConfig(saveexit, this.partnerobj.id);
				}
			})
			.catch(error => {
				this.error = true;
				this.commonService.scrollTop();
				this.loader = false;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	addRemoveDocuments(e, fromButton, fromUpload) {
		let array, tag;
		this.documentObj["frombalance"] = false;
		this.documentObj["fromcashflow"] = false;
		this.documentObj["fromcommcert"] = false;
		switch (fromButton) {
			case 'balancesheets': {
				this.documentObj["frombalance"] = true;
				array = this.documentObj["balancesheets"];
				tag = "balance_sheet";
				break;
			}
			case 'cashflow': {
				this.documentObj["fromcashflow"] = true;
				array = this.documentObj["cashflowstmts"];
				tag = "cash_flow_stmt";
				break;
			}
			case 'commercecertificate': {
				this.documentObj["fromcommcert"] = true;
				array = this.documentObj["commercecertificates"];
				tag = "comm_cert";
				break;
			}
			default: {
				break;
			}
		}
		if (fromUpload) {
			this.previd = null;
			this.prevbutton = null;
			this.loaderObj[tag] = true;
			this.commonService.addDocument(this.partnerobj.id, e, array, this.documentObj.filesize, tag, this.errorObj, this.loaderObj);
		} else {
			if (this.previd !== array[e].id) {
				this.commonService.removeDocument(this.partnerobj.id, e, array, this.errorObj, false);
				this.previd = array[e].id;
				this.prevbutton = fromButton
			}
		}
	}

	getDocuments() {
		this.partnercreationService
			.getPartnerDocuments(this.partnerobj.id)
			.then((data: any) => {
				if (data.documents.length > 0) {
					for (let i = 0; i < data.documents.length; i++) {
						switch (data.documents[i].tag) {
							case "balance_sheet": {
								this.documentObj.balancesheets.push(data.documents[i]);
								break;
							}
							case "cash_flow_stmt": {
								this.documentObj.cashflowstmts.push(data.documents[i]);
								break;
							}
							case "comm_cert": {
								this.documentObj.commercecertificates.push(data.documents[i]);
								break;
							}
							default:
								break;
						}
					}
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	checkValue(event) {
		if (!isNaN(event)) {
			this.salesObj.sales = event;
		}
		this.caliculateEveryDayRevenue();
	}

	getTaxes() {
		this.partnercreationService
			.getTaxes()
			.then((data: any) => {
				this.taxes = data;
				if (this.partnerobj.extra_data.onboarding_step_completed !== 1 && this.partnerobj.extra_data.onboarding_step_completed !== '1') {
					this.getBillingConfig(this.partnerobj.id);
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	updateBillingConfig(saveexit, partnerId) {
		// let billingRestPayload = {
		// 	'payment_plan': this.billingConfig['payment_plan'],
		// 	'tax_rate': this.billingConfig['tax_rate'],
		// 	'sponsor_finance_rates_enabled': false,
		// 	'sponsor': partnerId,
		// 	'id': 
		// }
		let req = cloneDeep(this.billingConfig);
		req['sponsor'] = partnerId;
		req['sponsor_finance_rates_enabled'] = false;
		delete req.rate;
		this.partnercreationService
			.updateBillingConfig(partnerId, req)
			.then((data: any) => {
				this.loader = false;
				if (saveexit) {
					this.partnerLogout();
				} else if (this.partnerobj.extra_data.monthly_cost_of_miles > 25000 && !this.showkyc && !saveexit && this.billingConfig.payment_plan === 'postpaid') {
					this.showKYC();
					this.commonService.scrollTop();
				} else {
					this.router.navigate(['/verifyyourbusiness']);
				}
			})
			.catch(error => {
				this.loader = false;
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				if (data && data.length > 0) {
					this.billingConfig = data[0];
					this.billingConfig['rate'] = '0.071';
					if (this.billingConfig.payment_plan === 'postpaid' && this.partnerobj.extra_data.monthly_cost_of_miles > 25000 && !this.partnerobj.extra_data.last_ownership_change) {
						this.showkyc = true;
					}
					let index = _.findIndex(this.taxes, {
						"id": this.billingConfig.tax_rate
					});
					if (index !== -1) {
						this.tax_name = this.taxes[index].name;
					}
					if (!this.showkyc) {
						this.caliculateEveryDayRevenue();
					}
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	getData() {
		let contentFulIds = this.stepTwoIdObj.entryIds;
		this.contentful.getEntries(this.locale).subscribe(dataobj => {
			this.header.getData(dataobj);
			for (let entry of contentFulIds) {
				let Index = _.findIndex(dataobj.items, {
					"sys": {
						"id": entry.id
					}
				});
				if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
					this.stepTwoCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
				}

			}
		});

	}

	gotoPage(url) {
		this.router.navigate([url]);
	}
}
