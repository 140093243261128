import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentfulService } from "../../services/contentful.service";
import { landingPageCMSModel, entryIdObj } from "../../models";
import * as _ from "lodash";

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.less']
})
export class LandingpageComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private contentful: ContentfulService
  ) { }
  landingPageCMSData = new landingPageCMSModel();
  landingPageIdObj = new entryIdObj();
  locale: string = 'en-US';
  highlight : boolean = true;
  secondhighlight : boolean = false;
  ngOnInit() {
    localStorage.clear();
    this.commonService.scrollTop();
    this.getData();
  }

  highlightTab(firsttab, secondtab) {
    this.highlight = firsttab;
    this.secondhighlight = secondtab;
  }

  gotoPage(url) {
    this.router.navigate([url]);
  }

  getData() {
    let contentFulIds = this.landingPageIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys":{
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent']!==undefined) {
            this.landingPageCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }
        
      }
    });

    this.contentful.getAsset("7fpLr08Rs6ecUwpAa19kXo", this.locale).subscribe(dataobj => {
      this.landingPageCMSData.googleStoreImg = dataobj.file.url;
    });
    this.contentful.getAsset("GNX1iMrUaRCetgCOgeXQx", this.locale).subscribe(dataobj => {
      this.landingPageCMSData.playStoreImg = dataobj.file.url;
    });
    this.contentful.getContent("5Zc0N0ftjV65dPOhvkvIiK", this.locale).subscribe(dataobj => {
      this.landingPageCMSData.backgroundImg = dataobj['imageContent'].fields.file.url;
    });
  }
}
