import { Component, OnInit } from '@angular/core';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Partner, partner, marker } from "../../models";
// import { MouseEvent } from '@agm/core';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
import { profileCMSModel, profileEntryIdObj } from "../../models";
import {
	NgbModal, NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';


@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
	partnerobj: Partner = partner[0];
	step: string = 'profile';
	error: boolean = false;
	errorMsg: string = "";
	tab: string = "myaccount";
	logoArray: any = [];
	headerArray: any = [];
	lookups: any = {};
	partnerlocations: any = [];
	nextcursor: string = '';
	prevcursor: string = '';
	industryval: string = '';
	editDescription: boolean = false;
	logos: boolean = false;
	partnerData: Partner = partner[0];
	enableUploadInformation: boolean = false;
	billingConfig: any;
	locationObj: any;
	hiddenInternalProfileTabs=[];
	errorObj = {
		"error": false,
		"errorMsg": ''
	};
	index: any;
	markers: marker[] = [];
	locationsData: any;
	partnerdocuments: any = [];
	description: any;
	pinType: string = "";
	locale: string = 'en-US';
	profileCMSData = new profileCMSModel();
	profileIdObj = new profileEntryIdObj();
	showsave: boolean = false;
	profileHeaderImg: any;
	selectedDetails: string = '';
	userdata: any;
	partnerId: any;
	signedDocuments:any=[];
	kycDocuments:any=[];
	nopindata = false;
	isProfileEditable:boolean =true;
	allProfileInternalTabs = ['myaccount','appcontent','settings','training','marketing'];
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private partnercreationService: PartnercreationService,
		private modalService: NgbModal,
		private contentful: ContentfulService,
		private rolebasedrulesService:RolebasedrulesService
	) { }

	ngOnInit() {
		this.getData();
		this.setIsProfileEditable();
		this.getInternalHiddenTabs();
		this.partnerobj["industry"] = "";
		this.commonService.getData("lookups", "INDUSTRY_TYPE").subscribe(result => {
			this.lookups["INDUSTRY_TYPE"] = result;
		});
		if (this.route.snapshot.queryParamMap.get('partner')) {
			this.partnerId = parseInt(this.route.snapshot.queryParamMap.get('partner'));
		} else {
			this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
		}
		this.userdata = this.authenticationService.currentUserValue;
		this.getBillingConfig(this.partnerId );
		this.getSignedAndKYCDocuments(this.partnerId);
		this.commonService.scrollTop();
		this.setFirstVisibleTab();
		this.commonService.getPartnerData(this.partnerId ).subscribe(data => {
			this.partnerobj = cloneDeep(data);
			this.resetLogos();
			if (this.partnerobj.extra_data.draft_description) {
				this.description = this.partnerobj.extra_data.draft_description;
			} else {
				this.description = this.partnerobj.description;
			}
			this.getPartnerLocations(this.partnerId);
			this.industryval = this.partnerobj['industry'];
			this.commonService.assignLookUpValues(data, this.partnerData);
			//this.getSponsordocuments(partnerId);
		});
	}

	setFirstVisibleTab(){
		let displayTab ;
		if(this.hiddenInternalProfileTabs && this.hiddenInternalProfileTabs.length > 0){
		let allPTabs = this.allProfileInternalTabs.slice();
			allPTabs.forEach((tab)=>{
				if(!this.hiddenInternalProfileTabs.includes(tab) && displayTab == null){
					displayTab = tab;
				}
			});
		}
		if(displayTab == null){
			displayTab ='myaccount';
		}
		this.tab = displayTab;
	}

	downloadParticipatingOutletsToCSV() {
		if (this.locationsData && this.locationsData.length > 0) {
			let downFileName = 'locations-data';
			if (this.locationsData[0] && this.locationsData[0].sponsor_name) {
				downFileName = this.locationsData[0].sponsor_name + "-" + downFileName;
			}
			// this.downloadFileAsCSV()
			let formatLocationDataArray = this.partnerlocations.map(value => ({
				"Location Name": value.location_name,
				"Opening hours": value.location_timings[0].open_time + ' - ' + value.location_timings[0].close_time,
				"Card Type": value.cardname,
				"VISA Acquirer ID": value.visa_acquirer_id,
				"VISA Acquirer BIN": value.visabin,
				"MC Acquirer ID": value.master_acquirer_id
			}));
			// console.log(formatLocationDataArray);
			this.downloadFileAsCSV(formatLocationDataArray, downFileName);
			// this.downloadASXLS(formatLocationDataArray);

		}
	}

	downloadFileAsCSV(data, filename) {
		let csvData;
		csvData = this.ConvertToCSV(data, ["Location Name", "Opening hours", "Card Type", "VISA Acquirer ID", "VISA Acquirer BIN", "MC Acquirer ID"]);
		// console.log(csvData);
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
		dwldLink.setAttribute("href", url);
		dwldLink.setAttribute("download", filename + ".csv");
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV(objArray, headerList) {
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		// console.log(array);
		let str = '';
		let row = 'S.No,';
		for (let index in headerList) {
			row += headerList[index] + ',';
		}
		row = row.slice(0, -1);
		str += row + '\r\n';
		for (let i = 0; i < array.length; i++) {
			let line = (i + 1) + '';
			console.log(array[i]);
			for (let index in headerList) {
				let head = headerList[index];
				console.log(head);
				line += ',' + array[i][head];
			}
			str += line + '\r\n';
		}
		return str;
	}

	getPartnerData(partnerId) {
		this.partnercreationService
			.getPartnerData(partnerId)
			.then((data: any) => {
				this.partnerobj = data;
			})
			.catch(error => {
				this.error = true;
				this.showErrorMsg(error);
			});

	}

	tabChange(val) {
		this.tab = val;
	}

	getPartnerLocations(id) {
		// if (cursor) {
		// 	cursor = cursor.split("cursor=")[1];
		// }
		this.partnercreationService
			.getPartnerLocations(id)
			.then((data: any) => {
				this.partnerlocations = data.body;
				this.prevcursor = data.headers.get('prev');
				this.nextcursor = data.headers.get('next');
				for(var crd1=0; crd1<this.partnerlocations.length; crd1++){
		          this.partnerlocations[crd1].visa_acquirer_id = '';
		          this.partnerlocations[crd1].master_acquirer_id =  '';
		          this.partnerlocations[crd1].cardname = '';
		          this.partnerlocations[crd1].visabin = '';
		          this.getCardDetails(this.partnerId, this.partnerlocations[crd1].id, crd1);
		        }
				this.locationsData = {};
				this.locationsData = cloneDeep(this.partnerlocations);
				this.markers = [];
				for (let i = 0; i < this.locationsData.length; i++) {
					this.markers.push(cloneDeep(this.locationsData[i]));
					this.markers[i]['location_timings'] = [];
					for (let j = 1; j < 8; j++) {
						if (this.partnerlocations[i].location_timings.length > 0) {
							let index = _.findIndex(this.partnerlocations[i].location_timings, {
								"day": j
							})
							if (index !== -1) {
								this.locationsData[i].location_timings[index]['active'] = true;
								this.markers[i].location_timings.push(this.locationsData[i]['location_timings'][index]);
							} else {
								this.markers[i].location_timings.push({
									'open_time': '',
									'close_time': '',
									'day': j,
									'active': false
								});
							}
						}
						else {
							this.markers[i].location_timings.push({
								'open_time': '',
								'close_time': '',
								'day': j,
								'active': false
							});
						}
					}

				}

				console.log(this.partnerlocations);
			})
			.catch(error => {
				this.showErrorMsg(error);
			});
	}

	getCardDetails(partnerid, locationid, index){
    this.partnercreationService
      .getlocationcarddatails(partnerid, locationid)
      .then((data: any) => {
         if(data.length === 2){
           this.partnerlocations[index].cardname = 'VISA/MasterCard';
           this.partnerlocations[index].visa_acquirer_id = data[0].acquirer_id;
           this.partnerlocations[index].master_acquirer_id = data[1].acquirer_id;
           this.partnerlocations[index].visabin = data[0].bin;
           
         } 
         else if(data[0].network && data[0].network.length > 0 && data[0].network.toLowerCase()==='visa'){
           this.partnerlocations[index].visabin = data[0].bin;
           this.partnerlocations[index].cardname =  'VISA';
           this.partnerlocations[index].visa_acquirer_id = data[0].acquirer_id;
         } 
         else if(data[0].network && data[0].network.length > 0 && data[0].network.toLowerCase()==='mastercard'){
         	this.partnerlocations[index].cardname =  'MasterCard';
          this.partnerlocations[index].master_acquirer_id = data[0].acquirer_id;
         }

          this.markers[index].visa_acquirer_id = this.partnerlocations[index].visa_acquirer_id;
					this.markers[index].master_acquirer_id = this.partnerlocations[index].master_acquirer_id;
					this.markers[index].network = this.partnerlocations[index].cardname;
					this.markers[index].bin_id = this.partnerlocations[index].visabin;
         
         
         
      }).catch(error => {
        this.showErrorMsg(error);
      });

  }


	resetPin(modalpinconfirmation, pinType, location) {
		this.pinType = pinType;
		if (location.active && pinType === 'location') {
			this.locationObj = location;
			this.open(modalpinconfirmation, 'pinResetModal');
		}
		if (pinType === 'sponsor') {
			this.open(modalpinconfirmation, 'pinResetModal');
		}

	}

	mailToPin(location) {
		window.open('mailto:?subject=Location Pin&body=location.pin');
	}

	gotoResetPassword() {
		this.router.navigate(['/changepassword']);
	}

	updatePartner(modalcontentsuccess) {
		this.partnerobj['industry'] = this.industryval;
		if (modalcontentsuccess) {
			this.partnerobj.extra_data.draft_logo = this.logoArray[0].image_url;
			this.partnerobj.extra_data.draft_header = this.headerArray[0].image_url;
			this.partnerobj.extra_data.draft_description = this.description;
		}
		delete this.partnerobj.logo;
		delete this.partnerobj.header;
		delete this.partnerobj.description;
		this.partnercreationService
			.updatePartnerData(this.partnerobj.id, this.partnerobj)
			.then((data: any) => {
				this.partnerobj = data.body;
				this.industryval = this.partnerobj['industry'];
				this.partnerData = cloneDeep(this.partnerobj);
				this.commonService.assignLookUpValues(data.body, this.partnerData);
				this.editDescription = false;
				let ref = this;
				this.open(modalcontentsuccess, 'contentSuccessModal');
				if (modalcontentsuccess) {
					setTimeout(function() { ref.close(); ref.enableUploadInformation = true; ref.logos = false; }, 3000);
				}

			})
			.catch(error => {
				this.error = true;
				this.editDescription = false;
				this.showErrorMsg(error);
			});

	}

	resetEdit(val) {
		if (val === 'logos') {
			this.description = this.partnerobj.description;
			this.resetLogos();
		}
		this[val] = false;
	}

	resetLogos() {
		this.logoArray = [];
		this.headerArray = [];
		if (this.partnerobj.extra_data.draft_logo) {
			this.logoArray.push({
				"image_url": this.partnerobj.extra_data.draft_logo,
				"image": this.partnerobj.extra_data.draft_logo
			});
		} else {
			this.logoArray.push({
				"image_url": this.partnerobj.logo,
				"image": this.partnerobj.logo
			});
		}
		if (this.partnerobj.extra_data.draft_header) {
			this.headerArray.push({
				"image_url": this.partnerobj.extra_data.draft_header,
				"image": this.partnerobj.extra_data.draft_header
			});
		} else {
			this.headerArray.push({
				"image_url": this.partnerobj.header,
				"image": this.partnerobj.header
			});
		}
	}

	open(content, modalclass) {
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			windowClass: modalclass
		};
		this.modalService.open(content, ngbModalOptions);
	}

	close() {
		this.modalService.dismissAll();
	}

	uploadImage(e, fromheader) {
		let array;
		let file = e.target.files[0];
		if (file) {
			if (fromheader) {
				this.headerArray = [];
				array = this.headerArray;
			} else {
				this.logoArray = [];
				array = this.logoArray;
			}
			this.commonService.addDocument(this.partnerobj.id, e, array, 5242880, '', this.errorObj, '');
		}
	}


	showLocationModal(index, modalcontentsuccess, location) {
		if (location) {
			if (location.active) {
				this.locationObj = location;
				this.index = index;
				this.open(modalcontentsuccess, 'locationsModal');
			}
		} else {
			this.index = null;
			this.open(modalcontentsuccess, 'locationsModal');
		}

	}

	showDeleteLocationConfirmation(locationdeletemodal) {
		this.open(locationdeletemodal, 'locationDeleteModal');
	}

	getSignedAndKYCDocuments(partnerId){
		this.partnercreationService.getSignedDocuments(partnerId).then((data: any) => {
			this.signedDocuments = data;})
			.catch(error => {
				this.error = true;
				this.showErrorMsg(error);
			});
		this.partnercreationService.getKYCDocuments(partnerId).then((data: any) => {
			this.kycDocuments = data;
		})
			.catch(error => {
				this.error = true;
				this.showErrorMsg(error);
			});
		this.getSponsordocuments(partnerId);
	}


	getSponsordocuments(partnerId) {
		this.partnercreationService
			.getPartnerDocuments(partnerId)
			.then((data: any) => {
				this.partnerdocuments = data.documents;
			})
			.catch(error => {
				this.error = true;
				this.showErrorMsg(error);
			});
	} 

	

	downloadDocuments(url) {
		let a = document.createElement('a')
		a.href = url;
		a.download = 'documents'
		a.target = '_blank';
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
	}

	showPreview(doc) {
		this.commonService.showPreview(doc);
	}

	getPartnerMarkers() {
		this.partnerlocations = [];
		this.getPartnerLocations(this.partnerId);
	}

	preventEvent(e, active) {
		if (!active) {
			e.preventDefault();
		}
	}

	enableReceiptCheck() {
		let request = {
			"sponsor_id": this.partnerobj.id,
			"bit_category": "SPEND",
			"bit_type": "Accrual",
			"mandatory_attributes": ["h_bit_source_generated_id"]
		};
		if (this.partnerobj.extra_data.receipt_check === false) {
			request.mandatory_attributes = [];
		}
		this.commonService.enableReceiptCheck(this.partnerobj.id, request).subscribe(data => {
		});
	}

	updateReceiptCheck(fromreceiptcheck) {
		var request: any;
		if (fromreceiptcheck) {
			request = {
				"extra_data": {
					'receipt_check': this.partnerobj.extra_data.receipt_check
				}
			};
		} else {
			request = {
				"extra_data": {
					'sponsor_pin_threshold': this.partnerobj.extra_data.sponsor_pin_threshold
				}
			};
		}
		this.error = false;
		this.partnercreationService
			.updateReceiptCheck(this.partnerobj.id, request)
			.then((data: any) => {
				if (fromreceiptcheck) {
					this.enableReceiptCheck();
				} else {
					this.showsave = false;
				}
			})
			.catch(error => {
				this.showErrorMsg(error);
			});
	}

	getData() {
		let contentFulIds = this.profileIdObj.entryIds;
		this.contentful.getEntries(this.locale).subscribe(dataobj => {
			for (let entry of contentFulIds) {
				let Index = _.findIndex(dataobj.items, {
					"sys": {
						"id": entry.id
					}
				});
				if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
					this.profileCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
				}

			}
		});

		this.contentful.getContent('uNWzQgSxf2cEVwzdWmDD6', this.locale).subscribe(dataobj => {
			this.profileHeaderImg = dataobj['imageContent'].fields.file.url;
		});


	}

	showErrorMsg(error) {
		this.error = true;
		this.errorMsg = this.commonService.showCommonErrorMsg(error);
	}

	getBillingConfig(partnerId) {
		this.partnercreationService
			.getBillingConfig(partnerId)
			.then((data: any) => {
				this.billingConfig = data[0];
			})
			.catch(error => {
				this.showErrorMsg(error);
			});

	}

	checkNumber(event) {
		return this.commonService.checkNumber(event);
	}

	showAssociatedEmails(resetemailmodal) {
		this.open(resetemailmodal, 'resetEmailModal');
	}

	showProfileDetails(editModal, selectedDetails) {
		this.selectedDetails = selectedDetails;
		this.open(editModal, 'editProfilelModal');
	}

	getAddedEmails(event){
		this.partnerobj = event;
	}

	getPartnerDetails(event){
		this.partnerData = event;
		this.partnerobj = event;
	}
	setIsProfileEditable(){
		const currentRoleCode = this.authenticationService?.currentUserValue?.role?.code;
		this.isProfileEditable = this.rolebasedrulesService.checkIfPathEditable(currentRoleCode,'profile');
	}

	getInternalHiddenTabs(){
		const currentRoleCode = this.authenticationService?.currentUserValue?.role?.code;
		this.hiddenInternalProfileTabs =  this.rolebasedrulesService.getHiddenInternalTabsBasedOnRoleCode(currentRoleCode);
	}
}
