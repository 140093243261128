import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, OffercreationService, PartnercreationService, ContentfulService } from '../../services';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import * as _ from "lodash";
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';

@Component({
	selector: 'app-offers',
	templateUrl: './offers.component.html',
	styleUrls: ['./offers.component.less']
})
export class OffersComponent implements OnInit {
	lookups: any = {};
	noTransactions: boolean = false;
	step: string = 'offers';
	error: boolean = false;
	errorMsg: string;
	offers = [];
	next: string = "None";
	prev: string = "None";
	partnerobj: any;
	noOffers: boolean = false;
	programtimezone: string = 'Asia/Dubai';
	offerHeaderImage: any;
	locale: string = 'en-US';
	loader: boolean = true;
	isOfferEditable:boolean=true;
	constructor(
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private offercreationService: OffercreationService,
		private router: Router,
		private _eref: ElementRef,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private contentful: ContentfulService,
		private rolebasedrulesService:RolebasedrulesService
	) { }

	ngOnInit() {
		this.getProgramData();
		this.partnerobj = this.authenticationService.currentUserValue;
		if (this.route.snapshot.queryParamMap.get('partner')) {
			this.getPartnerData(parseInt(this.route.snapshot.queryParamMap.get('partner')));
		}
		else {
			this.partnerobj = this.authenticationService.currentUserValue;
			this.getPartnerData(this.partnerobj.sponsors[0].id);
		}

		this.commonService.getData("lookups", "REWARD_TYPE").subscribe(result => {
			this.lookups["REWARD_TYPE"] = result;
			this.getCampaigns('');
		});
		this.getData();
		this.setIsOfferEditable();
	}


	getPartnerData(partnerId) {
		this.partnercreationService
			.getPartnerData(partnerId)
			.then((data: any) => {
				this.partnerobj = data;

			})
			.catch(error => {
				this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});

	}

	partnerLogout() {
		this.authenticationService.logout();
	}

	gotoPage(page) {
		this.router.navigate([page],  { queryParams: { partner: localStorage.getItem('partnerid') }});
	}

	getCampaigns(cursor) {
		this.loader = true;
		if (cursor !== undefined && cursor !== 'None') {
			cursor = cursor.split('cursor=')[1];
		}
		let partnerid = this.route.snapshot.queryParamMap.get('partner');
		this.offercreationService
			.getCampaigns(cursor, partnerid)
			.then((data: any) => {

				for (var i = 0; i < data.body.length; i++) {
					data.body[i].start_date = moment(data.body[i].start_date).tz(this.programtimezone).format('DD MMM YYYY');
					data.body[i].end_date = moment(data.body[i].end_date).tz(this.programtimezone).format('DD MMM YYYY');
					let findIndex = _.findIndex(this.lookups["REWARD_TYPE"], {
						"code": data.body[i].template_params.reward_type
					});
					if (findIndex !== -1) {
						data.body[i].template_params.reward_type = this.lookups["REWARD_TYPE"][findIndex].name;
					}
				}
				this.offers = data.body;
				this.next = data.headers.get('next');
				this.prev = data.headers.get('prev');
				if (this.offers.length > 0) {
					this.noOffers = false;
				} else {
					this.noOffers = true;
				}
				this.loader = false;
			})
			.catch(error => {
				this.error = true;
				this.loader = false;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
			});
	}

	gotoOfferDetails(offerid) {
			this.router.navigate(['/offerdetails'], { queryParams: { offer: offerid }});

	}

	getProgramData() {
		this.partnercreationService
			.getProgramData()
			.then((data: any) => {
				this.programtimezone = data[0].timezone;
			})
			.catch(error => {
				//
			});

	}

	getData() {
		this.contentful.getContent('3XUISyKM96aVSLQYrOhDNW', this.locale).subscribe(dataobj => {
			this.offerHeaderImage = dataobj['imageContent'].fields.file.url;
		});
	}

	setIsOfferEditable(){
		const currentRoleCode = this.authenticationService?.currentUserValue?.role?.code;
		this.isOfferEditable = this.rolebasedrulesService.checkIfPathEditable(currentRoleCode,'offers');
	}
}
