import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthenticationService , ContentfulService, StrategicparternsService} from '../../services';

@Component({
	selector: 'app-sessionmodal',
	templateUrl: './sessionmodal.component.html',
	styleUrls: ['./sessionmodal.component.less']
})
export class SessionmodalComponent implements OnInit {
	signobj: any = {
		"username": "",
		"password": ""
	};
	error: boolean = false;
	locale: string = "en-US";
	errorMsg: string = '';
	UserRestrictionData: any;
	isparent: boolean = false;
  	parentSponsor: string;
	constructor(private authenticationService: AuthenticationService,
		private appComponent: AppComponent,private strategicparternsService: StrategicparternsService, private contentful: ContentfulService) { }
	ngOnInit() {
		this.getUserRestrictionData();
	}

	partnerLogin(sigininForm) {
		var request: any = {
			"username": this.signobj.username,
			"password": this.signobj.password
		};
		this.error = false;
		this.errorMsg = "";
		for(var k=0; k<this.UserRestrictionData.length; k++){
	      if(this.UserRestrictionData[k] === request.username){
	        this.error = true;
	        this.errorMsg = "Login details incorrect";
	      }
    	}

		if (sigininForm && this.error === false) {
			this.errorMsg = '';
			this.authenticationService.login(request)
				.subscribe(
					data => {
						this.parentSponsor = data.sponsors[0].id;
						this.appComponent.setExpirationTime(data.expires_in);
						if(data.sponsors !== undefined && data.sponsors !== null){
			              	for(var spon=0; data.sponsors.length > spon; spon++){
			                	if(data.sponsors[spon].hierarchy_designation === "Parent"){
				                  this.parentSponsor =  data.sponsors[spon].id;
				                  localStorage.setItem('parentSponsor', JSON.stringify(data.sponsors[spon]));
				                  this.strategicparternsService.setParentSponsor(data.sponsors[spon]);
				                  this.isparent = true;
				                  break;
			                	}
			              	}
		            	}
			            if(this.isparent) {
			              this.getChilds(this.parentSponsor);
			            } else{
			              this.appComponent.getPartnerData(this.parentSponsor, true);
			            }
					},
					error => {
						this.error = true;
						this.errorMsg = error.error.error.message;
					});
		}

	}

	getUserRestrictionData(){
    	this.contentful.getContent('5yrqtSE2AT1xcRBwHVkjOJ', this.locale).subscribe(dataobj => {
      		this.UserRestrictionData = dataobj['stringContent'].split(', ');
    	});
  	}

	getChilds(id){
    this.strategicparternsService
      .StrategicPartners(id)
      .then((data: any ) => {
        if(data.length > 0){
          this.strategicparternsService.setChildSponsors(data);
        //  sessionStorage.setItem('partners', JSON.stringify(data));
          this.appComponent.getPartnerData(this.parentSponsor, true);
        }
      });
  }
}
