import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, OffercreationService, PartnercreationService, ContentfulService } from '../../services';
import { Offer, offer, Partner, partner, marker } from "../../models";
import * as _ from "lodash";
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
//import moment from "moment-timezone";
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { offerCMSModel, offerEntryIdObj } from "../../models";

@Component({
  selector: 'app-offercreation',
  templateUrl: './offercreation.component.html',
  styleUrls: ['./offercreation.component.less']
})
export class OffercreationComponent implements OnInit {
  partnerobj: Partner = partner[0];
  showreview: boolean = false;
  public offerobj: Offer = offer[0];
  lookups: any = {};
  error: boolean = false;
  errorMsg: string = "";
  locationsLeft: any = [];
  locationsRight: any = [];
  checked: boolean = true;
  targetedData = {
    "target_count": 0,
    "total_count": 0,
    "percentage": 0,
  };
  startDate = moment(new Date()).format('YYYY-MM-DD');
  endDate = moment(new Date()).format('YYYY-MM-DD');
  offerStartTime = null;
  offerEndTime = null;
  offerStartTimeInAMPM = "00:01 am";
  offerEndTimeInAMPM = "23:59 pm";
  rewardType = "";
  targetObj = {
    'gender': [],
    'tier_class': [],
    'age': []
  };
  reqObj: any = {
    'allow_sponsorfollowers': false,
    'member_target_json': []
  };
  private samplereqObj: any = {
  };
  partnerlocations: any = [];
  nextcursor: string = '';
  prevcursor: string = '';
  alllocations: boolean = true;
  allDay: boolean = true;
  offerCMSData = new offerCMSModel();
  offerIdObj = new offerEntryIdObj();
  locale: string = 'en-US';
  overlapoffer: any = [];
  previewtargetObj = {
    'gender': '',
    'tier_class': '',
    'age': ''
  };
  selectedtargetObj = {
    'gender': [],
    'tier_class': [],
    'age': [{ 'checked': false }, { 'checked': false }, { 'checked': false }, { 'checked': false }]
  };
  minDate = moment();
  locationCount: number = 0;
  multiplierVal: string = '';
  progresswidth = 1;
  progressid: any;
  target: boolean = false;
  offerloader: boolean = false;
  onedayerror: boolean = false;
  starterror: boolean = false;
  programtimezone: string = "Asia/Dubai";
  headerImage: any;
  today: any = moment(new Date()).format('YYYY-MM-DD');
  start_date: any = '';
  end_date: any = '';
  previewendDate: any = '';
  previewstartDate: any = '';
  endTime: any = '';
  startTime: any = '';
  partnerId: any;
  programCurrency:any;
  schedulemenu:boolean=false;
  communicationScheduleType:string = 'On Launch';
  finalScheduledDateAndTime:string;
  showScheduledDateAndTime:string;
  notificationScheduleType = 'on_launch';
  memberSegment = [];
  targetedSegment:any;
  rewardValueName:any = '';
  targetedSegmentName:any = '';
  headerArray: any = [];
  errorObj = {
		"error": false,
		"errorMsg": ''
	};
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private offercreationService: OffercreationService,
    private partnercreationService: PartnercreationService,
    private modalService: NgbModal,
    private contentful: ContentfulService

  ) { }

  ngOnInit() {
    this.getData();
    this.getProgramData();
    //let partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
    if (this.route.snapshot.queryParamMap.get('partner')) {
      this.partnerId = parseInt(this.route.snapshot.queryParamMap.get('partner'));
    } else {
      this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
    }
    this.commonService.getPartnerData(this.partnerId).subscribe(data => {
      this.partnerobj = data;
      this.commonService.getMemberSegments(this.partnerobj.id).subscribe(data => {
        this.memberSegment = data;
        this.lookups["MEMBER_SEGMENT"] = data;
      });
      this.getPartnerLocations();
      this.resetLogos();
    });
    this.commonService.getData("lookups", "REWARD_TYPE").subscribe(result => {
      this.lookups["REWARD_TYPE"] = result;
    });
    this.commonService.getData("lookups", "BONUS_REWARD_MULTIPLIER").subscribe(result => {
      this.lookups["BONUS_REWARD_MULTIPLIER"] = result;
    });
    this.commonService.getData("lookups", "OFFER_PROPOSITION").subscribe(result => {
      this.lookups["OFFER_PROPOSITION"] = result;
    });
    this.commonService.getData("lookups", "GENDER").subscribe(result => {
      this.lookups["GENDER"] = result;
      for (var i = 0; i < this.lookups["GENDER"].length; ++i) {
        this.selectedtargetObj.gender.push({ 'checked': false });
      }
    });
    this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
      this.lookups["TIER_CLASS"] = result;
      for (var i = 0; i < this.lookups["TIER_CLASS"].length; ++i) {
        this.selectedtargetObj.tier_class.push({ 'checked': false });
      }
    });
    this.offerobj['offer_name'] = '';
    this.offerobj['offer_description'] = '';
    this.offerobj.template_params.reward_type = 'VOUCHER';
    this.offerobj.template_params.product_id = 1893;
    this.offerobj.template_params.reward_value = 1893;
    this.offerobj.template_params.member_seg = '';
    this.offerobj.template_params.reward_multiplier = null;
    this.offerobj.template_params.minimum_transaction_value= 0;
    this.offerobj.template_params.percentage_value =null;

    this.setAllDayTime("", false);
    this.offerobj.start_date = this.startDate;
    this.offerobj.end_date = this.endDate;
    this.previewendDate = moment(this.offerobj.end_date).format('D MMM');
    this.previewstartDate = moment(this.offerobj.start_date).format('D MMM');
  }

  gotoMargetingPage() {
    this.router.navigate(['/skywardseveryday']);
  }

  choosedDate(event, modalofferoverlap) {
    this.overlapoffer = [];
    if (event.chosenLabel.split(' - ')[0]) {
      this.offerobj.start_date = moment(event.chosenLabel.split(' - ')[0]).format('YYYY-MM-DD');
      this.start_date = moment(event.chosenLabel.split(' - ')[0]).format('YYYY-MM-DD');
    }
    if (event.chosenLabel.split(' - ')[1]) {
      this.offerobj.end_date = moment(event.chosenLabel.split(' - ')[1]).format('YYYY-MM-DD');
      this.end_date = moment(event.chosenLabel.split(' - ')[1]).format('YYYY-MM-DD');
    }
    this.checkDateError();
    this.endDate = moment(this.offerobj.end_date).format('MMM DD, YYYY');
    this.startDate = moment(this.offerobj.start_date).format('MMM DD, YYYY');
    this.previewendDate = moment(this.offerobj.end_date).format('D MMM');
    this.previewstartDate = moment(this.offerobj.start_date).format('D MMM');
  }

  showOfferReview(showreview, modalofferoverlap) {
    this.error = false;
    this.errorMsg = '';
    this.targetObj.gender = this.targetObj.gender.sort();
    this.targetObj.tier_class = this.targetObj.tier_class.sort();
    this.targetObj.age = this.targetObj.age.sort();
    // if (this.offerobj.template_params.reward_type === 'PERCENTAGE') {
    //   if (!this.offerobj.template_params.minimum_transaction_value) {
    //     this.offerobj.template_params.minimum_transaction_value = 0;
    //   }
    // }
    // else {
    //   this.offerobj.template_params.minimum_transaction_value = null;
    // }
    if (this.overlapoffer.length > 0) {
      this.open(modalofferoverlap, '');
    } else if (this.locationCount === 0) {
      this.commonService.scrollTop();
      this.error = true;
      this.errorMsg = "Select atleast one participating outlet";
    } else {
      // for (var j = 0; j < this.lookups["REWARD_TYPE"].length; j++) {
      //   if (this.lookups["REWARD_TYPE"][j].code === this.offerobj.template_params.reward_type) {
      //     this.rewardType = this.lookups["REWARD_TYPE"][j].name;
      //   }
      // }
      for (let i = 0; i < this.targetObj.gender.length; i++) {
        let findIndex = _.findIndex(this.lookups["GENDER"], {
          "code": this.targetObj.gender[i]
        });
        if (findIndex !== -1) {
          if (i === 0) {
            this.previewtargetObj.gender = this.lookups["GENDER"][findIndex].name;
          } else {
            this.previewtargetObj.gender = this.previewtargetObj.gender + ", " + this.lookups["GENDER"][findIndex].name;
          }
        }
      }
      for (let j = 0; j < this.targetObj.tier_class.length; j++) {
        let findIndex = _.findIndex(this.lookups["TIER_CLASS"], {
          "code": this.targetObj.tier_class[j]
        });
        if (findIndex !== -1) {
          if (j === 0) {
            this.previewtargetObj.tier_class = this.lookups["TIER_CLASS"][findIndex].name;
          } else {
            this.previewtargetObj.tier_class = this.previewtargetObj.tier_class + ", " + this.lookups["TIER_CLASS"][findIndex].name;
          }
        }
      }
      let age = cloneDeep(this.targetObj.age);
      for (var j = 0; j < age.length; j++) {
        if (age[j] === '71-200') {
          age[j] = '71+';
        }
      }
      this.previewtargetObj.age = age.join(", ");
      this.commonService.scrollTop();
      this.showreview = showreview;
    }
  }

 /* constructTargetArray(code, form, attribute) {
    if (form) {
      this.samplereqObj[attribute] = {};
      let findIndex = this.targetObj[attribute].indexOf(code);
      if (findIndex === -1) {
        this.targetObj[attribute].push(code);
      } else {
        this.targetObj[attribute].splice(findIndex, 1);
      }
      if (this.targetObj[attribute].length === 1) {
        if (attribute !== 'age') {
          this.samplereqObj[attribute] = {
            "attribute": attribute,
            "value": [],
            "operator": "in",
            "boolean_operator": "and"
          };
          for (let i = 0; i < this.targetObj[attribute].length; i++) {
            this.samplereqObj[attribute]['value'].push(this.targetObj[attribute][i]);
          }
        } else {
          let val1 = "";
          let val2 = "";
          for (let i = 0; i < this.targetObj[attribute].length; i++) {
            val1 = this.targetObj[attribute][i].split('-')[0];
            val2 = this.targetObj[attribute][i].split('-')[1];
          }
          this.samplereqObj[attribute] = {
            "complex_clause": true,
            "complex_clause_criteria": [
              {
                "attribute": attribute,
                "value": val1,
                "operator": ">=",
                "boolean_operator": "and"
              },
              {
                "attribute": attribute,
                "value": val2,
                "operator": "<="
              }
            ],
            "boolean_operator": "or"
          }
        }

      }
      if (this.targetObj[attribute].length > 1) {
        if (attribute !== 'age') {
          this.samplereqObj[attribute] = {
            "attribute": attribute,
            "value": [],
            "operator": "in",
            "boolean_operator": "and"
          };
          for (let i = 0; i < this.targetObj[attribute].length; i++) {
            this.samplereqObj[attribute]['value'].push(this.targetObj[attribute][i]);
          }
        } else {
          this.samplereqObj[attribute] = {
            "complex_clause": true,
            "complex_clause_criteria": []
          };
          for (let i = 0; i < this.targetObj[attribute].length; i++) {
            let val1 = this.targetObj[attribute][i].split('-')[0];
            let val2 = this.targetObj[attribute][i].split('-')[1];
            this.samplereqObj[attribute]['complex_clause_criteria'].push(
              {
                "complex_clause": true,
                "complex_clause_criteria": [
                  {
                    "attribute": "age",
                    "value": val1,
                    "operator": ">=",
                    "boolean_operator": "and"
                  },
                  {
                    "attribute": "age",
                    "value": val2,
                    "operator": "<="
                  }
                ],
                "boolean_operator": "or"
              }
            );
          }
        }
      }
      this.reqObj['member_target_json'] = [];
      for (var key in this.samplereqObj) {
        // check if the property/key is defined in the object itself, not in parent
        if (this.samplereqObj.hasOwnProperty(key)) {
          if (Object.keys(this.samplereqObj[key]).length > 0) {
            this.reqObj['member_target_json'].push(this.samplereqObj[key]);
          }

        }
      }
      this.offerobj['member_target_json'] = this.reqObj['member_target_json'];
    }
  }

  move() {
    this.progressid = setInterval(frame, 100);
    let _self = this;
    function frame() {
      let elem = document.getElementById("progress-bar");
      if (_self.progresswidth >= _self.targetedData.percentage) {
        clearInterval(_self.progressid);
      } else {
        if (_self.progresswidth < 100) {
          _self.progresswidth++;
          elem.style.width = _self.progresswidth + '%';
        }
      }
    }
  } */

  getMembersTargeted(id: any) {
    this.targetedSegment = this.memberSegment.find(seg => seg.member_segment_code === this.offerobj.template_params.member_seg);
    this.targetedData.target_count = this.targetedSegment.count;
    this.targetedData.total_count = this.targetedSegment.total_count;
    this.targetedData.percentage = ((this.targetedSegment.count/this.targetedSegment.total_count)*100)>2?((this.targetedSegment.count/this.targetedSegment.total_count)*100):2
    // this.target = true;
    // // this.progresswidth = 1;
    // // if (val) {
    // //   this.move();
    // // }
    // this.offercreationService
    //   .getMembersTargeted(this.reqObj)
    //   .then((data: any) => {
    //     this.targetedData = data;
    //     this.targetedData.target_count = this.targetedData.total_count;
    //     //this.targetedData.percentage = (100 * this.targetedData.target_count) / this.targetedData.total_count;
    //     //clearInterval(this.progressid);
    //     this.target = false;
    //   })
    //   .catch(error => {
    //     this.error = true;
    //     this.errorMsg = this.commonService.showCommonErrorMsg(error);
    //   });
  } 

  getPartnerLocations() {
    this.partnerlocations = [];
    this.partnercreationService
      .getPartnerLocationsWithoutPagination(this.partnerobj.id)
      .then((data: any) => {
        this.partnerlocations = data.body;
        let locationsData = [];
        locationsData = cloneDeep(this.partnerlocations);
        var half_length = Math.ceil(locationsData.length / 2);
        this.locationsLeft = locationsData.splice(0, half_length);
        this.locationsRight = locationsData.splice(0, half_length);
        this.locationCount = this.partnerlocations.length;
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  resetLogos() {
		this.headerArray = [];
		if (this.partnerobj.header) {
			this.headerArray.push({
				"image_url": this.partnerobj.header,
				"image": this.partnerobj.header
			});
		} else {
			this.headerArray.push({
				"image_url": this.partnerobj.header,
				"image": this.partnerobj.header
			});
		}
	}

  activateDeactivateLocation(allloc, index, leftArray) {
    this.error = false;
    this.errorMsg = '';
    if (allloc) {
      for (let i = 0; i < this.partnerlocations.length; i++) {
        this.partnerlocations[i].active = this.checked;
      }
      for (let j = 0; j < this.locationsLeft.length; j++) {
        this.locationsLeft[j].active = this.checked;
      }
      for (let k = 0; k < this.locationsRight.length; k++) {
        this.locationsRight[k].active = this.checked;
      }
      if (this.checked) {
        this.locationCount = this.partnerlocations.length;
      } else {
        this.locationCount = 0;
      }
    } else {
      if (leftArray) {
        this.locationsLeft[index].active = !this.locationsLeft[index].active;
        if (this.locationsLeft[index].active) {
          this.locationCount++;
        } else {
          this.locationCount--;
        }
      } else {
        this.locationsRight[index].active = !this.locationsRight[index].active;
        if (this.locationsRight[index].active) {
          this.locationCount++;
        } else {
          this.locationCount--;
        }
      }
    }
    this.checked = this.locationCount === this.partnerlocations.length;
  }

  setAllDayTime(modalofferoverlap, cleartime) {
    if (this.allDay) {
      this.onedayerror = false;
      this.offerobj.template_params.offer_start_hour = '00';
      this.offerobj.template_params.offer_start_minute = '00';
      this.offerobj.template_params.offer_end_hour = '23';
      this.offerobj.template_params.offer_end_minute = '59';
      this.offerStartTime = null;
      this.offerEndTime = null;
    } else {
      if (cleartime) {
        this.offerobj.template_params.offer_start_hour = '';
        this.offerobj.template_params.offer_start_minute = '';
        this.offerobj.template_params.offer_end_hour = '';
        this.offerobj.template_params.offer_end_minute = '';
      }
      if (this.offerStartTime) {
        this.offerobj.template_params.offer_start_hour = this.offerStartTime.split(':')[0];
        this.offerobj.template_params.offer_start_minute = this.offerStartTime.split(':')[1];
      }
      if (this.offerEndTime) {
        this.offerobj.template_params.offer_end_hour = this.offerEndTime.split(':')[0];
        this.offerobj.template_params.offer_end_minute = this.offerEndTime.split(':')[1];
      }
      this.checkDateError();
      this.offerStartTimeInAMPM = moment(this.offerStartTime, ["h:mm A"]).format("HH:mm a");
      this.offerEndTimeInAMPM = moment(this.offerEndTime, ["h:mm A"]).format("HH:mm a");
      this.endTime = this.offerobj.template_params.offer_end_hour + ':' + this.offerobj.template_params.offer_end_minute;
      this.startTime = this.offerobj.template_params.offer_start_hour + ':' + this.offerobj.template_params.offer_start_minute;
    }
    // if ((this.offerStartTime !== null && this.offerEndTime !== null) || (this.allDay && modalofferoverlap !== '')) {
    //   this.overlapCampaign(this.offerobj.start_date, this.offerobj.end_date, modalofferoverlap);
    // }
  }

  gotoProfile() {
    if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
      this.router.navigate(['/profile'], { queryParams: { partner: localStorage.getItem('partnerid') }});
    } else {
      this.router.navigate(['/profile']);
    }
  }

  makeOfferObjValuesNull(){
    this.offerobj.template_params.reward_multiplier = null; 
    this.offerobj.template_params.reward_value = null; 
    this.offerobj.template_params.percentage_value = null;
    this.offerobj.template_params.minimum_transaction_value=0;
  }

  gotoOffersList() {
    if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
      this.router.navigate(['/offers'], { queryParams: { partner: localStorage.getItem('partnerid') }});
    } else {
        this.router.navigate(['/offers']);
    }
  }

  createCampaign(form, modaloffercreation) {
    this.offerobj['sponsors'] = [];
    this.error = false;
    this.errorMsg = "";
    this.offerloader = true;
    this.offerobj.locations = [];
    this.offerobj.targeted_segment = this.offerobj.template_params.member_seg;
    for (let i = 0; i < this.locationsLeft.length; i++) {
      if (this.locationsLeft[i].active) {
        this.offerobj.locations.push(this.locationsLeft[i].id);
      }
    }
    for (let i = 0; i < this.locationsRight.length; i++) {
      if (this.locationsRight[i].active) {
        this.offerobj.locations.push(this.locationsRight[i].id);
      }
    }
    this.offerobj['sponsors'].push(this.partnerobj.id);
    let startTime = this.offerobj.template_params.offer_start_hour + ":" + this.offerobj.template_params.offer_start_minute + ":" + "00";
    let endTime = this.offerobj.template_params.offer_end_hour + ":" + this.offerobj.template_params.offer_end_minute + ":" + "00";
    let startMomentObj = moment(startTime, ["h:mm:s A"]).format("HH:mm:ss");
    let endtMomentObj = moment(endTime, ["h:mm:s A"]).format("HH:mm:ss");
    let offset = moment.tz(this.programtimezone).format('Z');
    // if (this.locationCount !== this.partnerlocations.length) {
    //   console.log("inside same length");
    //   this.offerobj.location_category = "OFFLINE";
    // }
    let today = moment(new Date()).format('YYYY-MM-DD');
    if (today === this.offerobj.start_date && this.allDay) {
      this.offerobj.start_date = null;
    }
    if (this.offerobj.start_date && !this.offerobj.start_date.split('T')[1]) {
      this.offerobj.start_date = this.offerobj.start_date + "T" + startMomentObj + "" + offset;
    }
    if (this.offerobj.start_date && this.offerobj.start_date.split('T')[1]) {
      this.offerobj.start_date = this.offerobj.start_date.split('T')[0] + "T" + startMomentObj + "" + offset;
    }
    if (this.offerobj.end_date && !this.offerobj.end_date.split('T')[1]) {
      this.offerobj.end_date = this.offerobj.end_date + "T" + endtMomentObj + "" + offset;
    }
    if (this.offerobj.end_date && this.offerobj.end_date.split('T')[1]) {
      this.offerobj.end_date = this.offerobj.end_date.split('T')[0] + "T" + endtMomentObj + "" + offset;
    }
    if(this.notificationScheduleType =='on_scheduled_time'){
      this.offerobj['notification_schedule_time']= this.finalScheduledDateAndTime;
    }
    else{
      this.offerobj['notification_schedule_time'] = null;
    }
    this.offerobj.allow_sponsorfollowers = this.reqObj.allow_sponsorfollowers;
    this.offerobj['send_notification'] = true;
    this.offerobj['notification_schedule_type'] =this.notificationScheduleType;
    // if(this.offerobj['location_category'] === 'ALL_LOCATIONS'){
      // console.log(this.offerobj);
      // console.log('inside all locations');
      // this.offerobj.locations = [];
    // }
    this.offercreationService
      .createCampaign(this.offerobj)
      .then((data: any) => {
        // this.createCampaignDRL(data, modaloffercreation);
        this.offerloader = false;
        this.open(modaloffercreation, 'offerCreationModal');
        let self = this;
        setTimeout(function() { self.modalService.dismissAll(); 
          if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
            self.router.navigate(['/offers'], { queryParams: { partner: localStorage.getItem('partnerid') }});
          } else {
             self.router.navigate(['/offers']); 
          }
        }, 3000);
      })
      .catch(error => {
        this.offerloader = false;
        if (this.offerobj.start_date) {
          this.offerobj.start_date = this.offerobj.start_date.split('T')[0];
        }
        if (!this.offerobj.start_date && this.allDay) {
          this.offerobj.start_date = today;
        }
        if (this.offerobj.end_date) {
          this.offerobj.end_date = this.offerobj.end_date.split('T')[0];
        }
        this.showErrorMsg(error.error);
      });
  }

  open(content, modalclass) {
    this.modalService.open(content, { windowClass: modalclass });
  }

  close() {
    this.modalService.dismissAll();
  }

  createCampaignDRL(offerobj, modaloffercreation) {
    this.error = false;
    this.errorMsg = "";
    let id = offerobj.id
    this.offercreationService
      .createCampaignDRL(id)
      .then((data: any) => {
        this.launchCampaign(id, modaloffercreation);
      })
      .catch(error => {
        this.offerloader = false;
        this.showErrorMsg(error.error);
      });

  }


  launchCampaign(id, modaloffercreation) {
    let req = { "offer_id": id, "status": "launched" };
    this.offercreationService
      .launchCampaign(req)
      .then((data: any) => {
        this.offerloader = false;
        this.open(modaloffercreation, 'offerCreationModal');
        let self = this;
        setTimeout(function() { self.modalService.dismissAll(); 
          if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
            self.router.navigate(['/offers'], { queryParams: { partner: localStorage.getItem('partnerid') }});
          } else {
             self.router.navigate(['/offers']); 
          }
        }, 3000);
      })
      .catch(error => {
        this.offerloader = false;
        this.showErrorMsg(error.error);
      });
  }

  getData() {
    let contentFulIds = this.offerIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys": {
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
          this.offerCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }

      }
    });
    this.contentful.getContent('2ixR25RFMFiu4hROVnWJSs', this.locale).subscribe(dataobj => {
      this.headerImage = dataobj['imageContent'].fields.file.url;
    });

  }

  overlapCampaign(start_date, end_date, modalofferoverlap, fromReview) {
    if (start_date && start_date.split('T')[1]) {
      start_date = start_date.split('T')[0];
    }
    if (end_date && end_date.split('T')[1]) {
      end_date = end_date.split('T')[0];
    }
    this.overlapoffer = [];
    let offerpartnerid = ''; 
    if(localStorage.getItem('partnerid') !== null && localStorage.getItem('partnerid') !== '' && localStorage.getItem('partnerid') !== undefined){
      offerpartnerid = localStorage.getItem('partnerid');
    }
    // for(let i=0;i<this.lookups["OFFER_PROPOSITION"].length;i++){
    //   if(this.lookups["OFFER_PROPOSITION"][i].code === this.offerobj.template_params.reward_value){
    //     this.rewardValueName = this.lookups["OFFER_PROPOSITION"][i].name
    //     this.offerobj['offer_description'] = this.lookups["OFFER_PROPOSITION"][i].name
    //     this.offerobj.template_params.product_id = this.lookups["OFFER_PROPOSITION"][i].code;
    //   }
    // }
    this.targetedSegmentName = this.targetedSegment.member_segment_name
    this.showOfferReview(true, modalofferoverlap);
  }
  uploadImage(e, fromheader) {
		let array;
		let file = e.target.files[0];
		if (file) {
			if (fromheader) {
				this.headerArray = [];
				array = this.headerArray;
			}
			this.commonService.addDocument(this.partnerobj.id, e, array, 5242880, '', this.errorObj, '');
		}
	}

  showErrorMsg(error) {
    this.error = true;
    if (Object.keys.length > 0) {
      this.commonService.scrollTop();
      if (error.message) {
        this.errorMsg = error.message;
      } else {
        let Messages = Object.keys(error).map((key) => (error[key][0].message));
        this.errorMsg = Messages.toString();
      }
    }
  }

  setMultiplierVal(val, data?) {
    let value;
    if (val === 'FIXED') {
      value = this.offerobj.template_params.reward_value;
    }
    else if(val === 'PERCENTAGE'){
      value = this.offerobj.template_params.percentage_value;
    }
    else if(val) {
      value = this.offerobj.template_params.reward_multiplier;
    }

    if (data && val !== 'PERCENTAGE') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].code === value) {
          if (val === 'FIXED') {
            this.multiplierVal = '+' + data[i].name;
          } else {
            if (data[i].name === '3x') {
              this.multiplierVal = 'Triple';
            }
            if (data[i].name === '2x') {
              this.multiplierVal = 'Double';
            }
          }
        }
      }
    }
    else{
      this.multiplierVal = value + "% Bonus";
    }
    
  }

  setMultiplierValForPercentage(isValueInvalid){
    if(!isValueInvalid){
      this.setMultiplierVal('PERCENTAGE');
    }
  }
  checkDateError() {
    this.onedayerror = false;
    this.starterror = false;
    let currenttime = new Date().getHours();
    let currentmin = new Date().getMinutes();
    if (this.offerobj.start_date === this.offerobj.end_date) {
      if (((parseInt(this.offerobj.template_params.offer_end_hour) < parseInt(this.offerobj.template_params.offer_start_hour)) || (parseInt(this.offerobj.template_params.offer_end_hour) <= parseInt(this.offerobj.template_params.offer_start_hour) && parseInt(this.offerobj.template_params.offer_end_minute) <= parseInt(this.offerobj.template_params.offer_start_minute))) && this.offerobj.template_params.offer_end_hour && this.offerobj.template_params.offer_end_minute) {
        this.onedayerror = true;
      }
    }
    if(this.offerobj.start_date === moment(new Date()).tz(this.programtimezone).format('YYYY-MM-DD')) {
      if (((parseInt(this.offerobj.template_params.offer_start_hour) < parseInt(moment(new Date()).tz(this.programtimezone).format('HH'))) ||  (((parseInt(this.offerobj.template_params.offer_start_hour) == parseInt(moment(new Date()).tz(this.programtimezone).format('HH'))) && (parseInt(this.offerobj.template_params.offer_start_minute) < parseInt(moment(new Date()).tz(this.programtimezone).format('mm')))))) && this.offerobj.template_params.offer_start_hour && !this.allDay) {
        this.starterror = true;

      }
    }
  }

  getProgramData() {
    this.partnercreationService
      .getProgramData()
      .then((data: any) => {
        this.programtimezone = data[0].timezone;
        this.programCurrency = data[0].currency;
      })
      .catch(error => {
        //
      });

  }

  showScheduleMenu(){
    this.schedulemenu=!this.schedulemenu;
  }
  selectCommSchedule(commtype,datepickerModal?){
    this.communicationScheduleType=commtype;
    if(commtype == 'Scheduled' && datepickerModal){
      this.notificationScheduleType = 'on_scheduled_time';
      this.modalService.open(datepickerModal, { windowClass: 'offerCommDatePicker',backdrop:'static',keyboard:false });
    }
    else{
      this.notificationScheduleType = 'on_launch';
    }
  }

  getDateTimeFromModal(data){
    let selectedDate = data.selectedDate;
    let selectedTime = data.time;
    let selectedMomentObj = moment(selectedTime, ["h:mm:s A"]).format("HH:mm:ss");
    let offset = moment.tz(this.programtimezone).format('Z');
    let finalDateTime = selectedDate + "T" + selectedMomentObj + "" + offset;
    this.finalScheduledDateAndTime = finalDateTime;
    this.showScheduledDateAndTime = moment(selectedDate).format('DD-MM-YYYY') + ' at '+ selectedTime;
    // console.log(this.finalScheduledDateAndTime);
  }
}