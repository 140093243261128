export class offerDetailsCMSModel {
    reviewOfferDescription : string;
    backCTA : string;
}


export class offerDetailsEntryIdObj {
  	entryIds = [
      { id:'1sI0UggBwXourjNWO1jV7N', text : 'reviewOfferDescription'},
      { id:'Yur9FWZETwmZtWmiO3Rat', text : 'backCTA'},
  	];
}