import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Partner, partner } from "../../models";
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);
offline(Highcharts);
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService, OffercreationService, DashboardService } from '../../services';
import * as moment from 'moment-timezone';
import * as jsPDF from 'jspdf';
import { dashboardCMSModel, dashboardEntryIdObj } from "../../models";
import * as _ from "lodash";
import { RolebasedrulesService } from 'src/services/rolebasedrules.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.less']
})

export class DashboardComponent implements OnInit {
	highcharts : any =  Highcharts;
	step: string = 'dashboard';
	partnerobj: Partner = partner[0];
	tab: string = "performancesnapshot";
	filter: string = "month";
	chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
	transactionsToDate: any = [];
	spendingsToDate: any = [];
	updateFlag: boolean = false; // optional boolean
	oneToOneFlag: boolean = true; // optional boolean, defaults to false
	runOutsideAngular: boolean = false; // optional boolean, defaults to false
	offers: any = [];
	partnerlocations: any = [];
	data_name: string = "all";
	offer_name: string = "";
	location_name: string = "";
	error: boolean = false;
	errorMsg: string;
	miles_time_filter: string = 'week';
	miles_time_filter_bottom: string = 'week';
	membersArray: any = [];
	memberscount: number = null;
	memberflag: boolean = false;
	milesflag: boolean = false;
	analyseflag: boolean = false;
	analyseflagbottom: boolean = false;
	transactionsflag: boolean = false;
	spendingsflag: boolean = false;
	milesArray: any = [];
	milescount: number = null;
	analyseArray: any = [];
	analyseArrayBottom: any = [];
	analysemilescount: number = null;
	transactionsArray: any = [];
	transactionscount: number = null;
	spendingsArray: any = [];
	spendingscount: number = null;
	filter_one: string = "miles";
	filter_one_bottom: string = "spend";
	filter_two: string = "time";
	filter_two_bottom: string = "time";
	filter_name: string = "Skwards Miles";
	filter_name_bottom: string = "Associated spend";
	filter_text: string = "Miles Awarded";
	filter_text_bottom: string = "Associated spend";
	interval_text: string = "week";
	interval_text_bottom: string = "week";
	demographics_filter: string = "gender";
	demographics_filter_bottom: string = "gender";
	graphcolors: any = ['#72B56C'];
	charts: any = [];
	titleText: any = " in the last ";
	// Create a new jsPDF instance. Make sure it's big enough to include
	// the graph width and height
	pdf = new jsPDF('p', 'mm', [500, 500]);
	// A counter to make sure that all the graph dataURLs have been generated
	// properly since there is no callback option
	counter = 0;
	// An array to store the dataURLs
	imgurls = [];
	isOffersAPIAllowed : boolean = false;
	tooltip : any = {

	};
	xoptions: any = {
		type: 'datetime',
		dateTimeLabelFormats: { // don't display the dummy year
			day: '%b-%y'
		},
		startOnTick: true,
		endOnTick: false,
		minPadding: 0,
		maxPadding: 0,
		labels: {
			style: {
				color: '#A9A9A9',
				font: '12px Helvetica Neue',
			}
		},
		style: {
			textOverflow: 'none',
			color: '#A9A9A9',
			font: '12px Helvetica Neue'
		}
	};

	yoptions: any = {
		gridLineWidth: 0,
		gridLineColor: '#3e5068',
		allowDecimals: false,
		title: {
			text: null
		},
		labels: {
			style: {
				color: '#A9A9A9',
				font: '12px Helvetica Neue',
			}
		}
	}
	container: string = "member-container";
	dashboardCMSData = new dashboardCMSModel();
	dashboardIdObj = new dashboardEntryIdObj();
	locale: string = 'en-US';
	partnerId: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private partnercreationService: PartnercreationService,
		private contentful: ContentfulService,
		private offercreationService: OffercreationService,
		private dashboardService: DashboardService,
		private rolebasedRulesService: RolebasedrulesService
	) { }


	ngOnInit() {
		this.highcharts.setOptions({lang: {
			thousandsSep: ','
		}});
		if (this.route.snapshot.queryParamMap.get('partner')) {
			//console.log()
			this.partnerId = parseInt(this.route.snapshot.queryParamMap.get('partner'));
			localStorage.setItem('partnerid', this.partnerId);
		}
		else {
			this.partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
			localStorage.setItem('partnerimg', this.authenticationService.currentUserValue['sponsor_logo']);
			localStorage.setItem('partnername', this.authenticationService.currentUserValue['sponsor_name']);
		}
		this.getData();
		this.commonService.getPartnerData(this.partnerId).subscribe(data => {
			this.partnerobj = data;
			localStorage.setItem('partnerimg', this.partnerobj['logo']);
			localStorage.setItem('partnername', this.partnerobj['name']);
			this.filterPerformance(this.partnerobj.id, 'day');
			this.isOffersAPIAllowed =  this.rolebasedRulesService.checkIfPathAllowed(this.authenticationService.currentUserValue?.role?.code, 'offers');
			if(this.isOffersAPIAllowed){
				this.getCampaigns();
			}
			this.getPartnerLocations();
		});
		let _self = this;

		this.highcharts.downloadURL = function(dataURL, filename) {
			if (dataURL.length > 2000000) {
				dataURL = this.highcharts.dataURLtoBlob(dataURL);
				if (!dataURL) {
					throw 'Data URL length limit reached';
				}
			}
			_self.imgurls.unshift(dataURL);
			_self.counter++;
		};
	}

	tabChange(val) {
		this.tab = val;
		if (val === 'analyseperformance') {
			this.loadDasboardChartsForAnalyse(this.partnerobj.id, 'analyseflag', 'analyseArray', this.filter_one, this.filter_two, this.miles_time_filter, 'filter_name', 'filter_text', 'interval_text');
			this.loadDasboardChartsForAnalyse(this.partnerobj.id, 'analyseflagbottom', 'analyseArrayBottom', this.filter_one_bottom, this.filter_two_bottom, this.miles_time_filter_bottom, 'filter_name_bottom', 'filter_text_bottom', 'interval_text_bottom');
		}
	}

	filterPerformance(partnerId, val) {
		this.error = false;
		this.errorMsg = "";
		this.filter = val;
		this.loadDasboardCharts(partnerId, val, 'memberflag', 'memberscount', 'membersArray', 'members');
		this.loadDasboardCharts(partnerId, val, 'milesflag', 'milescount', 'milesArray', 'miles');
		this.loadDasboardCharts(partnerId, val, 'transactionsflag', 'transactionscount', 'transactionsArray', 'accruals');
		this.loadDasboardCharts(partnerId, val, 'spendingsflag', 'spendingscount', 'spendingsArray', 'spend');
	}

	loadDasboardCharts(partnerId, interval, flag, count, dataArray, url) {
		this[flag] = false;
		this[dataArray] = [];
		this.error = false;
		this.dashboardService
			.getDashboardData(partnerId, interval, url)
			.then((data: any) => {
				if(interval === 'month'){
				    this.xoptions = {
			            type: 'datetime',
			            endOnTick: false,
			            labels: {
			                staggerLines: 1,
			                formatter: function () {
			                	return Highcharts.dateFormat('%b %y', this.value);
			                },
			                style: {
								color: '#A9A9A9',
								font: '12px Helvetica Neue',
							}
			            },
			            tickPositioner: function () {
			                var positions = [],
			                    tick = Math.floor(this.dataMin),
			                    increment = 1000 * 3600 * 24 * 91.5; // 3 months

			                for (tick; tick <= this.dataMax; tick += increment) {
			                    positions.push(tick);
			                }
			                if (positions.indexOf(this.dataMax) == -1) positions.push(this.dataMax);
			                return positions;
				        }
				    };
				    this.tooltip = {
				    	formatter: function() {
		                    return  '<b>' + this.series.name + ' : ' + this.y+'</b><br/>' +
		                        Highcharts.dateFormat('%b %y', (this.x));
		                }
				    };
			        
				}
				else {
					this.xoptions = {
			            type: 'datetime',
			            endOnTick: false,
			            labels: {
			                staggerLines: 1,
			                formatter: function () {
			                	return Highcharts.dateFormat('%e.%b', this.value);
			                },
			                style: {
								color: '#A9A9A9',
								font: '12px Helvetica Neue',
							}
			            }
			        };
			        this.tooltip = {
				    	formatter: function() {
		                    return  '<b>' + this.series.name + ' : ' + this.y+'</b><br/>' +
		                        Highcharts.dateFormat('%e-%b-%y', (this.x));
		                }
				    }
				}
				this[count] = data.total;
				for (var i = 0; i < data.data.length; i++) {
					if (interval === 'month') {
						this[dataArray].push([Date.UTC(data.data[i][0], data.data[i][1] - 1, 1), data.data[i][2]]);
					} else {
						this[dataArray].push([Date.UTC(parseInt(data.data[i][0].split('-')[0]), parseInt(data.data[i][0].split('-')[1]) - 1, parseInt(data.data[i][0].split('-')[2])), data.data[i][1]]);
					}

				}
				this[flag] = true;
			})
			.catch(error => {
				this[flag] = false;
				this.error = true;
				this.errorMsg = error;
			});
	}

	loadDasboardChartsForAnalyse(partnerId, flag, dataArray, url, filter, dayinterval, filter_name, filter_text, interval_text) {
		this[flag] = false;
		this[dataArray] = [];
		let interval = '';
		let start_date = '';
		let end_date = '';
		let name = '';
		this.error = false;
		if (flag === 'analyseflag') {
			this.setFilterName(this.filter_one, flag);
			name = this.filter_text;
		} else {
			this.setFilterName(this.filter_one_bottom, flag);
			name = this.filter_text_bottom;
		}
		switch (dayinterval) {
			case 'week':
				this[interval_text] = "week";
				start_date = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "day";
				break;
			case 'day':
				this[interval_text] = "day";
				start_date = moment(new Date()).format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "day";
				break;
			case 'month':
				this[interval_text] = "month";
				start_date = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "month";
				break;
			case 'six':
				this[interval_text] = "six months";
				start_date = moment(new Date()).subtract(6, 'months').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "month";
				break;
			case 'year':
				this[interval_text] = "year";
				start_date = moment(new Date()).subtract(1, 'years').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "month";
				break;
			case 'all':
				this.interval_text = "";
				this.interval_text_bottom = "";
				start_date = moment(new Date()).subtract(2, 'years').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "month";
				break;
			default:
				this.interval_text = "week";
				this.interval_text_bottom = "week";
				start_date = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD');
				end_date = moment(new Date()).format('YYYY-MM-DD');
				interval = "day";
				break;
		}
		if (filter === 'demographics_filter') {
			let demo_filter = '';
			if (filter_name === 'filter_name') {
				demo_filter = this.demographics_filter;
			} else {
				demo_filter = this.demographics_filter_bottom;
			}
			switch (demo_filter) {
				case 'gender':
					this[interval_text] = dayinterval;
					filter = "gender";
					break;
				case 'age':
					this[interval_text] = dayinterval;
					filter = "age";
					break;
				default:
					this[interval_text] = dayinterval;
					filter = "gender";
					break;
			}
		}

		this.dashboardService
			.getDashboardDataForAnalyse(partnerId, interval, url, filter, start_date, end_date, this.data_name, this.offer_name, this.location_name)
			.then((data: any) => {
				this[dataArray] = [];
				for (var i = 0; i < data.data.length; i++) {
					if (filter === 'time') {
						this.xoptions = {
							type: 'datetime',
							dateTimeLabelFormats: { // don't display the dummy year
								day: '%b-%y'
							},
							startOnTick: true,
							endOnTick: true,
							minPadding: 0,
							maxPadding: 0,
							labels: {
								style: {
									color: '#A9A9A9',
									font: '12px Helvetica Neue',
								}
							},
							style: {
								textOverflow: 'none',
								color: '#A9A9A9',
								font: '12px Helvetica Neue'
							}
						};

						if (interval === 'month') {
							
						    this.xoptions = {
					            type: 'datetime',
								intervalInfo:interval,
					            startOnTick: true,
								endOnTick: true,
					            labels: {
					                staggerLines: 1,
					                formatter: function () {
					                	return Highcharts.dateFormat('%b %y', this.value);
					                },
					                style: {
										color: '#A9A9A9',
										font: '12px Helvetica Neue',
									}
					            },
					            tickPositioner: function () {
					                var positions = [],
					                    tick = Math.floor(this.dataMin),
					                    increment = 1000 * 3600 * 24 * 91.5; // 3 months

					                for (tick; tick <= this.dataMax; tick += increment) {
					                    positions.push(tick);
					                }
					                if (positions.indexOf(this.dataMax) == -1) positions.push(this.dataMax);
					                return positions;
						        }
						    };
						    this.tooltip = {
						    	formatter: function() {
				                    return  '<b>' + name + ' : ' + this.y+'</b><br/>' +
				                        Highcharts.dateFormat('%b %y', (this.x));
				                }
						    };
							this[dataArray].push([Date.UTC(data.data[i][0], data.data[i][1] - 1, 1), data.data[i][2]]);
							this.tooltip = {
						    	formatter: function() {
				                    return  '<b>' + name + ' : ' + this.y+'</b><br/>' +
				                        Highcharts.dateFormat('%b %y', (this.x));
				                }
						    };
						} else {
							if (dayinterval === 'week') {
								this.xoptions.dateTimeLabelFormats.day = '%A';
								this.tooltip = {
							    	formatter: function() {
					                    return  '<b>' + name + ' : ' + this.y+'</b><br/>' +
					                        Highcharts.dateFormat('%A', (this.x));
					                }
							    };
							} else {
								this.xoptions.dateTimeLabelFormats.day = '%e %b %y';
								this.tooltip = {
							    	formatter: function() {
					                    return  '<b>' + name + ' : ' + this.y+'</b><br/>' +
					                        Highcharts.dateFormat('%e %b %y', (this.x));
					                }
							    };
							}
							this[dataArray].push([Date.UTC(parseInt(data.data[i][0].split('-')[0]), parseInt(data.data[i][0].split('-')[1]) - 1, parseInt(data.data[i][0].split('-')[2])), data.data[i][1]]);
						}
					} else {
						this.xoptions = {
							"type": "category"
						};
						switch (data.data[i][0]) {
							case 'Blue':
								data.data[i].push('#81BFE1');
								data.data[i].push('#2597D5');
								break;
							case 'Silver':
								data.data[i].push('#E7E7E7');
								data.data[i].push('#C4C5CA');
								break;
							case 'Gold':
								data.data[i].push('#D1994A');
								data.data[i].push('#946B31');
								break;
							case 'Platinum':
								data.data[i].push('#AAAAAA');
								data.data[i].push('#626262');
								break;
							case 'female':
								data.data[i].push('#81BFE1');
								data.data[i].push('#2597D5');
								break;
							case 'male':
								data.data[i].push('#D1994A');
								data.data[i].push('#946B31');
								break;
							default:
								data.data[i].push('#81BFE1');
								data.data[i].push('#2597D5');
								break;
						}

						this[dataArray].push(
							{
								name: data.data[i][0],
								y: data.data[i][1],
								color: {
									linearGradient: {
										x1: 0,
										x2: 1,
										y1: 1,
										y2: 1
									},
									stops: [
										[0, data.data[i][2]],
										[1, data.data[i][3]]
									]
								}
							}
						);
						this.tooltip = {};
					}
				}
				this[flag] = true;
				if ((this.data_name === 'offers' && !this.offer_name) || (this.data_name === 'locations' && !this.location_name)) {
					this[dataArray] = [];
				}
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = error;
			});

	}
	getCampaigns() {
		let partnerid = this.route.snapshot.queryParamMap.get('partner');
		this.offercreationService
			.getCampaignsList(partnerid)
			.then((data: any) => {
				this.offers = data.body;
			})
			.catch(error => {
				this.error = true;
				this.errorMsg = error.error.error.message;
			});
	}
	getPartnerLocations() {
		this.offercreationService
			.getPartnerLocations(this.authenticationService.currentUserValue.sponsors[0].id)
			.then((data: any) => {
				this.partnerlocations = data.body;
			})
			.catch(error => {
				this.errorMsg = error.error.error.message;
			});
	}
	setSubFilter(filter) {
		this.data_name = filter;
		if (filter === 'offers' && this.offers.length > 0) {
			this.offer_name = this.offers[0].id;
		}
		if (filter === 'locations' && this.partnerlocations.length > 0) {
			this.location_name = this.partnerlocations[0].location_code;
		}
	}


	downloadPdf() {
		this.charts[0].exportChartLocal('image/svg+xml');
		this.charts[1].exportChartLocal('image/svg+xml');
		this.counter = 0;
		this.imgurls = [];
		var pageCount = this.pdf.internal.getNumberOfPages();
		if (pageCount > 0) {
			for (let i = 0; i < pageCount; i++) {
				this.pdf.deletePage(i);
			}
		}
		let _self = this;
		var interval = setInterval(function() {
			if (_self.counter === 2) {
				clearInterval(interval);
				_self.imgurls.forEach(function(img) {
					_self.pdf.addImage(img, 'SVG', 10, 10);
					_self.pdf.addPage();
				});
				_self.pdf.save('dashboard.pdf');
			}
		}, 100);
	}

	getData() {
		let contentFulIds = this.dashboardIdObj.entryIds;
		this.contentful.getEntries(this.locale).subscribe(dataobj => {
			for (let entry of contentFulIds) {
				let Index = _.findIndex(dataobj.items, {
					"sys": {
						"id": entry.id
					}
				});
				if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
					this.dashboardCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
				}

			}
		});

	}

	setFilterName(value, fromflag) {
		let filtername = "";
		switch (value) {
			case "cost-of-miles":
				filtername = "Cost of Miles";
				break;
			case "miles":
				filtername = "Miles Awarded";
				break;
			case "spend":
				filtername = "Associated spend";
				break;
			case "accruals":
				filtername = "Transactions earned";
				break;
			case "members":
				filtername = "Members accrued miles";
				break;

			default:
				filtername = "Miles Awarded";
				break;
		}
		if (fromflag === 'analyseflagbottom') {
			this.filter_text_bottom = filtername;
		} else {
			this.filter_text = filtername;
		}
	}

}
