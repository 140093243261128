import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CommonService, PartnercreationService, ContentfulService } from '../../services';
import { Partner, partner, marker, stepFourCMSModel, stepFourEntryIdObj } from "../../models";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
@Component({
  selector: 'app-locationpaymentschemesreview',
  templateUrl: './locationpaymentschemesreview.component.html',
  styleUrls: ['./locationpaymentschemesreview.component.less', '../header/header.component.less']
})


export class LocationpaymentschemesreviewComponent implements OnInit {
  partnerobj: Partner = partner[0];
  error: boolean = false;
  errorMsg: string = '';
  partnerlocations: any = [];
  nextcursor: string = '';
  prevcursor: string = '';
  billingConfig: any = {
      'payment_plan': '',
      'rate': null
  };
  noPayments: boolean = true;
  headerImage: any;
  locale: string = 'en-US';
  cardcount: number = 0;
  carddownloadcount: number = 0;
  statusfailed: boolean = true;
  stepFourCMSData = new stepFourCMSModel();
  stepFourIdObj = new stepFourEntryIdObj();
  constructor(
    private partnercreationService: PartnercreationService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private contentful: ContentfulService
  ) { }

  ngOnInit() {
    let partnerId = this.authenticationService.currentUserValue.sponsors[0].id;
    this.commonService.scrollTop();
    this.getBillingConfig(partnerId);
    this.commonService.getPartnerData(partnerId).subscribe(data => {
      this.partnerobj = data;
      this.getPartnerLocations('');
    });
    this.getData();
  }
  getPartnerLocations(cursor) {
    if (cursor) {
      cursor = cursor.split("cursor=")[1];
    }
    this.statusfailed = true;
    this.partnercreationService
      .getPartnerLocations(this.partnerobj.id)
      .then((data: any) => {
        this.partnerlocations = data.body;
        this.cardcount = 0;
        this.statusfailed = true;
        for(var crd=0; crd<this.partnerlocations.length; crd++){
          this.partnerlocations[crd].visa_acquirer_id = '';
          this.partnerlocations[crd].master_acquirer_id =  '';
          this.partnerlocations[crd].status = null;
          this.partnerlocations[crd].statustooltip = false;
          this.partnerlocations[crd].visafailed = false;
          this.partnerlocations[crd].mastercardfailed = false;
          this.cardcount++;
          this.getCardDetails(this.partnerobj.id, this.partnerlocations[crd].id, crd);
        }
        
        this.prevcursor = data.headers.get('prev');
        this.nextcursor = data.headers.get('next');
      })
      .catch(error => {
        console.log(error.error);
      });
  }

  getCardDetails(partnerid, locationid, index){
    this.partnercreationService
      .getlocationcarddatails(partnerid, locationid)
      .then((data: any) => {
         this.carddownloadcount++;
         if(data.length === 2){
           this.partnerlocations[index].visa_acquirer_id = data[0].acquirer_id;
           this.partnerlocations[index].master_acquirer_id = data[1].acquirer_id;
           if(data[0].status === 'Active' && data[1].status === 'Active'){
             this.partnerlocations[index].status = data[0].status;
           } else if((data[0].status === 'In-Review' && data[1].status === 'In-Review') || (data[0].status === 'In-Review' && data[1].status === 'Active') || (data[1].status === 'In-Review' && data[0].status === 'Active') ){
             this.partnerlocations[index].status = 'In-Review';
           } else if((data[0].status === 'In-Review' && data[1].status === 'Rejected' ) || (data[0].status === 'Rejected' && data[1].status === 'In-Review') || (data[0].status === 'Active' && data[1].status === 'Rejected') || (data[0].status === 'Rejected' && data[1].status === 'Active')){
             this.partnerlocations[index].status = 'Rejected';
           } else if(data[0].status === 'Failed' || data[1].status === 'Failed'){
             this.partnerlocations[index].status = 'Failed';
           }

           if(data[0].status === 'Rejected' || data[0].status === 'Failed'){
             this.partnerlocations[index].visafailed = true;
           } else if(data[1].status === 'Rejected' || data[1].status === 'Failed'){
             this.partnerlocations[index].mastercardfailed = true;
           }
         } 
         else if(data[0].network === 'visa'){
           this.partnerlocations[index].visa_acquirer_id = data[0].acquirer_id;
           this.partnerlocations[index].status = data[0].status;
           if(data[0].status === 'Rejected' || data[0].status === 'Failed'){
             this.partnerlocations[index].visafailed = true;
           } 
         } 
         else if(data[0].network === 'mastercard'){
          this.partnerlocations[index].master_acquirer_id = data[0].acquirer_id;
          this.partnerlocations[index].status = data[0].status;
          if(data[0].status === 'Rejected' || data[0].status === 'Failed'){
            this.partnerlocations[index].mastercardfailed = true;
          }
         }
         

         
         if(this.carddownloadcount === this.cardcount){
          var m=0;
          for(var crd=0; crd<this.partnerlocations.length; crd++){
            if(this.partnerlocations[crd].status !== 'Active'){
              this.statusfailed = true;
              break;
            } else {
              m++;
            }
          }
          if(m === this.partnerlocations.length){
            this.statusfailed = false;
          }
          
         }
         
      }).catch(error => {
        console.log(error.error);
      });

  }


  resetPin(location) {
    this.partnercreationService
      .resetAuthPin(location.sponsor, location.id)
      .then((data: any) => {
        location.pin = data.body.new_pin;
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  goLive() {
    let request = {
      "event": "go_live_button_is_clicked"
    };
    this.partnercreationService
      .goLive(request, this.partnerobj.id)
      .then((data: any) => {
        this.router.navigate(['/dashboard']);
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  partnerLogout() {
    this.authenticationService.logout();
  }

  gotoMargetingPage() {
    this.router.navigate(['/skywardseveryday']);
  }

  getBillingConfig(partnerId) {
    this.partnercreationService
      .getBillingConfig(partnerId)
      .then((data: any) => {
        this.billingConfig = data[0];
        this.billingConfig['rate'] = 0.071;
        if (this.billingConfig.payment_plan === 'prepaid') {
          this.getPayments(partnerId);
        }
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });

  }

  getPayments(partnerId) {
    this.partnercreationService
      .getPayments('', partnerId)
      .then((data: any) => {
        if (data.body.length > 0) {
          this.noPayments = false;
        } else {
          this.noPayments = true;
        }
      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  getData() {
    let contentFulIds = this.stepFourIdObj.entryIds;
    this.contentful.getEntries(this.locale).subscribe(dataobj => {
      for (let entry of contentFulIds) {
        let Index = _.findIndex(dataobj.items, {
          "sys": {
            "id": entry.id
          }
        });
        if (Index !== -1 && dataobj.items[Index]['fields']['stringContent'] !== undefined) {
          this.stepFourCMSData[entry.text] = dataobj.items[Index]['fields']['stringContent'];
        }

      }
    });
    this.contentful.getContent('3VDF0hzezUB232Sq1mYlNW', this.locale).subscribe(dataobj => {
      this.headerImage = dataobj['imageContent'].fields.file.url;
    });
  }

}
