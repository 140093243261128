import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService, PartnercreationService, CommonService, VersionCheckService, StrategicparternsService } from '../services';
import {
  NgbModal, NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { LocationStrategy } from '@angular/common';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Partner, partner, Sales, sales } from "./../models";
import { Subscription } from 'rxjs';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'LifeMiles Merchant Portal';
  language = "en_US";
  logintime: number = 0;
  expiretime: number = 0;
  tokentimeout: number = 0;
  currentTime: number = 0;
  reauthentication: any;
  sessionalerttime: number = 0;
  currentUser: any;
  sessionalertmodal: boolean = false;
  modal: any;
  loginmodal: boolean = false;
  interval: any;
  errorMsg = "";
  error: boolean = false;
  partnerobj: Partner = partner[0];
  subscription: Subscription;
  browserRefresh: boolean = false;
  partnerData: any;
  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private partnercreationService: PartnercreationService,
    private location: LocationStrategy,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private versionCheckService: VersionCheckService,
    private strategicparternsService: StrategicparternsService
  ) {
    translate.setDefaultLang(this.language);
    this.location.onPopState(() => {
      this.commonService.setBackClicked(true);
      return false;
    });
    router.events.subscribe((event: NavigationStart) => {
        if(localStorage.getItem('status') === 'loggedin' && event.url){
          localStorage.setItem('typedurl', event.url);
        }
    });
  }
  switchLanguage(language) {
    let lang;
    if (language === undefined || language === "") {
      lang = navigator.language;
    } else {
      lang = language;
    }
    if (lang.indexOf("-") !== -1) {
      lang = lang.split("-")[0];
    }
    if (lang.indexOf("_") !== -1) {
      lang = lang.split("_")[0];
    }
    switch (lang) {
      case "en":
        lang = "en_US";
        break;
      case "es":
        lang = "es_ES";
        break;
      case "fr":
        lang = "fr_FR";
        break;
      case "pt":
        lang = "pt_BR";
        break;
      case "ar":
        lang = "ar_AE";
        break;
      default:
        lang = "en_US";
        break;
    }
    this.translate.use(lang);
  }

  setExpirationTime(expirationtime) {
    let logintime = new Date().getTime();
    localStorage.setItem('logintime', JSON.stringify(logintime));
    this.logintime = parseInt(localStorage.getItem('logintime'));
    this.expiretime = this.logintime + ((expirationtime - 600) * 1000);
    this.tokentimeout = this.logintime + (expirationtime * 1000);
    localStorage.setItem('expirationalerttime', JSON.stringify(this.expiretime));
    localStorage.setItem('tokentimeout', JSON.stringify(this.tokentimeout));
    localStorage.setItem('reauthentication', 'true');
    this.expirationCheck();
  }

  expirationCheck() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.interval = setInterval(() => {
      this.currentTime = new Date().getTime();
      if (this.tokentimeout < this.currentTime && this.reauthentication === false && this.currentUser) {
        this.currentUser = '';
        this.authenticationService.logout();
      } else {
        this.checkTokenExpiration();
        if (this.tokentimeout && this.tokentimeout < this.currentTime && this.reauthentication === true && this.currentUser) {
          if (this.loginmodal !== true) {
            if (this.sessionalertmodal !== undefined) {
              this.close(true);
            }
            document.getElementById("loginbutton").click();
            this.loginmodal = true;
          }
        }
      }
    }, 1000);
  }


  checkTokenExpiration() {
    this.currentTime = new Date().getTime();
    this.sessionalerttime = parseInt(localStorage.getItem('expirationalerttime'));
    this.reauthentication = localStorage.getItem('reauthentication');
    this.tokentimeout = parseInt(localStorage.getItem('tokentimeout'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.reauthentication && this.reauthentication !== null && this.currentUser) {
      this.reauthentication = JSON.parse(this.reauthentication);
    }
    this.tokentimeout = parseInt(localStorage.getItem('tokentimeout'));
    if (this.currentUser !== undefined && this.currentUser !== null && (this.currentTime > this.sessionalerttime) && this.reauthentication !== false && this.currentTime < this.tokentimeout) {
      if (!this.sessionalertmodal && !this.loginmodal) {
        document.getElementById("modalbutton").click();
        this.sessionalertmodal = true;
      }
    }
  }

  open(content, windowClass) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: windowClass
    };
    this.modalService.open(content, ngbModalOptions);
  }

  close(reauth) {
    localStorage.setItem('reauthentication', reauth);
    this.modalService.dismissAll();
    this.sessionalertmodal = false;
    this.loginmodal = false;
  }

  refreshToken() {
    let request = {
      "token": this.currentUser.token
    };
    this.partnercreationService
      .refreshToken(request)
      .then((data: any) => {
        this.currentUser.token = data.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        localStorage.setItem('status','loggedin');
        this.logintime = new Date().getTime();
        localStorage.setItem('loginTime', JSON.stringify(this.logintime));
        this.setExpirationTime(data.expires_in);
        this.close(true);
      })
      .catch(error => {
        this.authenticationService.logout();
      });
  };
  getPartnerData(partnerId, fromreelogin) {
    this.partnercreationService
      .getPartnerData(partnerId)
      .then((data: any) => {
        this.partnerData = data;
        let checkversion = true;
        switch (data.status) {
          case "Q":
            if (data.extra_data.onboarding_step_completed === 3 || data.extra_data.onboarding_step_completed === '3') {
              this.router.navigate(['/partnerinformation'], { queryParams: { partner: data.id } });
            } else if (data.extra_data.onboarding_step_completed === 1 || data.extra_data.onboarding_step_completed === '1') {
              this.router.navigate(['/customizeyourpackage']);
            } else {
              checkversion = false;
              this.getBillingConfig(data.id);
            }
            break;
          case "APPROVED":
            if (data.extra_data.onboarding_step_completed === 5 || data.extra_data.onboarding_step_completed === '5' || (data.extra_data.contract_signed && data.industry)) {
              this.router.navigate(['/welcomepage']);
            } else if (data.extra_data.onboarding_step_completed === 4 || data.extra_data.onboarding_step_completed === '4' || (data.extra_data.contract_signed && !data.industry)) {
              this.router.navigate(['/letsgo']);
            } else {
              this.router.navigate(['/signtheagreement']);
            }
            break;
          case "A":
            if(this.partnerData.hierarchy_designation === 'Parent' && this.strategicparternsService.getChildSponsors().length > 0){
              this.router.navigate(['strategicpartners']);
              break;
            } else {
              this.router.navigate(['/dashboard']);
              break;
            }
          case "IN-REVIEW":
            this.router.navigate(['/partnerinformation'], { queryParams: { partner: data.id } });
            break;
          case "REJECTED":
            this.router.navigate(['/partnerinformation'], { queryParams: { partner: data.id } });
            break;
          case "READY":
            this.router.navigate(['/welcomepage']);
            break;
          case "REQUEST":
            this.router.navigate(['/welcomepage']);
            break;
          case "S":
            this.router.navigate(['/dashboard']);
            break;
          case "T":
            this.router.navigate(['/dashboard']);
            break;
          case "P":
            this.partnerobj.contact_person_first_name =  data.contact_person_first_name;
            this.partnerobj.contact_person_last_name = data.contact_person_last_name;
            this.partnerobj.status = data.status;
            this.partnerobj.extra_data.contract_signed = true;
            this.router.navigate(['/becomeapartner']);
            break;
          default:
            this.router.navigate(['/partnerinformation'], { queryParams: { partner: data.id } });
            break;
        }
        if (fromreelogin) {
          this.close(true);
        } 
        else {
          if(checkversion){
            this.versionCheckService.initVersionCheck(environment.versionCheckURL);
          }
        }

      })
      .catch(error => {
        this.error = true;
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });
  }

  getBillingConfig(partnerId) {
    this.partnercreationService
      .getBillingConfig(partnerId)
      .then((data: any) => {
        if (data.length > 0 && (data[0].payment_plan === 'prepaid' || (data[0].payment_plan === 'postpaid' && this.partnerData.extra_data.last_ownership_change && parseInt(this.partnerData.extra_data.monthly_cost_of_miles) > 25000) || (data[0].payment_plan === 'postpaid' && parseInt(this.partnerData.extra_data.monthly_cost_of_miles) <= 25000))) {
          this.router.navigate(['/verifyyourbusiness']);
        } else {
          this.router.navigate(['/customizeyourpackage']);
        }
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
      })
      .catch(error => {
        this.errorMsg = this.commonService.showCommonErrorMsg(error);
      });

  }

  ngOnInit() {
    this.switchLanguage(this.language);
    this.expirationCheck();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.partnerobj.extra_data.contract_signed = false;
    let url = localStorage.getItem('typedurl');
    if (this.currentUser && window.performance && (window.performance.navigation.type == 0 || window.performance.navigation.type == 255) && !url) {
      let scope = this;
      setTimeout(function() {
        if (window.location.href.indexOf("paymentterms") < 1) {
          scope.getPartnerData(scope.currentUser.sponsors[0].id, false);
        }
      }, 3000);
    }
    if (window.location.href.indexOf("paymentterms") < 1) {
      if (url && url.split('?')[1] !== '' &&  url.split('?')[1] !== null &&  url.split('?')[1] !== undefined) {
        let path = url.split('?')[0];
        let params = (url.split('?')[1]).split('=')[0];
        let id = url.split('=')[1];
        this.router.navigate([path],{ queryParams: {[params] : id} });
      } else {
        this.router.navigate([url]);
      }
    }
   //this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }
}

