import { Component, OnInit, Input } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as cloneDeep from 'lodash/cloneDeep';
import { ContentfulService } from '../../services';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-paymentsmodal',
	templateUrl: './paymentsmodal.component.html',
	styleUrls: ['./paymentsmodal.component.less']
})
export class PaymentsmodalComponent implements OnInit {
	@Input() partnerdata: any;
	@Input() billingdata: any;
	locale: string = 'en-US';

	constructor(private contentful: ContentfulService,
		private modalService: NgbModal) { }

	ngOnInit() {
	}
	downloadPdf() {
		let doc = new jsPDF();
		doc.addHTML(document.getElementById("bank-details"), function() {
			doc.save("bank-transfer-instructions.pdf");
		});
	}
	
	close() {
		this.modalService.dismissAll();
	}
}
