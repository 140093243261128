export class headerCMSModel {
  	yourDetails: string;
  	commercials:string;
  	verifyYourBusiness:string;
  	signtheAgreement:string;
  	letsGo:string;
}


export class headerEntryIdObj {
  	entryIds = [
  		{ id:'4eeEmyBloeZ8u24S48xMrk', text : 'yourDetails'},
  		{ id:'2JEqC4yrzI3Oo3qDEpxBUU', text : 'commercials'},
  		{ id:'6Ds0VT66R1qNYaMFL2wo5q', text : 'verifyYourBusiness'},
  		{ id:'2AGPX9KJLet8zywjbcssFo', text : 'signtheAgreement'},
  		{ id:'2SxnYfjefcFIr8yiwQ2499', text : 'letsGo'}
  	]
}