import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PartnercreationService, CommonService } from '../../services';
import * as cloneDeep from 'lodash/cloneDeep';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-resetemails',
	templateUrl: './resetemails.component.html',
	styleUrls: ['./resetemails.component.less']
})
export class ResetemailsComponent implements OnInit {

	@Input() partnerobj: any;
	@Output('getAddedEmails') addemails = new EventEmitter<any>();
	associatedemail: string = "";
	error: boolean = false;
	errorMsg: string = '';
	partnerData: any;
	constructor(
		private modalService: NgbModal,
		private partnercreationService: PartnercreationService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.partnerData = cloneDeep(this.partnerobj);
	}
	addEmails() {
		if(this.partnerData.additional_emails === null || this.partnerData.additional_emails === undefined){
			this.partnerData.additional_emails = [];
		}
		if (this.associatedemail) {
			this.partnerData.additional_emails.push(this.associatedemail);
			this.associatedemail = "";
		}
	}

	removeEmail(index) {
		this.partnerData.additional_emails.splice(index, 1);
	}

	save() {
		delete this.partnerData.logo;
		delete this.partnerData.header;
		delete this.partnerData.description;
		this.partnercreationService
			.updatePartnerData(this.partnerData.id, this.partnerData)
			.then((data: any) => {
				this.addemails.emit(data.body);
				this.close();
			})
			.catch(error => {
				 this.error = true;
				this.errorMsg = this.commonService.showCommonErrorMsg(error);
				//this.close();
			});
	}

	close() {
		this.modalService.dismissAll();
	}
}
