export class Offer {
  is_template_based: boolean;
  template_name: string;
  offer_name: string;
  offer_type: string;
  allow_sponsorfollowers : boolean;
  locations : any;
  sponsors: any;
  products: any;
  start_date : string;
  end_date : string;
  member_target_type : string;
  targeted_segment : string;
  member_target_json : any;
  member_target_xml : string;
  template_params : any;
  offer_description : string;
  location_category: string;
  status: string
}

export const offer: Offer[] = [{
  is_template_based : true,
  template_name: 'DEMO_SANLAM_OFFER',
  status : "launched",
  offer_name: '',
  offer_type: 'reward',
  allow_sponsorfollowers : false,
  locations : [],
  sponsors : [],
  products : [],
  start_date : '',
  end_date : '',
  member_target_type : 'segment',
  targeted_segment : '',
  offer_description : '',
  member_target_json : [],
  member_target_xml : '',
  template_params : {
     offer_start_hour : null,
     offer_start_minute : null,
     offer_end_hour : null,
     offer_end_minute : null,
     reward_type : '',
     reward_multiplier : null,
     reward_value : null
  },
  location_category: 'OFFLINE'
}];

