export class Map {
  zoom: number;
  lat: number;
  lng: number;
  bounds:boolean;
  locations: any;
  address: string;
  weekarray: any;
  geocoder: any;
  selectedlocindex: any;
  manuallocation: boolean;
  updatelocation: boolean;
  showloader: boolean;
  count: number;
  prevcursor: string;
  nextcursor: string;
  location_name: string;
}

export const map: Map[] = [{
	zoom :15,
	lat: 25.2048,
	lng: 55.2708,
	bounds: false,
	locations: [],
	address: '',
	weekarray: {},
	geocoder: '',
	selectedlocindex: '',
	manuallocation: false,
	updatelocation: false,
	showloader: false,
	count: 0,
	prevcursor: '',
	nextcursor: '',
	location_name: ''
}];

export class Locationreq {
	"area_name": string;
  "city_name": string;
  "country_name": string;
  "region_name": string
};

export const locationmapreq: Locationreq = {
	"area_name": '',
  "city_name": '',
  "country_name": '',
  "region_name": ''
};
